import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { playoutStoreDataResolver } from 'utils';
import { playerStorage, contextStorage, startOverInfoStorage, playerEventsStorage } from '../../storages';
import { handleLoadChosenStream } from '../handleLoadChosenStream/handleLoadChosenStream';
import { handleRouteUpdates } from '../handleRouteUpdates/handleRouteUpdates';

export async function handleProgramUpdate(isMaxi?: boolean) {
  const { currentAsset, type, loadingNextProgram } = playoutStoreDataResolver();
  const {
    toastContext: { clearToast },
    routerContext: { goBack },
  } = contextStorage.getContext();

  if (!type && !currentAsset) {
    return goBack();
  }

  // to hide seekbar progress jumps while switching to new video
  if (loadingNextProgram) {
    playerEventsStorage.setIsPlayerDataLoaded(false);
  }

  clearToast();

  if (type === PlayoutStoreV2Type.LIVE) {
    startOverInfoStorage.setStartOver(false, false);
  }

  if (!isMaxi) {
    handleRouteUpdates();
  }

  await handleLoadChosenStream();

  playerStorage.setIsPlayBackPaused(false);
  playerStorage.clearVideoTrackInfo();
}
