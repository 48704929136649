import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { featureEnabler, RailStoreV2 } from '@kaltura-ott/tvpil-shared';
import { FEATURES } from 'consts';

import { checkIsLogoRail } from './checkIsLogoRail';

export function checkIsCollectionRail(rail: RailStoreV2) {
  const isEnabledCollectionRail = featureEnabler.variation(FEATURES.IS_NEW_COLLECTION_RAIL, false);

  return rail?.subtype === UIDAPresentationTypeEnum.collection && !checkIsLogoRail(rail) && isEnabledCollectionRail;
}
