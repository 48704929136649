import { AssetPresentationRatioEnum } from '@kaltura-ott/tvpil';
import {
  ViewableEntitiesType,
  DataRailV2,
  getImageByHeight,
  Program,
  getImageByHeightV2,
  HeroRailStoreV2,
} from '@kaltura-ott/tvpil-shared';

import { CHANNEL_LOGO_SIZE_MULTIPLIER } from '../components/AssetCardProgramChannelInfo/constants';

export const getImageUrl = ({
  asset,
  listStore,
  isHero,
}: {
  asset: ViewableEntitiesType;
  listStore: DataRailV2;
  isHero: boolean;
}) => {
  const { linear } = asset as Program;
  let imageUrl: string | undefined | null = listStore.itemImage(asset.data)?.imageUrl;
  let image;

  if (linear) {
    const height = isHero ? listStore.railHeight * CHANNEL_LOGO_SIZE_MULTIPLIER : 50;
    if (isHero) {
      image = getImageByHeightV2(height, (listStore as HeroRailStoreV2).channelImageType, linear.data.images);
    } else {
      image = getImageByHeight(height, AssetPresentationRatioEnum['16x9'], linear.data.images);
    }
  }

  // if show channel and no image => no placeholder
  if (!imageUrl && isHero && image?.url) {
    imageUrl = null;
  }

  return {
    imageUrl,
    image,
  };
};
