import { useRailCollection, useRailSettings } from '@kaltura-ott/tvpil-shared';

import { RAIL_PAGE_SIZE } from 'consts';

function useCollectionRailCombiner(referenceId: string) {
  const settings = useRailSettings({ referenceId });
  const data = useRailCollection(referenceId, RAIL_PAGE_SIZE);

  return { data, railSettings: settings.settings };
}

export { useCollectionRailCombiner };
