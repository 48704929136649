import { LOCK_ICON, MD_ICON } from 'consts';
import { useAppStore } from 'hooks';
import { getDefaultImagePath, getLockIcon, getMDIconPath } from 'utils';

function useCardIconsPaths({ height, width }: { height: number; width: number }) {
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();
  const ratio = height / width;
  const defaultImage = `${staticAssetsUrl}${getDefaultImagePath(ratio > 1)}`;
  const lockIcon = `${staticAssetsUrl}${getLockIcon(LOCK_ICON)}`;
  const mdIcon = `${staticAssetsUrl}${getMDIconPath(MD_ICON)}`;

  return {
    defaultImage,
    lockIcon,
    mdIcon,
  };
}

export { useCardIconsPaths };
