import React, { useMemo, useRef } from 'react';

import { DayProps } from 'components/pages/Epg/interface';
import DayHooksCombiner from './components/DayHooksCombiner/DayHooksCombiner';

import styles from './Day.module.scss';

interface Props {
  day: DayProps;
}

interface RefProps extends HTMLButtonElement {
  callBack: (value: DayProps) => void;
}

const Day = ({ day }: Props) => {
  const ref = useRef<RefProps | null>(null);

  function clickHandle(props: DayProps) {
    const callBack = ref.current?.callBack ?? null;

    if (typeof callBack === 'function') {
      callBack(props);
    }
  }

  const item = useMemo(() => {
    return (
      <button
        ref={ref}
        key={day.title}
        data-target={day.delta}
        className={styles.day}
        onClick={clickHandle.bind(null, day)}
        type='button'
      >
        {day.title}
      </button>
    );
  }, [day.day, day.delta, day.title]);

  return (
    <>
      {item}
      <DayHooksCombiner forwardRef={ref} day={day} />
    </>
  );
};

export default Day;
