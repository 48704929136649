import React from 'react';

import { useEpgStore } from 'hooks';
import { config } from 'components/pages/Epg/config';
import Channel from './components/Channel/Channel';

import styles from './Channels.module.scss';

function Channels() {
  const {
    epgStore: { tvChannels },
  } = useEpgStore();

  return (
    <ul
      className={styles.list}
      style={{
        width: config.channelWidth,
      }}
    >
      {tvChannels.map((channel) => (
        <Channel key={channel.id} channel={channel} />
      ))}
    </ul>
  );
}

export default Channels;
