import { useRef } from 'react';

import { useCardContext } from 'context/сardContext/сardContext';
import { useClickHandler } from '../useClickHandler/useClickHandler';
import { useHandleRef } from '../useHandleRef/useHandleRef';
import { useMouseEnterHandle } from '../useMouseEnterHandle/useMouseEnterHandle';
import { useMouseLeaveHandle } from '../useMouseLeaveHandle/useMouseLeaveHandle';

function useCardCombiner() {
  const handleLinkRef = useRef(null);
  const {
    itemWidth,
    data,
    resolvedCardProps: {
      imageHeight,
      setRef,
      collectionType,
      defaultImage,
      itemImageUrl,
      link,
      ref,
      isNoDataWithNoLogoImg,
      isShowStreams,
      isGrid,
      mediaTypeParam,
      providerName,
      isExternalProviderAsset,
      isLinkAsAsset,
      isPurchasable,
      isProgramAsset,
      isTypeOfSeries,
      isCollectionEpisode,
      isCatchupSeries,
      onHover,
      nextButtons,
      isCollectionInAppAdultLink,
      adult,
    },
  } = useCardContext();

  const handleRef = useHandleRef({ setRef, itemWidth, imageHeight });
  const onMouseEnter = useMouseEnterHandle({ ref, itemWidth, imageHeight, isGrid, onHover, nextButtons });
  const onMouseLeave = useMouseLeaveHandle(onHover);
  const clickHandlerForward = useClickHandler({
    data,
    link,
    mediaTypeParam,
    providerName,
    imageHeight,
    isExternalProviderAsset,
    isLinkAsAsset,
    isPurchasable,
    collectionType,
    isProgramAsset,
    isTypeOfSeries,
    isCatchupSeries,
    isCollectionEpisode,
    isCollectionInAppAdultLink,
    adult,
  });

  return {
    isShowStreams,
    itemWidth,
    imageHeight,
    collectionType,
    defaultImage,
    itemImageUrl,
    link,
    data,
    handleRef,
    isNoDataWithNoLogoImg,
    onMouseEnter,
    onMouseLeave,
    clickHandler: clickHandlerForward,
    handleLinkRef,
  };
}

export { useCardCombiner };
