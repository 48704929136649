import { ParentalControlStore } from '@kaltura-ott/tvpil-shared';
import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import { useSettingsStore } from './useSettingsStore';

function useParentalControlStore() {
  const { settingsStore } = useSettingsStore();

  const parentalControlStore = settingsStore?.children.find(
    ({ subtype }) => subtype === UIDAPresentationTypeEnum.parental_control,
  ) as ParentalControlStore;

  return { parentalControlStore };
}

export { useParentalControlStore };
