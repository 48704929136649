import React from 'react';

import { defaultPropsError } from 'components/widgets/Modal/constants';
import { checkResetInput } from '../../../utils/checkResetInput/checkResetInput';

export const onChange = (
  value: string,
  pinCode: string,
  setError: React.Dispatch<
    React.SetStateAction<{
      errorMessage: string;
      errorCode: string;
    }>
  >,
  setPinCode: React.Dispatch<React.SetStateAction<string>>,
) => {
  setPinCode(value);
  setError(defaultPropsError);
  checkResetInput(undefined, pinCode, setPinCode);
};
