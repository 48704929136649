import React from 'react';

import MaxiPlayerPosterV2 from 'components/widgets/Player/components/MaxiPlayerPosterV2/MaxiPlayerPosterV2';
import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import { createSkeletonCollection } from 'utils';
import VideoContent from '../VideoContent/VideoContent';
import { COMPONENT_NAME } from '../../constans';
import { useVideoPlayoutContext } from '../VideoPlayoutProvider/VideoPlayoutProvider';

import styles from './VideoPlayoutResolveRendering.module.scss';

const SKELETON_ITEMS_NUMBER = 3;
const SCAFFOLD_SKELETON_ITEMS_PREFIX = 'scaffold-skeleton';

function VideoPlayoutResolveRendering({ pageReferenceId }: { pageReferenceId: string }) {
  const { asset, loaded, isContentRestricted } = useVideoPlayoutContext();
  const isPlayerShowed = asset && loaded;
  const scaffolds = createSkeletonCollection(SKELETON_ITEMS_NUMBER, SCAFFOLD_SKELETON_ITEMS_PREFIX);

  return (
    <>
      {!isContentRestricted && <MaxiPlayerPosterV2 pageReferenceId={pageReferenceId} />}
      {isPlayerShowed ? (
        <VideoContent />
      ) : (
        <div className={styles.scaffold}>
          <LoaderWrapperForEvents
            hashKey={COMPONENT_NAME}
            component={scaffolds.map((scaffold) => (
              <div key={scaffold.id} className={styles.scaffoldItem} />
            ))}
          />
        </div>
      )}
    </>
  );
}

export default VideoPlayoutResolveRendering;
