import { contextStorage } from 'components/widgets/Player/storages';
import networkService from 'services/networkService';
import { resetError } from '../resetError/resetError';

export async function checkConnection() {
  const isConnection = await networkService.isNetworkConnection();

  if (isConnection) {
    await resetError();
  } else {
    const {
      errorContext: { stopLoadingError },
    } = contextStorage.getContext();
    stopLoadingError();
  }
}
