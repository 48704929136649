import { useEpgContext } from 'context/epgContext/epgContext';
import { useOnHandleDragOver } from '../../handlers';
import { useGridPerformance } from '../useGridPerformance/useGridPerformance';
import { useScrollTo } from '../useScrollTo/useScrollTo';

export function useGridMovementHooksCombiner() {
  const { epgParams, refTime, epgRef, forwardGridMovement, nowLineRef } = useEpgContext();
  const onDragOver = useOnHandleDragOver({ epgParams, forwardGridMovement });
  const { epgWidth } = epgParams;

  useGridPerformance();
  useScrollTo({ refTime, epgRef, nowLineRef, epgParams });

  return {
    onDragOver,
    epgWidth,
    forwardGridMovement,
  };
}
