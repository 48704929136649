import { useDeviceStore, useMenuStore, useScrollDirection } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import { useMenuInit } from '../useMenuInit/useMenuInit';
import { useHandleRef } from '../useHandleRef/useHandleRef';

export const useMenuWrapperCombiner = () => {
  const store = useMenuStore();
  const { isOnBoarding, isNotFound } = globalStorage;
  const { deviceLimitExceeded, householdList } = useDeviceStore();
  const isHouseHold = deviceLimitExceeded && householdList.length !== 0;
  const handleRef = useHandleRef();

  useScrollDirection();

  useMenuInit();

  return {
    store,
    isNotFound,
    isOnBoarding,
    isHouseHold,
    handleRef,
  };
};
