import React, { useRef } from 'react';
import { VariableSizeList as List } from 'react-window';

import { MediaAsset } from '@kaltura-ott/tvpil-shared/lib/stores/entities/MediaAsset';

import { ADDITIONAL_HEIGHT, CARD_PADDING, INITIAL_OFFSET } from '../../constants';
import { useRailLastXPosition } from '../../hooks/useRailLastXPosition/useRailLastXPosition';
import { calculateItemWidth, calculateItemPositionX } from '../../utils';
import { useRailHorisontalDataContext } from '../RailHorisontalDataProvider/RailHorisontalDataProvider';
import { AssetCardProvider } from '../RailCard/AssetCardProvider/AssetCardProvider';

import styles from './RailCardsList.module.scss';

type Props = {
  height: number;
  width: number;
};

function RailCardsList({ height, width }: Props) {
  const { forwardRailCardsListRef, isLogoRail, store } = useRailHorisontalDataContext();
  const { id, items, subtype } = store;
  const outerRef = useRef<HTMLDivElement | null>(null);
  const listWidth = width - 1;
  const savedOffset = useRailLastXPosition(id, subtype);
  const offset = forwardRailCardsListRef?.current?.state?.scrollOffset || savedOffset;

  return (
    <List
      ref={forwardRailCardsListRef}
      className={styles.itemsWrapper}
      height={height}
      initialScrollOffset={savedOffset}
      innerElementType='ul'
      itemCount={items.length}
      itemData={items}
      itemSize={(i) => calculateItemWidth(store, i)}
      layout='horizontal'
      outerRef={outerRef}
      // TODO: check next slide assets
      overscanCount={10}
      width={listWidth}
    >
      {({ index, style, data }) => {
        const positionX = calculateItemPositionX(store, index);
        const itemWidth = calculateItemWidth(store, index);
        const paddingTop = isLogoRail ? 0 : ADDITIONAL_HEIGHT - 10; // scale + shadow - design
        const outOfWindow = positionX + itemWidth > offset + width + itemWidth * 0.8; // some % to not disable hover
        const asset = data[index];
        return (
          <li
            key={asset.id}
            style={{
              ...style,
              boxSizing: 'border-box',
              left: Number(style.left) + INITIAL_OFFSET,
              paddingRight: CARD_PADDING,
              paddingTop,
            }}
          >
            <AssetCardProvider asset={asset as MediaAsset} disableHover={outOfWindow} listIndex={index} />
          </li>
        );
      }}
    </List>
  );
}

export default RailCardsList;
