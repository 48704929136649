import React from 'react';

import { KalturaRecordingType } from '@kaltura-ott/ott-client-types/api/types';

import { useRecordRepository } from 'hooks';
import { useRecord } from './useRecord/useRecord';
import { isSeriesRecord } from '../utils/utils';
import { BasicRecordButtonProps } from '../interface';

interface Props extends BasicRecordButtonProps {
  setPending: Function;
  setIsMenuOpen: Function;
  ref: React.MutableRefObject<HTMLDivElement | null>;
}

function useHandleMenuBtnClick({
  assetId,
  seriesId,
  programNotAvailable,
  callback,
  enableCatchUp,
  setPending,
  setIsMenuOpen,
  ref,
}: Props) {
  const recordRepository = useRecordRepository();
  const { addRecord, removeRecord } = useRecord({ setPending, setIsMenuOpen, ref });

  const record = recordRepository.getSingleRecord(assetId);
  const seriesRecord = recordRepository.getSeriesRecord(String(seriesId));

  function handleMenuBtnClick(event: React.MouseEvent<HTMLButtonElement>) {
    const type = event.currentTarget.dataset.value as KalturaRecordingType;
    const btnRecordType = type ?? KalturaRecordingType.single;
    const isAddAction = KalturaRecordingType.series === btnRecordType ? !seriesRecord : !record;

    if (isAddAction) {
      addRecord(btnRecordType, assetId, seriesId);
    } else {
      removeRecord(btnRecordType, record!, seriesRecord!, assetId, seriesId);

      if (!enableCatchUp || programNotAvailable) {
        const isNotHidingButtonsCondition = isSeriesRecord(record!) && programNotAvailable;

        callback(isNotHidingButtonsCondition);
      }
    }
  }

  return { handleMenuBtnClick };
}

export { useHandleMenuBtnClick };
