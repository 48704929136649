import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { RecordIcon } from 'components/presenters/icons';
import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { ActionButtonTypesEnum } from 'interface';

import RecordButtonPopup from './RecordButtonPopup/RecordButtonPopup';
import { RecordButtonProps, useRecordButtonHooksCombinerOutputProps } from './interface';
import { useRecordButtonHooksCombiner } from './hooks/useRecordButtonHooksCombiner';

import styles from './RecordButton.module.scss';

function RecordButton({
  assetId,
  seriesId,
  disabled,
  className,
  programNotAvailable,
  callback,
  enableCatchUp,
}: RecordButtonProps) {
  const {
    record,
    seriesRecord,
    pending,
    isMenuOpen,
    actionBtnDefaultMessage,
    isActiveRecordIcon,
    isMultipleRecordIcon,
    actionBtnId,
    ref,
    handleMenuBtnClick,
    handleRecordBtnClick,
  } = useRecordButtonHooksCombiner({
    assetId,
    seriesId,
    programNotAvailable,
    callback,
    enableCatchUp,
  }) as useRecordButtonHooksCombinerOutputProps;

  return (
    <div className={styles.wrapper}>
      {isMenuOpen && (
        <RecordButtonPopup
          isRecord={!!record}
          isSeriesRecord={!!seriesRecord}
          isActiveRecordIcon={isActiveRecordIcon}
          onClick={handleMenuBtnClick}
        />
      )}

      <ActionButton
        className={cn(className, { [styles.active]: isMenuOpen })}
        data-disabled={disabled ? 'disabled' : null}
        disabled={disabled}
        icon={
          <RecordIcon
            active={isActiveRecordIcon}
            className={cn({ [styles.recordIcon]: !!seriesRecord, [styles.pending]: pending })}
            multiple={isMultipleRecordIcon}
            pending={pending}
          />
        }
        isLarge
        onClick={handleRecordBtnClick}
        type={ActionButtonTypesEnum.secondary}
        forwardRef={ref}
      >
        <div className={cn({ [styles.hidden]: pending })}>
          <FormattedMessage defaultMessage={actionBtnDefaultMessage} id={actionBtnId} />
        </div>
      </ActionButton>
    </div>
  );
}

export default observer(RecordButton);
