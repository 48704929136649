import { MediaType } from '@kaltura-ott/tvpil-analytics';
import { Episode, Movie, Series, ProgramStateV2 } from '@kaltura-ott/tvpil-shared';

export function getUserActionAdditionalParams(asset: any) {
  const assetId = asset?.data?.id;
  const isLiveState = asset?.programState === ProgramStateV2.LIVE;
  const isVod = asset instanceof Movie || asset instanceof Episode || asset instanceof Series;
  const isRecording = asset?.record || asset?.seriesRecord;
  const isLive = asset?.linear && (asset?.linear?.currentProgram || isLiveState);
  let mediaType;

  if (isVod) {
    mediaType = MediaType.vod;
  } else if (isRecording) {
    mediaType = MediaType.recording;
  } else if (isLive) {
    mediaType = MediaType.live;
  } else {
    mediaType = MediaType.catchup;
  }

  return { assetId: String(assetId), mediaType };
}
