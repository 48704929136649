import React from 'react';

import { KalturaRecordingType } from '@kaltura-ott/ott-client-types/api/types';

import RecordButton from './RecordButton';
import { COMPONENT_NAME_FOR_TRANSLATION } from '../utils/translations';

import styles from './RecordButtonPopup.module.scss';

interface Props {
  isRecord: boolean;
  isSeriesRecord: boolean;
  isActiveRecordIcon: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export default function RecordButtonPopup({ isRecord, isSeriesRecord, isActiveRecordIcon, onClick }: Props) {
  return (
    <div className={styles.popup}>
      <RecordButton
        type={KalturaRecordingType.single}
        isActiveRecordIcon={isActiveRecordIcon}
        defaultMessage={isRecord ? 'Cancel Episode' : 'Record Episode'}
        id={
          isRecord
            ? `${COMPONENT_NAME_FOR_TRANSLATION}.CancelEpisode`
            : `${COMPONENT_NAME_FOR_TRANSLATION}.RecordEpisode`
        }
        onClick={onClick}
      />
      <RecordButton
        type={KalturaRecordingType.series}
        isActiveRecordIcon={isActiveRecordIcon}
        defaultMessage={isSeriesRecord ? 'Cancel Series' : 'Record Series'}
        id={
          isSeriesRecord
            ? `${COMPONENT_NAME_FOR_TRANSLATION}.CancelSeries`
            : `${COMPONENT_NAME_FOR_TRANSLATION}.RecordSeries`
        }
        onClick={onClick}
        className={styles.recordIcon}
        multiple
      />
    </div>
  );
}
