import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Provider } from '@kaltura-ott/tvpil';

import ActionButton from 'components/widgets/ActionButton/ActionButton';
import Checkbox from 'components/widgets/common/Checkbox/Checkbox';
import { DefaultModal } from 'components/widgets/Modal/DefaultModal/DefaultModal';
import Img from 'components/presenters/Img/Img';
import localStorageService from 'services/localStorageService';
import { DO_NOT_SHOW_AGAIN } from 'consts';
import { ActionButtonTypesEnum } from 'interface';

import styles from './ProviderActivationModal.module.scss';

interface Props {
  provider: string;
  providerLogo: string;
  providerSettings: Provider;
  watchAsset(): void;
}

const COMPONENT_NAME = 'ProviderActivationModal';

function ProviderActivationModal({ provider, providerLogo, providerSettings, watchAsset }: Props) {
  const { fullActivationUrl, isConfigured, shortActivationUrl, code } = providerSettings;

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      localStorageService.setItem(`${provider}${DO_NOT_SHOW_AGAIN}`, checked);
    },
    [provider],
  );

  return (
    <DefaultModal className={styles.modal} icon={<Img alt={`${provider} logo`} srcList={providerLogo} />}>
      <div>
        <FormattedMessage
          defaultMessage={`Do you have ${provider} account?`}
          id={`${COMPONENT_NAME}.titleForRegistered.${provider}`}
          tagName='h3'
        />
        <FormattedMessage
          defaultMessage={`Open ${provider} to start watching`}
          id={`${COMPONENT_NAME}.descriptionForRegistered.${provider}`}
          tagName='p'
        />
        <ActionButton type={ActionButtonTypesEnum.primary} onClick={watchAsset}>
          <FormattedMessage defaultMessage={`Go to ${provider}`} id={`${COMPONENT_NAME}.actionBtn.${provider}`} />
        </ActionButton>
        <Checkbox
          translationId={`${COMPONENT_NAME}.doNotShowAgain.${provider}`}
          defaultMessage='Don’t show this again'
          onChange={handleCheckboxChange}
        />
      </div>
      <div className={styles.withBorder}>
        <FormattedMessage
          defaultMessage={`Don't have ${provider} account?`}
          id={`${COMPONENT_NAME}.titleForUnregistered.${provider}`}
          tagName='h3'
        />
        {isConfigured ? (
          <>
            <FormattedMessage
              defaultMessage='Click here:'
              id={`${COMPONENT_NAME}.descriptionForUnregistered.${provider}`}
              tagName='p'
            />
            <a href={fullActivationUrl} rel='noopener noreferrer'>
              {shortActivationUrl}
            </a>
            {code && (
              <>
                <FormattedMessage
                  defaultMessage='And enter this code:'
                  id={`${COMPONENT_NAME}.activationInstruction.${provider}`}
                  tagName='p'
                />
                <span className={styles.code}>{code}</span>
              </>
            )}
          </>
        ) : (
          <FormattedMessage
            defaultMessage='It seems that your activation code was not provided yet. You need it for the fist registration.{br}Please write email to <strong>ott@o2.cz</strong> or try later.'
            id={`${COMPONENT_NAME}.notConfigured.${provider}`}
            tagName='p'
            values={{
              br: <br />,
              strong: (text: React.ReactNode) => <strong>{text}</strong>,
            }}
          />
        )}
      </div>
    </DefaultModal>
  );
}

export default ProviderActivationModal;
