import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppStore } from 'hooks/common/useAppStore';
import cdnService from 'services/cdnService';
import routes from 'routesPaths';

const isPlayerExist = (pathname: string) =>
  ![routes.notFound.path, routes.household.path, routes.auth.path].includes(pathname);

export function useAppPreparePlayer() {
  const {
    appStore: { dms },
  } = useAppStore();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const { playerSettings } = dms || {};
      if (isPlayerExist(location.pathname) && playerSettings) {
        await cdnService.preparePlayer(playerSettings);
      }
    })();
  }, [location.pathname, dms]);
}
