import React from 'react';

import { RailSettings } from '../RailProviderV2/types';
import MoreButton from '../MoreButton/MoreButton';
import { RAIL_TYPE } from '../../constants';

import styles from './Title.module.scss';

interface Props {
  isLogoRail: boolean;
  railSettings: RailSettings;
  forwardHeaderRef: any;
  railType: string;
  totalCount: number;
  itemsLength: number;
  hasNextPage: boolean;
}

const Title = ({
  forwardHeaderRef,
  isLogoRail,
  railSettings,
  railType,
  totalCount,
  itemsLength,
  hasNextPage,
}: Props) => {
  const { title, moreLink } = railSettings;
  const isShowingTitle = !!(title && !isLogoRail && railType !== RAIL_TYPE.HERO);
  const isShowedMoreButton = moreLink && !hasNextPage && itemsLength < totalCount;

  return (
    <>
      {isShowingTitle ? (
        <header className={styles.header} ref={forwardHeaderRef}>
          <h3 data-length={itemsLength} data-count={totalCount}>
            {title}
          </h3>
          {isShowedMoreButton && <MoreButton data={moreLink} />}
        </header>
      ) : null}
    </>
  );
};

export default Title;
