import { Link, NavigateFunction } from 'react-router-dom';

import { ParentalPinStoreFacade } from '@kaltura-ott/tvpil-shared/lib/facades/parental-pin-store-facade';
import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import { overlayStorage } from 'components/widgets/Player/storages';
import { unpause } from 'components/widgets/Player/utils/unpause/unpause';
import { saveAssetToGlobalStorage } from 'utils';
import { ContextType } from 'context/modal/interface';
import { checkParentalRestriction } from '../../../../utils';

interface Props {
  e: React.MouseEvent<typeof Link>;
  asset: ViewableEntitiesType;
  isLive?: boolean;
  callback?: () => void;
  closeOverlay?: (() => void) | undefined;
  navigate: NavigateFunction;
  modalContext: ContextType;
  parentalPinStore: ParentalPinStoreFacade;
}

export const handleClickDetailsButton = ({
  e,
  asset,
  isLive = false,
  callback,
  closeOverlay,
  navigate,
  modalContext,
  parentalPinStore,
}: Props) => {
  if (checkParentalRestriction(e, { asset, isLive, navigate, modalContext, parentalPinStore })) {
    const isOverlayOpen = overlayStorage.getShowOverlay();

    if (!isOverlayOpen) {
      saveAssetToGlobalStorage(asset, isLive);
    }

    if (callback) {
      callback();
    }
  }

  if (closeOverlay) {
    closeOverlay();
  }

  if (!isLive) {
    unpause();
  }
};
