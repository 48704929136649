import React from 'react';

import { CollectionGridSettings } from '@kaltura-ott/tvpil-shared';

import Card from 'components/widgets/Card/Card';
import { CardProvider } from 'context/сardContext/сardContext';
import { useResolveSeriesCatchUpGridCardProps } from '../SeriesCatchUpGridCardResolveRendering/hooks/useResolveSeriesCatchUpGridCardProps/useResolveSeriesCatchUpGridCardProps';

interface Props {
  data: any;
  gridSettingsData: CollectionGridSettings;
  itemWidth: number;
}

function SeriesCatchUpGridCardLogic({ data, gridSettingsData, itemWidth }: Props) {
  const resolvedSeriesCatchUpGridCardProps = useResolveSeriesCatchUpGridCardProps({ data, gridSettingsData });

  return (
    <CardProvider resolvedCardProps={resolvedSeriesCatchUpGridCardProps} itemWidth={itemWidth} data={data}>
      <Card />
    </CardProvider>
  );
}

export default React.memo(SeriesCatchUpGridCardLogic);
