import React from 'react';

import Img from 'components/presenters/Img/Img';
import { useAssetCardContext } from '../AssetCardContext/AssetCardContext';

import styles from './AssetCardLogo.module.scss';

function AssetCardLogo() {
  const { listStore, asset } = useAssetCardContext();
  const image = listStore.itemImage(asset.data);
  const { height, width, imageUrl } = image;

  if (!imageUrl) {
    return null;
  }

  return (
    <div
      style={{
        height,
        width,
      }}
    >
      <Img alt={asset.data.title} className={styles.logo} srcList={imageUrl} />
    </div>
  );
}

export default AssetCardLogo;
