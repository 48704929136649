import { useEffect } from 'react';

import { appErrorEventSender } from 'utils';
import { useIsNpvr } from './useIsNpvr';
import { useAppStore } from './useAppStore';
import { useDetectLocation } from './useDetectLocation';

export const useApplicationErrorEvents = () => {
  const { appStore } = useAppStore();
  const { apiError } = appStore;
  const isNpvr = useIsNpvr();
  const { isDetailsPage, isFullPlayer, isSearchPage, isEpg } = useDetectLocation();

  useEffect(() => {
    if (apiError) {
      appErrorEventSender(apiError[Object.keys(apiError)[0]], {
        isDetailsPage,
        isSearchPage,
        isFullPlayer,
        isNpvr,
        isEpg,
      });
    }
  }, [apiError]);
};
