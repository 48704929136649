import { CollectionAsset, useRecommendations, useRecommendationsSettings } from '@kaltura-ott/tvpil-shared';

import { RAIL_TYPE } from 'components/widgets/RailV2/constants';
import { RailProviderData, RailSettings } from 'components/widgets/RailV2/components/RailProviderV2/types';
import { useSetScrollPosition } from 'components/widgets/RailV2/hooks/useSetScrollPosition/useSetScrollPosition';
import { useRailEmptyCheck } from 'components/widgets/RailV2/hooks/useRailEmptyCheck/useRailEmptyCheck';
import { RAIL_PAGE_SIZE } from 'consts';

/**
 * `useRecommendationRail` is a custom React hook used for retrieving and managing data necessary
 * for displaying a recommendation rail in a web application. This hook combines recomedations settings,
 * channel data, and rail configuration.
 *
 * @param referenceId - A unique identifier of the recommendation rail widget.
 * @param pageReferenceId - A unique identifier of the page containing the recommendation rail widget.
 *
 * @returns An object containing:
 * - `settings`: Configurations and settings for the recomendations component's UI, obtained from `useRecommendationsSettings`.
 * - `railData`: An object structured as `RailProviderData<CollectionAsset>`, containing the CollectionAsset,
 *               total count, and loading state.
 * - `railType`: Set to `RAIL_TYPE.RECOMMENDATION`
 *               in this context, marking it as a RECOMMENDATIONS-type rail component.
 */

function useRecommendationRail(referenceId: string) {
  const settings = useRecommendationsSettings({ referenceId, imageSize: 'compact' });
  const data = useRecommendations({
    referenceId,
    pageSize: RAIL_PAGE_SIZE,
    useLimit: true,
  });
  const railType = RAIL_TYPE.RECOMMENDATION;

  const isRailEmpty = useRailEmptyCheck(data);

  useSetScrollPosition(!!data.items?.length);

  const railData: RailProviderData<CollectionAsset> = {
    items: data.items,
    totalCount: data.totalCount,
    hasNextPage: data.hasNextPage,
    isLoading: data.isLoading,
    error: data.error,
    fetchNextPage: data.fetchNextPage,
    refetch: data.refetch,
  };

  const railSettings: RailSettings = {
    branding: settings?.branding,
    id: settings?.id,
    imageHeight: settings?.imageHeight,
    imageType: settings?.imageType,
    imageWidth: settings?.imageWidth,
    title: settings?.title,
    moreLink: settings?.moreLink,
    shouldShowRating: settings?.shouldShowRating,
  };

  return {
    isRailEmpty,
    railData,
    railType,
    railSettings: settings ? railSettings : null,
  };
}

export { useRecommendationRail };
