import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SeriesAdditionalRailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { LAST_VIEW_SEASON_NUMBER } from 'consts';
import Select from 'components/widgets/common/Select/Select';

import styles from './Episodes.module.scss';

type Props = {
  seriesStore: SeriesAdditionalRailStoreV2;
  setupSeason: (seasonNumber: number) => void;
};

const COMPONENT_NAME = 'VideoDetails.Episodes';

const SelectEpisode = ({ seriesStore, setupSeason }: Props) => {
  const intl = useIntl();
  const { seasonsList: seasons, seasonNumber: seriesSeasonNumber } = seriesStore;
  const [selectedSeasonNumber, setSelectedSeasonNumber] = useState(seriesSeasonNumber);

  useEffect(() => {
    const number = sessionStorage.getItem(LAST_VIEW_SEASON_NUMBER);

    if (number) {
      setSelectedSeasonNumber(+number);
    }
  }, []);

  const handleChange = (event: MouseEvent) => {
    const element = event.currentTarget as HTMLInputElement;
    const { value = '' } = element.dataset;

    setupSeason(+value);
  };

  const options = seasons.map(({ seasonNumber, episodesCount }) => {
    return {
      value: seasonNumber,
      text: intl.formatMessage(
        {
          id: `${COMPONENT_NAME}.season`,
          defaultMessage: 'Season {value}',
        },
        { value: seasonNumber },
      ),
      additionalInfo: `(${intl.formatMessage(
        {
          defaultMessage: '{value, plural, one {# episode} other {# episodes}}',
          id: `${COMPONENT_NAME}.episode.${intl.formatPlural(episodesCount)}`,
        },
        { value: episodesCount },
      )})`,
    };
  });

  function renderSingleSeason() {
    return options.find((item) => Number(item.value) === selectedSeasonNumber)?.text;
  }

  return seasons.length > 0 && selectedSeasonNumber ? (
    <>
      <FormattedMessage defaultMessage='Episodes' id='VideoDetails.Episodes.title' tagName='h3' />
      {seasons.length === 1 ? (
        <div className={styles.singleSeason}>{renderSingleSeason()}</div>
      ) : (
        <Select onChange={handleChange} options={options} value={selectedSeasonNumber} />
      )}
    </>
  ) : (
    <FormattedMessage defaultMessage='All Episodes' id='VideoDetails.AllEpisodes.title' tagName='h3' />
  );
};

export default observer(SelectEpisode);
