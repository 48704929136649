import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { globalStorage } from 'services/globalStorage';
import routes from 'routesPaths';
import { useAppStore } from 'hooks';
import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import Loader from '../Loader/Loader';

import styles from './MainLoader.module.scss';

function MainLoader() {
  const { location } = useStableReactRouter();
  const { appStore } = useAppStore();
  const { loadingBgColor } = appStore.dms || {};

  useEffect(() => {
    if (location.pathname === routes.login.path) {
      globalStorage.clearIsLogoutInProgress();
    }

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [location.pathname]);

  if (loadingBgColor) {
    document.body.style.backgroundColor = loadingBgColor;
  }

  if (globalStorage.isLogoutInProgress) return null;

  return (
    <div style={{ backgroundColor: `${loadingBgColor}` }} className={styles.wrapper}>
      <LoaderWrapperForEvents hashKey='app' component={<Loader />} />
    </div>
  );
}

export default observer(MainLoader);
