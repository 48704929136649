import { useRef, useCallback } from 'react';

import { VariableSizeList as List } from 'react-window';

import { RAIL_TYPE } from 'components/widgets/RailV2/constants';
import {
  calculateItemHeight,
  calculateTotalWidth,
} from 'components/widgets/RailV2/utils/calculateItemDimensions/calculateItemDimensions';
import { useRailLastXPosition } from '../useRailLastXPosition/useRailLastXPosition';
import { useCheckResizeHook } from '../useCheckResizeHook/useCheckResizeHook';
import { useOnHoverHook } from '../useOnHoverHook/useOnHoverHook';
import { useOnCardClickHook } from '../useOnCardClickHook/useOnCardClickHook';
import { useApplyIncreaseHeightHook } from '../useApplyIncreaseHeightHook/useApplyIncreaseHeightHook';
import { RailData, RailSettings } from '../../types';

function useRailProviderCombiner<T extends { id: number }>(
  railSettings: RailSettings,
  railData: RailData<T>,
  railType: string,
  pageReferenceId?: string,
  referenceId?: string,
) {
  const savedOffset = useRailLastXPosition<T>({ railId: railSettings.id!, railType, items: railData.items });
  const offset = useRef(savedOffset);
  const isLogoRail = railType === RAIL_TYPE.LOGO;
  const headerRef = useRef<HTMLElement | null>(null);
  const railCardsListRef = useRef<List | null>(null);
  const refElementInner = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const setRailCardsListRefScrollTo = useCallback((value: number) => {
    if (railCardsListRef.current) {
      railCardsListRef.current.scrollTo(value);
      offset.current = value;
    }
  }, []);
  const componentHeight = calculateItemHeight(railSettings, isLogoRail);
  const totalWidth = calculateTotalWidth(railData.items.length, railSettings);
  const checkResize = useCheckResizeHook({
    setRailCardsListRefScrollTo,
    refElementInner,
    offset,
    totalWidth,
  });
  const onHover = useOnHoverHook({ headerRef, refElementInner, componentHeight });
  const onCardClick = useOnCardClickHook({ id: railSettings.id!, pageReferenceId, referenceId, offset });
  const nextButtons = useRef({
    isLeftNextButton: offset.current > 0,
    isRightNextButton:
      // @ts-ignore
      offset.current + (refElementInner?.current?.clientWidth || document.body.clientWidth) < totalWidth,
  });
  const setNextButtons = useCallback((value: any) => {
    nextButtons.current = value;
  }, []);

  useApplyIncreaseHeightHook({ refElementInner, componentHeight });

  return {
    checkResize,
    onHover,
    onCardClick,
    wrapperRef,
    totalWidth,
    componentHeight,
    headerRef,
    refElementInner,
    railCardsListRef,
    offset,
    setNextButtons,
    setRailCardsListRefScrollTo,
    nextButtons,
    isLogoRail,
    savedOffset,
  };
}

export { useRailProviderCombiner };
