import React from 'react';

import { ContentMarker as ContentMarkerType } from '@kaltura-ott/tvpil-shared/lib/stores/entities/MediaAsset';
import { Program } from '@kaltura-ott/tvpil-shared';

import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import { NewEpisodeBadge, ContentMarker, Rating, RentedBadge } from 'components/presenters/icons';
import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import { useVideoContext } from 'context/videoContext/videoContext';
import { SpecificationsDetails } from './SpecificationsDetails';

import styles from '../../VideoContent.module.scss';

type Props = {
  isRented: boolean;
  isDisplayContentMarker: boolean | ContentMarkerType;
};

export const Specifications = ({ isRented, isDisplayContentMarker }: Props) => {
  const { program } = useVideoContext();
  const isNewEpisode = (program as Program)?.isNewEpisode;
  const { duration, contentMarker, isProviderAsset } = detailsStoreDataResolver(program);
  const { rating } = program;

  return (
    <div className={styles.specifications}>
      {!isProviderAsset && (
        <>
          {isNewEpisode && <NewEpisodeBadge translationId={COMPONENT_NAME} className={styles.newEpisodeBadge} />}
          {isRented && <RentedBadge className={styles.rentedBadge} translationId={`${COMPONENT_NAME}.VideoContent`} />}
          {isDisplayContentMarker && <ContentMarker className={styles.contentMarker} contentMarker={contentMarker!} />}
        </>
      )}
      {rating && <Rating rating={rating} isDetailsInfo />}
      <SpecificationsDetails duration={duration} />
    </div>
  );
};
