import { useEffect, startTransition } from 'react';
import { useParams } from 'react-router-dom';

import { Linear } from '@kaltura-ott/tvpil-shared';
import { MediaType } from '@kaltura-ott/tvpil';

import { getNormalizeMediaType } from 'utils';
import { useCleanupState, useDetectLocation, useIsNpvr, useRootStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';

export const useVideoDetailsInit = () => {
  const [detailsInfo, setDetailsInfo] = useCleanupState({
    loaded: false,
  });
  const rootStore = useRootStore();
  const { detailsStore } = rootStore!;
  const { programId, mediaType: mediaTypeParam, externalId } = useParams();
  const mediaType = mediaTypeParam && getNormalizeMediaType(mediaTypeParam);
  const isNpvr = useIsNpvr();
  const { isDetailsSeries, seriesId, isFutureNpvr } = useDetectLocation();
  const isRecord = isFutureNpvr ? false : isNpvr; // for avoid the bugs with future recording
  const asset = globalStorage.detailsAsset;
  const isEqualsProgram = asset?.id === +programId!;
  const isEqualsLinear = asset instanceof Linear && asset?.currentProgram?.data?.id === +programId!;
  const initParams =
    (isEqualsProgram || isEqualsLinear) && !externalId
      ? { asset }
      : { id: !externalId ? +programId! : undefined, seriesId };

  useEffect(() => {
    async function effect() {
      if (programId || externalId) {
        await detailsStore.init({
          ...initParams,
          type: mediaType as MediaType,
          isDetailsSeries,
          isRecord,
          externalId,
          loadMosaicAsset: true,
        });

        globalStorage.clearDetailsAsset();

        if (detailsStore.asset?.data) {
          if (detailsStore.asset.data.images) {
            startTransition(() => {
              setDetailsInfo((prevState: { loaded: boolean }) => ({
                ...prevState,
                loaded: true,
              }));
            });
          }
        }
      }
    }

    effect();

    return () => {
      setDetailsInfo((prevState: { loaded: boolean }) => ({
        ...prevState,
        loaded: false,
      }));
      detailsStore.resetStore();
    };
  }, [detailsStore, programId, setDetailsInfo, isDetailsSeries, externalId]);

  return detailsInfo;
};
