import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Program } from '@kaltura-ott/tvpil-shared';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import VodPlayer from 'components/widgets/Player/VodPlayer';
import routes from 'routesPaths';
import { useAppStore, useGoBack, useRootStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import FullPlayerLoadingScreen from './FullPlayerLoadingScreen';
import { useCleanupState, useDeviceStore } from '../../../hooks';
import { useCheckMdUiDisable } from './hooks';

import styles from './VideoPage.module.scss';

interface Props {
  catchUp?: boolean;
  vod?: boolean;
}

const VodVideoPage: React.FC<Props> = ({ catchUp = false, vod = false }) => {
  const { householdDevice } = useDeviceStore();
  const goBack = useGoBack();
  const isHouseholdDeviceNotEmpty = !!householdDevice?.householdId;
  const [loaded, setLoaded] = useState(false);
  const [recordId, setRecordId] = useState(0);
  const [error, setError] = useCleanupState(false);
  const isRecord = !catchUp && !vod;
  const { id, externalId } = useParams<{ id: string; externalId?: string }>();
  const isPlayerCondition = loaded && isHouseholdDeviceNotEmpty;
  const playableAsset = globalStorage.focusAsset;

  const {
    appStore: { playoutStoreV2 },
  } = useAppStore();
  const rootStore = useRootStore();
  const { uiDesignElements } = rootStore!;
  const assetId = Number(id);

  let type = PlayoutStoreV2Type.CATCHUP;

  if (vod) {
    type = PlayoutStoreV2Type.VOD;
  } else if (isRecord) {
    type = PlayoutStoreV2Type.NPVR;
  }

  useEffect(() => {
    async function vodEffect() {
      if (playoutStoreV2) {
        const initParams = playableAsset ? { playableAsset } : { id: assetId };
        await playoutStoreV2.init({
          ...initParams,
          type,
          autoplayNextProgram: catchUp,
          saveLastWatched: true,
          mosaic: {
            externalId,
          },
        });

        setLoaded(!!playoutStoreV2.currentAsset?.id);
        globalStorage.clearFocusAsset();
      }

      if (!playoutStoreV2.currentAsset) {
        setError(true);
      }
    }
    if (assetId && !loaded) {
      vodEffect();
    }

    if (uiDesignElements) {
      document.body.style.backgroundColor = uiDesignElements.backgroundColor;
    }
  }, [loaded]);

  useEffect(() => {
    return () => {
      playoutStoreV2.stopPlayback();
    };
  }, [playoutStoreV2]);

  useEffect(() => {
    async function effect() {
      const { currentAsset } = playoutStoreV2;
      if (!currentAsset) return;

      if (isRecord) {
        const { record: singleRecord } = currentAsset as Program;

        if (singleRecord?.data.id) {
          setRecordId(singleRecord?.data.id);
        }
      }
    }

    effect();
  }, [playoutStoreV2.currentAsset, isRecord, recordId]);

  useCheckMdUiDisable();

  if (error) {
    if (isRecord) {
      goBack();
    } else {
      return <Navigate to={routes.notFound.path} />;
    }
  }

  return (
    <div className={styles.videoPage}>
      <FullPlayerLoadingScreen vod={vod} />
      {isPlayerCondition && <VodPlayer assetId={recordId ? +recordId : +id!} />}
    </div>
  );
};

export default observer(VodVideoPage);
