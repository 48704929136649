import { EpgParamsType } from 'types';
import { CHANNEL_COLUMN_WIDTH } from '../../constans';
import { moveAlongX } from '../../helpers';
import { formatPixelsToTimestamp } from '../../utils';

export const onHandleKeyDown = (
  event: KeyboardEvent,
  ref: React.MutableRefObject<HTMLDivElement | null>,
  epgParams: EpgParamsType,
) => {
  const step = formatPixelsToTimestamp(
    epgParams.ratio,
    Math.round((ref.current?.clientWidth! - CHANNEL_COLUMN_WIDTH) * 0.1),
  );

  if (event.keyCode === 37) {
    moveAlongX(-step, epgParams);
  }
  if (event.keyCode === 39) {
    moveAlongX(step, epgParams);
  }
};
