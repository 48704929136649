import { useEffect } from 'react';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { PerformanceContextType, PerformanceEvents } from 'interface';
import { checkLoadingLifecycle, APPLICATION_SPINNER_EVENT_NAME } from 'utils';

interface Props {
  store: RailStoreV2;
  isSkeletonCondition: boolean;
  performanceEventsContext: PerformanceContextType;
}

export const usePerformanceSpinerHook = ({ store, isSkeletonCondition, performanceEventsContext }: Props) => {
  useEffect(() => {
    if (!store.isRailEmpty) {
      const hashKeyForSpinerEvent = `${APPLICATION_SPINNER_EVENT_NAME.RAIL}-id:${store.id}-name:${store.name}`.toLowerCase();

      if (isSkeletonCondition) {
        performanceEventsContext.setStartTime(PerformanceEvents.spinner, hashKeyForSpinerEvent);
      } else {
        checkLoadingLifecycle(hashKeyForSpinerEvent, performanceEventsContext);
      }
    }
  }, [store.isRailEmpty, store.isReady, isSkeletonCondition]); // eslint-disable-line
};
