import React from 'react';
import cn from 'classnames';

import { ExponeaUiResponse } from '@kaltura-ott/tvpil-shared';

import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { ActionButtonTypesEnum } from 'interface';
import { resolveImgClass } from './utils';
import { useResolveRedirectButton } from './hooks/useResolveRedirectButton';

import styles from './ExponeaBanner.module.scss';

interface Props {
  exponeaBanner: ExponeaUiResponse;
  closeBannerHandle(): void;
}

export default function ExponeaBanner({ exponeaBanner, closeBannerHandle }: Props) {
  const { img, title, ratio, main_text: description, buttons } = exponeaBanner;
  const { text, url, openAs } = useResolveRedirectButton(buttons);
  const resolvedImgClass = resolveImgClass(ratio);

  return (
    <div className={styles.wrapper}>
      {!!img && (
        <div className={cn(styles[`img-${resolvedImgClass}`])}>
          <img alt={title} src={img} />
        </div>
      )}
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        {!!description && <span className={styles.description}>{description}</span>}
        {!!text && (
          <ActionButton
            onClick={closeBannerHandle}
            className={styles.button}
            to={url}
            target={openAs}
            type={ActionButtonTypesEnum.primary}
          >
            {text}
          </ActionButton>
        )}
      </div>
    </div>
  );
}
