import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

import { MediaType } from '@kaltura-ott/tvpil';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared';
import { DetailsStore } from '@kaltura-ott/tvpil-shared/lib/stores/category/details/details-store';

import { playoutStoreDataResolver } from 'utils';
import arrowLeft from 'assets/images/player/back.svg';
import { useCleanupState, useCloseOverlayOnPlaybackEnded, useRootStore } from 'hooks';
import Modal from 'components/widgets/Modal/Modal';
import VideoContent from '../components/VideoContent/VideoContent';
import { getNormalizeOverlayMediaType } from './utils/getNormalizeOverlayMediaType';

import styles from './OverlayDetails.module.scss';

interface Props {
  onClose(): void;
  switchToLive?(): void;
  programId?: number;
}

function OverlayDetails({ onClose, programId, switchToLive }: Props) {
  const [loaded, setLoaded] = useCleanupState(false);
  const { mediaType: mediaTypeParam } = useParams<{ mediaType: MediaType }>();
  const rootStore = useRootStore();
  const { detailsStore } = rootStore!;
  const { asset } = detailsStore as DetailsStore;
  const { type } = playoutStoreDataResolver();
  const isRecord = type === PlayoutStoreV2Type.NPVR;
  const mediaType = getNormalizeOverlayMediaType(mediaTypeParam);

  useCloseOverlayOnPlaybackEnded(loaded);

  useEffect(() => {
    async function effect() {
      if (programId) {
        await detailsStore.init({
          isRecord,
          id: +programId,
          type: mediaType,
          isDetailsSeries: false,
          asset: undefined,
          seriesId: undefined,
          loadMosaicAsset: true,
        });
        setLoaded(true);
      }
    }

    effect();

    return () => {
      setLoaded(false);
    };
  }, [detailsStore, programId, setLoaded, mediaTypeParam]);

  return (
    <Modal className={styles.modalContent} overlayClassName={styles.modalOverlay}>
      <div className={cn(styles.detailsModal, styles.overlay)}>
        <button className={styles.back} onClick={onClose} type='button'>
          <SVG src={arrowLeft} />
        </button>
        {asset && loaded && (
          <VideoContent switchToLive={switchToLive} isOverlay onCloseModal={onClose} program={asset} />
        )}
      </div>
    </Modal>
  );
}

export default observer(OverlayDetails);
