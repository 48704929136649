import React from 'react';
import StableAutoSizer from 'components/widgets/RailV2/components/StableAutoSizer/StableAutoSizer';
import VirtualizedContainer from 'components/widgets/RailV2/components/VirualizedContainer/VirtualizedContainer';
import NextButtons from 'components/widgets/RailV2/components/NextButtonsV2/NextButtonsV2';
import { ChildProps, Item } from '../VirualizedContainer/types';
import { OnItemsRendered } from '../RailProviderV2/types';

interface RailViewerItem extends Item {}

export type HeroRailContentProps<T extends RailViewerItem> = {
  /**
   * Children function that renders the content inside the VirtualizedContainer
   * and returns a React Node.
   */
  children: ({ imageHeight, itemWidth, item }: ChildProps<T>) => React.ReactNode;
  onItemsRendered?: OnItemsRendered;
};

/**
 * The RailViewer component creates a virtualized container for efficiently rendering
 * a large list of items (like images or cards) within a horizontal scroll interface.
 * It automatically adjusts its size using the StableAutoSizer component and includes next/previous
 * buttons for navigation.
 *
 * @template T - The type of the items in the rail.
 * @param props - The HeroRailContentProps, expecting a children function for rendering the Rail card.
 * @returns The RailViewer component with a virtualized container and navigation buttons.
 */
function RailViewer<T extends RailViewerItem>({ children, onItemsRendered }: HeroRailContentProps<T>) {
  return (
    <>
      <StableAutoSizer>
        {({ width, height }) => (
          <>
            <VirtualizedContainer<T> width={width} height={height!} onItemsRendered={onItemsRendered}>
              {children}
            </VirtualizedContainer>
            <NextButtons width={width} />
          </>
        )}
      </StableAutoSizer>
    </>
  );
}

export default RailViewer;
