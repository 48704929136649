import React from 'react';
import { observer } from 'mobx-react';

import { useRecordRepository } from 'hooks';
import { useIntl } from 'react-intl';
import { useContentRestriction } from 'components/widgets/RailV2/HeroRail/components/HeroCard/hooks/useContentRestriction';
import CardTitleStaticView from './components/CardTitleStaticView/CardTitleStaticView';
import CardTitleBubbleView from './components/CardTitleBubbleView/CardTitleBubbleView';

const CardTitle = ({
  isStaticInfo,
  title,
  isWrapperWithIcon,
  seasonEpisodeTitle,
  isMosaicProgram,
  isAdult,
  seriesId,
  programId,
}: {
  isStaticInfo?: boolean;
  title: string;
  isWrapperWithIcon?: boolean;
  seasonEpisodeTitle?: string;
  isMosaicProgram: boolean;
  isAdult: boolean;
  programId?: number;
  seriesId?: string;
}) => {
  // for avoiding wrapping the whole parent component with observer
  const recordRepository = useRecordRepository();
  const record = programId ? recordRepository.records.get(programId.toString()) : null;
  const seriesRecord = seriesId ? recordRepository.seriesRecords.get(seriesId) : null;
  // for avoiding wrapping the whole parent component with observer
  const isContentRestricted = useContentRestriction(isAdult);
  // translation and title
  const intl = useIntl();
  const restrictedTitle = intl.formatMessage({
    id: 'Rail.RailCard.restrictedContent',
    defaultMessage: 'Restricted content',
  });
  const cardTitle = isContentRestricted ? restrictedTitle : title;

  return (
    <>
      {isStaticInfo ? (
        <CardTitleStaticView
          title={cardTitle}
          seasonEpisodeTitle={seasonEpisodeTitle}
          isRecord={!!record || !!seriesRecord}
          isWrapperWithIcon={isWrapperWithIcon}
          isWithSeriesRecord={!!seriesRecord}
          isSeriesType={!!seriesRecord}
          isMosaicProgram={isMosaicProgram}
        />
      ) : (
        <CardTitleBubbleView
          title={cardTitle}
          isRecord={!!record || !!seriesRecord}
          isWrapperWithIcon={isWrapperWithIcon}
          isWithSeriesRecord={!!seriesRecord}
          isSeriesType={!!seriesRecord}
          isMosaicProgram={isMosaicProgram}
        />
      )}
    </>
  );
};

export default observer(CardTitle);
