import React from 'react';
import cn from 'classnames';

import { MasonryScroller } from 'masonic';

import { useGridCollectionCardsViewCombiner } from './hooks/useGridCollectionCardsViewCombiner/useGridCollectionCardsViewCombiner';
import GridCollectionCardResolveRendering from '../GridCollectionCardResolveRendering/GridCollectionCardResolveRendering';

import styles from './GridCollectionCardsView.module.scss';

interface Props {
  className?: string;
}

function GridCollectionCardsView({ className }: Props) {
  const {
    containerRef,
    windowHeight,
    columnWidth,
    defaultHeight,
    positioner,
    offset,
    gridItems,
    resizeObserver,
    nextPageHandle,
    isFullLoaded,
    totalGridHeight,
  } = useGridCollectionCardsViewCombiner();

  return (
    <div className={cn(styles.wrapper, className, { [styles.fullLoadded]: isFullLoaded })}>
      <MasonryScroller
        className={styles.grid}
        containerRef={containerRef}
        height={isFullLoaded ? totalGridHeight : windowHeight}
        items={gridItems}
        itemStyle={{ width: columnWidth, height: defaultHeight }}
        offset={offset}
        positioner={positioner}
        itemKey={(data, index) => data?.id || index}
        render={GridCollectionCardResolveRendering}
        onRender={nextPageHandle}
        resizeObserver={resizeObserver}
        overscanBy={3}
        itemHeightEstimate={defaultHeight}
      />
    </div>
  );
}

export default GridCollectionCardsView;
