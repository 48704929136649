import React from 'react';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { useRailSkeletonSizer } from 'hooks';
import { DEFAULT_HEIGHT } from 'consts';
import { AssetCardProvider } from '../../../components/RailCard/AssetCardProvider/AssetCardProvider';
import { RailSkeletonAsset } from '../../../components/RailSkeleton/RailSkeleton';
import { useRailContext } from '../../../RailHorisontal/components/RailProvider/RailProvider';
import AssetCardResolveRendering from '../../../components/RailCard/AssetCardResolveRendering/AssetCardResolveRendering';

const RailGridResolveRendering = ({ data, index }: { data: any; index: number }) => {
  const { store } = useRailContext();
  const { columnWidth } = useRailSkeletonSizer(store);
  const { lastPageWasLoaded } = (store as RailStoreV2 | any) || {};
  if (!data.data) {
    // hide skeleton items if all grid items were loaded
    if (lastPageWasLoaded) {
      return null;
    }
    return <RailSkeletonAsset height={store.railHeight || DEFAULT_HEIGHT} width={columnWidth} />;
  }

  if (store.isRailEmpty) {
    return null;
  }

  return (
    <AssetCardProvider asset={data} listIndex={index}>
      <AssetCardResolveRendering />
    </AssetCardProvider>
  );
};

export default React.memo(RailGridResolveRendering);
