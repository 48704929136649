import React, { createContext, useMemo, useContext } from 'react';

import { MenuStore } from '@kaltura-ott/tvpil-shared';

interface Props {
  children: React.ReactNode;
  isOnBoarding?: boolean;
  isNotFound?: boolean;
  store: MenuStore;
}

export interface ContextType {
  isOnBoarding: boolean;
  isNotFound: boolean;
  store: MenuStore;
}

const MenuContext = createContext<ContextType>({
  isOnBoarding: false,
  isNotFound: false,
  store: {} as MenuStore,
});

const MenuContextProvider = ({ children, isOnBoarding = false, isNotFound = false, store }: Props) => {
  const contextValue = useMemo(() => {
    return { isOnBoarding, isNotFound, store };
  }, [isOnBoarding, isNotFound, store]);

  return <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>;
};

const useMenuContext = () => useContext(MenuContext);

export { MenuContextProvider, useMenuContext };
