import { appStore } from '@kaltura-ott/tvpil-shared';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { isSafari } from 'utils';
import { playerServiceManagement } from '../../services/PlayerServiceManagement';
import { startOverInfoStorage } from '../../storages';
import { focusOnPlayerNode } from '../../utils/focusOnPlayerNode/focusOnPlayerNode';

export async function handleStartOver({ payload }: any) {
  const playerService = playerServiceManagement.getPlayerService();
  const { startTime, normalizedStartTime, epgId } = payload;

  if (appStore?.playoutStoreV2?.type === PlayoutStoreV2Type.START_OVER) {
    startOverInfoStorage.setStartOver(true);
    await playerService.startOver(epgId, startTime);
  } else {
    const startOverStartTime = isSafari() ? normalizedStartTime : startTime;
    startOverInfoStorage.setStartOver(true, false, startOverStartTime);

    await appStore?.playoutStoreV2.init({
      id: epgId,
      type: PlayoutStoreV2Type.START_OVER,
      autoplayNextProgram: true,
    });
  }

  focusOnPlayerNode();
}
