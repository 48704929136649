import { useMemo } from 'react';

import { QuotaStatusEnum } from '@kaltura-ott/tvpil';
import { RhinoEPGProgram, RhinoProgram, isProgramNotFound, useLinears } from '@kaltura-ott/tvpil-shared';

import { config } from 'components/pages/EpgV2/config';
import { epgLocalTimeStampStorage } from 'components/pages/EpgV2/storages';
import { MD_ICON, MS_IN_SEC, SEC_IN_HOUR } from 'consts';
import { useEpgContext } from 'context/epgContext/epgContext';
import { useRecordRepository, useAppStore, useParentalPinStore } from 'hooks';
import { formatLocalTime, getMDIconPath } from 'utils';
import { textStyles } from '../../constants';

export function useConditionsResolver(program: RhinoEPGProgram) {
  const {
    appStore: {
      staticAssetsUrl,
      localizationStore: { displayLanguage, latinLanguage },
    },
  } = useAppStore();
  const { epgParams } = useEpgContext();
  const parentalPinStore = useParentalPinStore();
  const recordRepository = useRecordRepository();
  const { linears } = useLinears();
  const channel = linears.get((program as RhinoProgram).channelId);
  const isNotFound = !!(channel && isProgramNotFound(program as RhinoProgram, channel));
  const { startDate, endDate, hasMosaic, isSeries, isAdult, mosaicExternalId } = program as RhinoProgram;
  const isRestrictedByAdult = !!isAdult && !parentalPinStore.isAdultPinEntered;
  const record = recordRepository.records.get(program.id.toString());
  const seriesRecord = isSeries ? recordRepository.seriesRecords.get((program as RhinoProgram).seriesId!) : null;

  return useMemo(() => {
    const isFutureDate = startDate > epgLocalTimeStampStorage.currentTimestamp;
    const isSeriesRecord = !!((record && record.isSeriesRecord) || seriesRecord);
    const isMdIconCondition = !!hasMosaic;
    const iconMDUrl = `${staticAssetsUrl}${getMDIconPath(MD_ICON)}`;
    const duration = endDate - startDate;
    const width = (config.hourScale * duration) / (SEC_IN_HOUR * MS_IN_SEC);
    const time = formatLocalTime({ date: startDate, locale: latinLanguage || displayLanguage });
    const isCatchUp =
      startDate < epgLocalTimeStampStorage.currentTimestamp && endDate < epgLocalTimeStampStorage.currentTimestamp;
    const isAdultCathUpPlaybackEnabled = !!(!isNotFound && isAdult && channel?.data.enableCatchUp && isCatchUp);
    const isCatchUpWithNoData = isCatchUp && isNotFound;
    const isAdultCatchUp = !!(isAdult && isCatchUp);
    const isNpvrEnabled = recordRepository.space.status !== QuotaStatusEnum.NpvrServiceNotAllowed;
    const isRecordIconCondition = !!(record || seriesRecord) && isNpvrEnabled;
    const isLive =
      startDate <= epgLocalTimeStampStorage.currentTimestamp && endDate >= epgLocalTimeStampStorage.currentTimestamp;
    const isLoading = !!(program as RhinoEPGProgram).isPlaceholder;
    const isDescriptionHidden = width < textStyles.title.padding;
    const widthForTimeEllipsis = isRecordIconCondition || isMdIconCondition ? width - 20 : width;
    const isOnlyMd = isRecordIconCondition && isMdIconCondition && !!(width < config.minWidthForRecordAndMd);
    const isWithoutMd = isMdIconCondition && width < config.minWidthForMdIcon;

    return {
      time,
      width,
      isLive,
      duration,
      isOnlyMd,
      epgParams,
      iconMDUrl,
      startDate,
      isCatchUp,
      isLoading,
      isNotFound,
      isWithoutMd,
      isFutureDate,
      isSeriesRecord,
      isAdultCatchUp,
      mosaicExternalId,
      isMdIconCondition,
      isCatchUpWithNoData,
      isRestrictedByAdult,
      isDescriptionHidden,
      widthForTimeEllipsis,
      isRecordIconCondition,
      isAdultCathUpPlaybackEnabled,
    };
  }, [record, seriesRecord, isRestrictedByAdult, epgLocalTimeStampStorage.currentTimestamp]);
}
