import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import App from 'App';
import AppContext from 'context/appContext/appContext';

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <AppContext>
        <App />
      </AppContext>
    ),
  },
]);

export { router };
