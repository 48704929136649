import React from 'react';
import cn from 'classnames';
import SVG from 'react-inlinesvg';

import KalturaPlayer from 'components/widgets/Player/KalturaPlayer';
import { globalStorage } from 'services/globalStorage';
import { GEOBLOCK_ICON, LOCK_ICON } from 'consts';
import { getLockIcon } from 'utils';
import { useAppStore } from 'hooks';
import { useVideoPlayoutContext } from '../VideoPlayoutProvider/VideoPlayoutProvider';

import styles from './PlayerView.module.scss';

const PlayerView: React.FC = () => {
  const { isPlayerCondition, playableAssetId, muted, isContentGeoBlocking } = useVideoPlayoutContext();
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  if (!isPlayerCondition) {
    globalStorage.setPossiblePresentPlayer(false);
  }

  return isPlayerCondition ? (
    <div className={styles.videoContainer}>
      <div className={cn(styles.playerContainer, styles.playerDisplayed)}>
        <div className={styles.leftGradient} />
        <KalturaPlayer assetId={playableAssetId} hideUI isLive muted={muted} isMaxi />
      </div>
    </div>
  ) : (
    <div className={styles.lock}>
      <SVG src={`${staticAssetsUrl}${getLockIcon(isContentGeoBlocking ? GEOBLOCK_ICON : LOCK_ICON)}`} />
    </div>
  );
};

export default PlayerView;
