import { KuxRating } from '@kaltura-ott/tvpil';

import { CONCURRENCY_ICON_PATH, SLOW_CONNECTION_ICON_PATH } from 'components/widgets/Player/constants';
import {
  SEARCH_HISTORY_ICON_PATH,
  SERIES_RECORD,
  SERIES_RECORD_ACTIVE,
  SINGLE_RECORD,
  SINGLE_RECORD_ACTIVE,
  WARNING_ICON,
  FAVORITE_ICON_PATH,
  FAVORITE_ICON_BIG,
  FAVORITE_ICON_FILLED,
  MD_ICON,
  PENDING_RECORD,
  SEARCH_ICON_PATH,
  PENDING_PURCHASE_SMALL,
  CLOCK,
  VOICE_ICON,
  SHOPPING_CART_ICON,
  SUBTITLES_ICON,
  SVG_ADDITIONAL_PARAM,
  GEOBLOCK_ICON,
  LOCK_ICON,
} from 'consts';
import {
  getConcurrencyIcon,
  getDefaultImagePath,
  getFavoriteChannelIcon,
  getMDIconPath,
  getPurchaseIcon,
  getRatingIconPath,
  getRecordIcon,
  getSearchHistoryIcon,
  getSearchIcon,
  getSubtitlesIcon,
  getVoiceIcon,
  getWarningIcon,
  getLockIcon,
} from 'utils/getImagePath';

export const getImageList = (staticAssetsUrl: string) => [
  `${staticAssetsUrl}${getRecordIcon(SINGLE_RECORD)}${SVG_ADDITIONAL_PARAM}`,
  `${staticAssetsUrl}${getRecordIcon(SERIES_RECORD)}${SVG_ADDITIONAL_PARAM}`,
  `${staticAssetsUrl}${getRecordIcon(SINGLE_RECORD_ACTIVE)}${SVG_ADDITIONAL_PARAM}`,
  `${staticAssetsUrl}${getRecordIcon(SERIES_RECORD_ACTIVE)}${SVG_ADDITIONAL_PARAM}`,
  `${staticAssetsUrl}${getRecordIcon(PENDING_RECORD)}`,
  `${staticAssetsUrl}${getWarningIcon(WARNING_ICON)}`,
  `${staticAssetsUrl}${getConcurrencyIcon(CONCURRENCY_ICON_PATH)}`,
  `${staticAssetsUrl}${getFavoriteChannelIcon(FAVORITE_ICON_PATH)}`,
  `${staticAssetsUrl}${getFavoriteChannelIcon(FAVORITE_ICON_FILLED)}`,
  `${staticAssetsUrl}${getFavoriteChannelIcon(FAVORITE_ICON_BIG)}`,
  `${staticAssetsUrl}${getMDIconPath(MD_ICON)}`,
  `${staticAssetsUrl}${getSearchHistoryIcon(SEARCH_HISTORY_ICON_PATH)}`,
  `${staticAssetsUrl}${getSearchIcon(SEARCH_ICON_PATH)}`,
  `${staticAssetsUrl}${getPurchaseIcon(SHOPPING_CART_ICON)}`,
  `${staticAssetsUrl}${getPurchaseIcon(PENDING_PURCHASE_SMALL)}`,
  `${staticAssetsUrl}${getPurchaseIcon(CLOCK)}`,
  `${staticAssetsUrl}${getDefaultImagePath(false)}`,
  `${staticAssetsUrl}${getDefaultImagePath(true)}`,
  `${staticAssetsUrl}${getVoiceIcon(VOICE_ICON)}`,
  `${staticAssetsUrl}${getSubtitlesIcon(SUBTITLES_ICON)}`,
  `${staticAssetsUrl}${getWarningIcon(SLOW_CONNECTION_ICON_PATH)}`,
  `${staticAssetsUrl}${getLockIcon(GEOBLOCK_ICON)}`,
  `${staticAssetsUrl}${getLockIcon(LOCK_ICON)}`,
];

export const getRatingImageList = (staticAssetsUrl: string, images: KuxRating[]): string[] => {
  const ratingImageList: string[] = [];

  images?.forEach(({ long, short }: KuxRating) => {
    if (long || short) {
      ratingImageList.push(`${staticAssetsUrl}${getRatingIconPath(long || short)}`);
    }
  });

  return ratingImageList;
};
