/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { PurchaseStates } from '@kaltura-ott/tvpil';

import { useTransactionStore, useParentalPinStore, useSettingsStore, useAppStore, useRootStore } from 'hooks';
import { priceResolve, sizeImageResolve } from 'utils';
import Loader from 'components/presenters/Loader/Loader';
import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import routes from 'routesPaths';
import { useHandlePurchase } from './hooks/useHandlePurchase';
import { COMPONENT_NAME } from './constants';
import PackageDetailsProvider from './components/PackageDetailsProvider/PackageDetailsProvider';
import { findSubscriptionStore } from '../utils';
import PackageDetailsResolveRendering from './components/PackageDetailsResolveRendering/PackageDetailsResolveRendering';

import styles from './PackageDetails.module.scss';

const defaultPropsError = {
  errorMessage: '',
  errorCode: '',
};

function PackageDetails() {
  const { settingsStore } = useSettingsStore();
  const { appStore } = useAppStore();
  const rootStore = useRootStore();
  const transactionStore = useTransactionStore();
  const parentalPinStore = useParentalPinStore();
  const { packageId } = useParams<{ packageId: string }>();
  const [error, setError] = useState(defaultPropsError);
  const [loading, setLoading] = useState(false);
  const [purchaseState, setPurchaseState] = useState('');
  const [purchaseAction, setPurchaseAction] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const details = transactionStore.getSubscriptionByPackageId(+packageId!);
  const subscriptionStore = findSubscriptionStore(settingsStore.children)!;
  const { onPurchaseSubscription, onCancelSubscription } = useHandlePurchase({
    setLoading,
    setPurchaseAction,
    setPurchaseState,
    setError,
    details,
  });

  useEffect(() => {
    (async () => {
      if (!transactionStore.subscriptionsReady) {
        await transactionStore.initSubscriptions();
      }
    })();
  }, [transactionStore]);

  useEffect(() => {
    const { uiDesignElements } = rootStore!;
    if (uiDesignElements) {
      document.body.style.backgroundColor = uiDesignElements.backgroundColor;
    }
    appStore.saveApplicationRoute(location.pathname);
  }, []); // eslint-disable-line

  if (!transactionStore.subscriptionsReady) {
    return (
      <div className={styles.loaderWrapper}>
        <LoaderWrapperForEvents hashKey={COMPONENT_NAME} component={<Loader />} />
      </div>
    );
  }

  const handleButtonBack = () => {
    if (purchaseState === PurchaseStates.error) {
      setPurchaseState('');
      return;
    }

    if (location.key === 'default') {
      navigate(`${routes.settings.path}${subscriptionStore.link}`);
    } else {
      navigate(-1);
    }
  };

  if (!details) {
    navigate(-1);
    return null;
  }

  const { purchaseDate, isPurchasePending = false, purchaseStatus = '', price } = details;
  const { title = '', images, description = '', disclaimer, priceDescription = '' } = details.packageData;
  const priceResolved = priceResolve(price, priceDescription);
  const imageResolve = sizeImageResolve({ images, size: 'small', imageParam: '/height/691/width/510' });

  const isSubscription = details !== undefined;
  const isPurchasePendingState = isPurchasePending || purchaseState === PurchaseStates.pending;
  const isChangeState =
    purchaseState === PurchaseStates.ok || purchaseState === PurchaseStates.error || isPurchasePendingState;
  const isRestricted = parentalPinStore.isBlockByAdult(details);

  return (
    <PackageDetailsProvider
      imageResolve={imageResolve}
      handleButtonBack={handleButtonBack}
      title={title}
      isChangeState={isChangeState}
      isPurchasePending={isPurchasePendingState}
      loading={loading}
      error={error}
      priceResolved={priceResolved}
      purchaseState={purchaseState}
      purchaseAction={purchaseAction}
      description={description}
      disclaimer={disclaimer}
      onBuy={onPurchaseSubscription}
      purchaseDate={purchaseDate}
      isRestricted={isRestricted}
      isSubscription={isSubscription}
      onCancelSubscription={onCancelSubscription}
      purchaseStatus={purchaseStatus}
      subscription={details}
    >
      <PackageDetailsResolveRendering />
    </PackageDetailsProvider>
  );
}

export default observer(PackageDetails);
