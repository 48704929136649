import React from 'react';
import { observer } from 'mobx-react';
import Routes from 'Routes';
import * as Sentry from '@sentry/react';

import { featureEnabler, DebugMenuStore } from '@kaltura-ott/tvpil-shared';

import { useAppStore } from 'hooks';
import { FEATURES } from 'consts';

import AppHooksCombiner from 'AppHooksCombiner';
import MainLoader from 'components/presenters/MainLoader/MainLoader';
import DebugMenu from 'components/presenters/DevMenu/DebugMenu';
import Gdpr from 'components/widgets/Gdpr/Gdpr';
import Banner from 'components/widgets/Banner/Banner';
import MenuResolver from 'components/widgets/Menu/MenuResolver';
import DownloadPageChecker from 'components/pages/DownloadPageChecker/DownloadPageChecker';

import 'normalize.css';

function App() {
  const {
    appStore: { cookieStore, dms, isReady, cookiePopupDetails },
  } = useAppStore();
  const isGdpr =
    featureEnabler.variation(FEATURES.GDPR, false) &&
    !cookieStore?.cookieAccepted &&
    !cookiePopupDetails.loadExternalCookiePopup;
  const isBanner = !!dms?.exponea?.enable;

  return (
    // TODO: change error fallback to ErrorScreen UI
    <Sentry.ErrorBoundary fallback={({ error }) => <div>{JSON.stringify(error.stack)}</div>}>
      <AppHooksCombiner />
      {isReady ? (
        <>
          <DownloadPageChecker>
            <>
              <MenuResolver />
              <Routes />
              {isBanner && <Banner />}
              {isGdpr && <Gdpr />}
            </>
          </DownloadPageChecker>
          {DebugMenuStore.isMutableRootCategoryId && <DebugMenu />}
        </>
      ) : (
        <MainLoader />
      )}
    </Sentry.ErrorBoundary>
  );
}

export default observer(App);
