import { useRentals, useRentalsSettings } from '@kaltura-ott/tvpil-shared';
import { RentalAsset } from '@kaltura-ott/tvpil-shared/lib/rhino/data/rental/types';

import { RAIL_TYPE } from 'components/widgets/RailV2/constants';
import { RailProviderData, RailSettings } from 'components/widgets/RailV2/components/RailProviderV2/types';
import { useSetScrollPosition } from 'components/widgets/RailV2/hooks/useSetScrollPosition/useSetScrollPosition';
import { useRailEmptyCheck } from 'components/widgets/RailV2/hooks/useRailEmptyCheck/useRailEmptyCheck';

/**
 * `useRentalsRail` is a custom React hook used for retrieving and managing data necessary
 * for displaying a rentals rail in a web application. This hook combines rentals settings,
 * channel data, and rail configuration.
 *
 * @param referenceId - A unique identifier of the rentals rail widget.
 * @param pageReferenceId - A unique identifier of the page containing the rentals rail widget.
 *
 * @returns An object containing:
 * - `settings`: Configurations and settings for the retals component's UI, obtained from `useRentalsSettings`.
 * - `railData`: An object structured as `RailProviderData<RentalAsset>`, containing the RentalAsset,
 *               total count, and loading state.
 * - `railType`: Set to `RAIL_TYPE.RENTAL`
 *               in this context, marking it as a rental-type rail component.
 */

function useRentalsRail(referenceId: string) {
  const settings = useRentalsSettings({ referenceId, imageSize: 'compact' });
  const data = useRentals({
    referenceId,
    pageSize: 50, // 50 should be changed to RAIL_PAGE_SIZE (20) when graphql bug fixed
  });
  const railType = RAIL_TYPE.RENTAL;

  const isRailEmpty = useRailEmptyCheck(data);

  useSetScrollPosition(!!data.items?.length);

  const railData: RailProviderData<RentalAsset> = {
    items: data.items,
    totalCount: data.totalCount,
    hasNextPage: data.hasNextPage,
    isLoading: data.isLoading,
    error: data.error,
    fetchNextPage: data.fetchNextPage,
    refetch: data.refetch,
  };

  const railSettings: RailSettings = {
    branding: settings?.branding,
    id: settings?.id,
    imageHeight: settings?.imageHeight,
    imageType: settings?.imageType,
    imageWidth: settings?.imageWidth,
    title: settings?.title,
    shouldShowRating: settings?.shouldShowRating,
  };

  return {
    railData,
    railType,
    isRailEmpty,
    railSettings: settings ? railSettings : null,
  };
}

export { useRentalsRail };
