import { useEffect } from 'react';

import { EpgParamsType } from 'types';
import {
  onHandleKeyDown,
  onHandleMouseWheel,
  onHandleResize,
  onHandleSetUserActive,
  onHandleWheel,
} from '../../handlers';
import { config } from '../../config';
import { throttle } from '../../utils';

interface Props {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  userActive: React.MutableRefObject<boolean>;
  refTime: React.MutableRefObject<HTMLDivElement | null>;
  epgParams: EpgParamsType;
}

export const useEventsListener = ({ ref, refTime, userActive, epgParams }: Props) => {
  const setUserActive = () => onHandleSetUserActive(userActive);
  const onHandleWheelWrapper = (e: WheelEvent) => {
    throttle(onHandleWheel(e, refTime, epgParams), config.delayForHorisontalScroll);
    setUserActive();
  };
  const onHandleKeyDownWrapper = (e: KeyboardEvent) => {
    onHandleKeyDown(e, ref, epgParams);
    setUserActive();
  };
  const onHandleResizeWrapper = () => onHandleResize();
  const onHandleMouseWheelWrapper = (event: WheelEvent) => {
    onHandleMouseWheel(event);
    setUserActive();
  };
  const onHandleScrollWrapper = () => {
    setUserActive();
  };

  useEffect(() => {
    window.addEventListener('wheel', onHandleWheelWrapper);
    window.addEventListener('scroll', onHandleScrollWrapper);
    window.addEventListener('resize', onHandleResizeWrapper);
    window.addEventListener('keydown', onHandleKeyDownWrapper);
    if (ref.current) {
      ref.current.addEventListener('mousewheel', onHandleMouseWheelWrapper as any);
    }

    return () => {
      window.removeEventListener('wheel', onHandleWheelWrapper);
      window.removeEventListener('scroll', onHandleScrollWrapper);
      window.removeEventListener('resize', onHandleResizeWrapper);
      window.removeEventListener('keydown', onHandleKeyDownWrapper);
      if (ref.current) {
        ref.current.removeEventListener('mousewheel', onHandleMouseWheelWrapper as any);
      }
    };
  }, []); // eslint-disable-line

  return { setUserActive };
};
