import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDynamicSvgImport } from 'hooks/common/useDynamicSvgImport';
import { onChange } from './handlers/onChange/onChange';
import { PIN_LIMIT_ERROR } from '../../constants';

import styles from './PinCode.module.scss';

interface Props {
  pinCode: string;
  error: {
    errorMessage: string;
    errorCode: string;
  };
  loading: boolean;
  pincodeLength: number;
  inputWrapper?: React.Ref<HTMLDivElement>;
  pinTimeout: number;
  setError: React.Dispatch<
    React.SetStateAction<{
      errorMessage: string;
      errorCode: string;
    }>
  >;
  setPinCode: React.Dispatch<React.SetStateAction<string>>;
}

// TODO: maybe refactor onChange functions
function PinCode({
  error: { errorMessage, errorCode },
  loading,
  pinCode,
  inputWrapper,
  pincodeLength,
  pinTimeout,
  setError,
  setPinCode,
}: Props) {
  const intl = useIntl();
  const { SvgIcon: ErrorSvgIcon } = useDynamicSvgImport('icon-error');
  const isPinLimitError = errorCode === PIN_LIMIT_ERROR;

  function handleKeyDown(event: any) {
    const { target } = event;
    // key backspace
    if (!target.value && event.keyCode === 8 && target.previousSibling) {
      target.previousSibling.focus();
      target.previousSibling.value = '';
      event.preventDefault();
    }
    // key tab
    if (event.keyCode === 9) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  function setPassword(event: any) {
    const { target } = event;
    const result: any = [];

    Array.from(target.parentElement.children).forEach(({ value }: any) => {
      if (!value) return;
      result.push(value);
    });

    onChange(result.toString().replace(/[^\d]+/g, ''), pinCode, setError, setPinCode);
  }

  function handleInputChange(event: any) {
    const { target } = event;
    target.value = target.value.replace(/[^\d]+/g, '');
    const { form } = target;
    const index = Array.prototype.indexOf.call(form, target);
    setPassword(event);
    if (target.value && index !== 3) {
      target.nextSibling.focus();
    }
  }

  function handleClick(event: any) {
    const { target } = event;
    const { form } = target;
    const index = Array.prototype.indexOf.call(form, target);
    // resolve click on input
    if (!target.value && index !== 0) {
      let indexItem = 0;
      Array.from(target.parentElement.children).forEach(({ value }: any, i: number) => {
        if (value) {
          indexItem = i + 1;
        }
      });

      form[indexItem].focus();
    }
  }

  return (
    <>
      {!isPinLimitError && (
        <div ref={inputWrapper} className={styles.inputs}>
          {[...Array(pincodeLength)].map((prop, index) => (
            <input
              key={index!.toString()}
              autoComplete='new-password'
              className={styles.input}
              disabled={loading}
              maxLength={1}
              onChange={handleInputChange}
              onClick={handleClick}
              onKeyDown={handleKeyDown}
              type='text'
              value={pinCode.charAt(index)}
            />
          ))}
        </div>
      )}
      {errorCode && (
        <div className={styles.errorBlock}>
          {ErrorSvgIcon && <ErrorSvgIcon className={styles.errorIcon} />}
          <FormattedMessage
            defaultMessage={errorMessage}
            id={
              isPinLimitError
                ? `PinCodeModal.tvpilValidateError.pinlimit.${intl.formatPlural(+pinTimeout)}`
                : `PinCodeModal.tvpilValidateError.${errorCode}`
            }
            values={{ pinTimeout }}
          />
        </div>
      )}
    </>
  );
}

export default observer(PinCode);
