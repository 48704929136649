import { useCallback } from 'react';

import { appStore, setCookie } from '@kaltura-ott/tvpil-shared';

import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { useErrorScreenContext } from 'context/error/error';
import { o2Domains } from 'components/widgets/Login/constants';
import { ENV_ENDPOINTS } from 'config';
import { Form, useLoginStore } from 'hooks';
import { ONE_YEAR } from 'consts';
import { PerformanceEvents } from 'interface';

interface Props {
  form: Form;
  isOttLogin: boolean;
  legacyAppPath: string;
  setLoginSuccessEndTime: () => Promise<void>;
}

export const useOnCallSignIn = ({ form, isOttLogin, legacyAppPath, setLoginSuccessEndTime }: Props) => {
  const loginStore = useLoginStore();
  const errorScreenContext = useErrorScreenContext();
  const performanceEventsContext = usePerformanceEventsContext();

  return useCallback(async () => {
    performanceEventsContext.setStartTime(PerformanceEvents.loginSuccesses);
    // appStart time would be updated if service list appears
    performanceEventsContext.setStartTime(PerformanceEvents.appStart);

    const selectedCookieDomain =
      appStore?.params?.dmsSetting?.endpoint === ENV_ENDPOINTS.STAGING ? o2Domains.staging : o2Domains.production;

    setCookie('migo2tv', 1, {
      domain: legacyAppPath,
      'max-age': ONE_YEAR,
    });

    if (isOttLogin) {
      await loginStore.userLogin({ form: { username: form.email.value, password: form.password.value } });
      performanceEventsContext.setEndTime({ value: PerformanceEvents.loginSuccesses });
      errorScreenContext.clearError();
    } else {
      const signInResult = await loginStore.userLogin({
        form: { username: form.email.value, password: form.password.value },
      });

      await setLoginSuccessEndTime();
      // @ts-ignore
      setCookie('jwt', signInResult.token.jwt, {
        domain: selectedCookieDomain,
        'max-age': ONE_YEAR,
      });
      // @ts-ignore
      setCookie('jwtRefreshToken', signInResult.token.refreshToken, {
        domain: selectedCookieDomain,
        'max-age': ONE_YEAR,
      });
    }
  }, [form]);
};
