import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';

import styles from '../../PinCodeModalDetails.module.scss';

interface Props {
  assetName: string;
  price: string | React.ReactNode;
  duration: number;
}

function PinCodeDetailsInner({ assetName, price, duration }: Props) {
  const intl = useIntl();
  return (
    <>
      <p className={styles.textUpperCase}>
        <FormattedMessage
          defaultMessage='You are purchasing:'
          id={`${COMPONENT_NAME}.VideoContent.PinCodeModalDetails.description`}
        />
      </p>
      <p>
        <b>{assetName}</b>
      </p>
      <p>
        <b>{price}</b>
        {' / '}
        <FormattedMessage
          defaultMessage='{duration, plural, one {Rent for # hour} other {Rent for # hours}}'
          id={`${COMPONENT_NAME}.VideoContent.PinCodeModalDetails.duration.${intl.formatPlural(duration)}`}
          values={{ duration }}
        />
      </p>
      <p className={styles.textUpperCase}>
        <FormattedMessage
          defaultMessage='Enter pin code to confirm purchase:'
          id={`${COMPONENT_NAME}.VideoContent.PinCodeModalDetails.comfirmText`}
        />
      </p>
    </>
  );
}

export default PinCodeDetailsInner;
