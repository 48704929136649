import React, { useEffect, useState } from 'react';

import { QuotaStatusEnum } from '@kaltura-ott/tvpil';

import { Transition } from 'components/hoc';
import { CUSTOM_APP_EVENTS, SINGLE_RECORD } from 'consts';
import { useRecordRepository, useAppStore, useGoBack } from 'hooks';
import { playerStorage } from 'components/widgets/Player/storages';
import { getRecordIcon } from 'utils';

import backIcon from 'assets/images/player/back.svg';
import infoIcon from 'assets/images/player/info.svg';
import pauseIcon from 'assets/images/player/pause.svg';
import rewindIcon from 'assets/images/player/rewind.svg';
import forwardIcon from 'assets/images/player/forward.svg';
import settingsIcon from 'assets/images/player/settings.svg';
import volumeIcon from 'assets/images/player/volume-bar.svg';
import startOverIcon from 'assets/images/player/start-over.svg';
import fullscreenIcon from 'assets/images/player/full-screen.svg';

import styles from './VideoPage.module.scss';

export const TIMEOUT = 500;
const TIME = '0:00';

interface Props {
  vod?: boolean;
}

function FullPlayerLoadingScreen({ vod = false }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();
  const goBack = useGoBack();

  const recordRepository = useRecordRepository();

  const isNpvrEnabled = recordRepository.space.status !== QuotaStatusEnum.NpvrServiceNotAllowed;
  const isVisibleRecordBtn = !vod && isNpvrEnabled;
  const recordIcon = `${staticAssetsUrl}${getRecordIcon(SINGLE_RECORD)}`;

  function onload(e: CustomEvent) {
    const { detail } = e;

    setIsLoading(detail.value);
  }

  useEffect(() => {
    document.addEventListener(CUSTOM_APP_EVENTS.IS_SKELETON_VIEW, onload as EventListener);

    return () => {
      document.removeEventListener(CUSTOM_APP_EVENTS.IS_SKELETON_VIEW, onload as EventListener);
    };
  }, []);

  const onMouseOver = () => {
    playerStorage.setIsMouseOverPlayerBottomBar(true);
  };

  const onMouseLeave = () => {
    playerStorage.setIsMouseOverPlayerBottomBar(false);
  };

  return (
    <Transition
      className={styles.playerScreenWrapper}
      isLoading={isLoading}
      timeout={TIMEOUT}
      transitionClassNames={{ exit: styles.exit, exitActive: styles.exitActive }}
      unmountOnTransitionExit
    >
      <>
        <button className={styles.backButton} onClick={goBack} type='button'>
          <img alt='Back' src={backIcon} />
        </button>
        <div className={styles.controls} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onFocus={onMouseOver}>
          <div className={styles.seekbar}>
            <div className={styles.currentTime}>{TIME}</div>
            <div className={styles.seekLine} />
            <div className={styles.duration}>{TIME}</div>
          </div>
          <div className={styles.controlsPreset}>
            <img className={styles.pause} alt='Pause' src={pauseIcon} />
            <div>
              <img className={styles.rw} alt='RW' src={rewindIcon} />
              <img className={styles.ff} alt='FF' src={forwardIcon} />
            </div>
            <img className={styles.info} alt='Info' src={infoIcon} />
            <img className={styles.startOver} alt='startOver' src={startOverIcon} />
            {isVisibleRecordBtn && <img className={styles.record} alt='record' src={recordIcon} />}
            <img className={styles.settings} alt='settings' src={settingsIcon} />
            <img className={styles.volume} alt='volume' src={volumeIcon} />
            <img className={styles.fullscreen} alt='fullscreen' src={fullscreenIcon} />
          </div>
        </div>
      </>
    </Transition>
  );
}

export default FullPlayerLoadingScreen;
