import { useSeriesCatchUpRailCollection, useRailSettings } from '@kaltura-ott/tvpil-shared';

import { RAIL_PAGE_SIZE } from 'consts';

function useSeriesCatchUpRailCombiner(referenceId: string) {
  const settings = useRailSettings({ referenceId });
  const data = useSeriesCatchUpRailCollection(referenceId, RAIL_PAGE_SIZE);

  return { data, railSettings: settings.settings };
}

export { useSeriesCatchUpRailCombiner };
