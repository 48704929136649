import { secondsToMilliseconds, intlFormat, setMinutes } from 'date-fns';
import { TIMESTAMP_THRESHOLD } from 'consts';

export interface TimeFormatOptions {
  hours?: {
    withZero: boolean;
  };
  minutes?: {
    withZero: boolean;
  };
  seconds?: {
    withZero: boolean;
  };
}

export function formatTime(
  date: Date | number,
  options: TimeFormatOptions = { minutes: { withZero: true }, hours: { withZero: true } },
): string {
  if (typeof date === 'number') {
    // eslint-disable-next-line
    date = new Date(date);
  }
  let result = '';

  if (options?.hours) {
    const hours = date.getHours();
    const hoursString = options.hours.withZero && hours < 10 ? `0${hours}` : hours;
    result += hoursString;
  }

  if (options?.minutes) {
    const minutes = date.getMinutes();
    const minutesString = options.minutes.withZero && minutes < 10 ? `0${minutes}` : minutes;
    result += `:${minutesString}`;
  }

  if (options?.seconds) {
    const seconds = date.getSeconds();
    const secondsString = options.seconds.withZero && seconds < 10 ? `0${seconds}` : seconds;
    result += `:${secondsString}`;
  }

  return result;
}

interface LocalTimeOptions {
  date?: Date | number;
  hours?: number;
  minutes?: number;
  locale?: string;
  withRangeDelimiter?: boolean;
}

interface LocalTimeOptionsV2 {
  time: Date | number;
  minutes: number;
  locale: string;
}

export function formatLocalTime({
  date,
  hours,
  minutes = 0,
  locale = 'en',
  withRangeDelimiter = false,
}: LocalTimeOptions) {
  let formattedDate: Date | number | undefined = date;
  let delimiter = '';

  if (typeof date === 'number') {
    const dateSource = date < TIMESTAMP_THRESHOLD ? secondsToMilliseconds(date) : date;
    formattedDate = new Date(dateSource);
  }

  if (typeof hours === 'number') {
    formattedDate = new Date().setHours(hours, minutes);
  }

  if (!formattedDate) return '';

  const localTime = Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric',
  }).format(formattedDate);

  if (withRangeDelimiter) {
    delimiter = /\s/.test(localTime) ? ' - ' : '-';
  }

  return `${localTime}${delimiter}`;
}

export function formatLocalTimeV2({ locale, time, minutes }: LocalTimeOptionsV2) {
  const tm = setMinutes(time, minutes);
  const formated = intlFormat(
    tm,
    {
      hour: 'numeric',
      minute: 'numeric',
    },
    {
      locale,
    },
  );

  return formated;
}

export function removeLeadingZeroesFromDateString(dateString: string) {
  return dateString
    .replace(/^0+/, '')
    .replace(/ 0+/g, ' ')
    .replace(/\.0+/g, '.')
    .replace(/\/0+/g, '/')
    .replace(/-0+/g, '-');
}

export enum DateFormatStyle {
  WithShortWeekday,
  WithLongWeekday,
  OnlyLongWeekday,
  WithoutWeekday,
  WithFullYear,
}

export function formatDate(date: number, style?: DateFormatStyle, local = 'en'): string {
  const dateSource = date < TIMESTAMP_THRESHOLD ? secondsToMilliseconds(date) : date;
  const dateObject = new Date(dateSource);
  let result = '';

  switch (style) {
    case DateFormatStyle.WithShortWeekday:
      result = `${Intl.DateTimeFormat(local, { weekday: 'short', day: 'numeric', month: 'numeric' }).format(
        dateObject,
      )}`;
      break;
    case DateFormatStyle.WithLongWeekday:
      result = `${Intl.DateTimeFormat(local, { weekday: 'long', day: 'numeric', month: 'numeric' }).format(
        dateObject,
      )}`;
      break;
    case DateFormatStyle.OnlyLongWeekday:
      result = `${Intl.DateTimeFormat(local, { weekday: 'long' }).format(dateObject)}`;
      break;
    case DateFormatStyle.WithoutWeekday:
      result = `${Intl.DateTimeFormat(local, { day: 'numeric', month: 'numeric' }).format(dateObject)}`;
      break;
    case DateFormatStyle.WithFullYear:
      result = `${Intl.DateTimeFormat(local, { day: 'numeric', month: 'numeric', year: 'numeric' }).format(
        dateObject,
      )}`;
      break;
    default:
      // eslint-disable-next-line no-case-declarations
      const d = new Date(dateSource);
      // TODO: add locale support
      result = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear().toString().slice(-2)}`;
      break;
  }

  return removeLeadingZeroesFromDateString(result);
}
