import { getBrowserData } from 'utils/browser';

export const ENV_ENDPOINTS = {
  STAGING: 'https://api.frs1.ott.kaltura.com',
  PRE_PROD: 'https://api.frt1.ott.kaltura.com',
  PRODUCTION: 'https://api.frp1.ott.kaltura.com',
};

export const APP_TITLE: { [key: string]: string } = {
  3200: 'Hipernet TV',
  3201: 'O2 TV 2.0',
  3207: 'Hipernet TV',
  3204: 'Yettel TV',
  3206: 'O2 TV: Digitálna televízia',
  5002: 'Yettel TV',
};

const data = getBrowserData();

export default {
  udid: '',
  debugMenu: import.meta.env.VITE_DEBUG_MENU === 'true',
  dmsSetting: {
    clientTag: import.meta.env.VITE_CLIENT_TAG || '',
    endpoint: import.meta.env.VITE_API_ENDPOINT || '',
    applicationName: import.meta.env.VITE_APP_NAME || '',
    clientVersion: import.meta.env.VITE_CLIENT_VERSION || '',
    platform: (import.meta.env.VITE_API_PLATFORM || '').toLowerCase(),
    tag: '',
    partnerId: parseFloat(import.meta.env.VITE_PARTNER_ID || ''),
  },

  deviceAttributes: {
    name: '',
    brandId: parseFloat(import.meta.env.VITE_BRAND_ID || ''),
    firmware: 'n/a',
    osVersion: navigator.appVersion,
    osName: undefined,
    deviceManufacturer: data?.name,
    deviceModel: data?.version,
  },
  designParams: {
    epgScreenLength: 10,
    defaultSizeIndex: 1,
    imageScale: 2,
  },
  poEditor: {
    exportProjectPoeditorAPI: import.meta.env.VITE_PO_EDITOR_API || '',
    poEditorToken: import.meta.env.VITE_PO_EDITOR_TOKEN || '',
    projectId: import.meta.env.VITE_PO_EDITOR_PROJECT_ID || '',
    proxy: import.meta.env.VITE_PROXY || '',
  },
};
