import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import cn from 'classnames';

import {
  KuxMenuItem,
  UIDAPresentationTypeEnum,
  UIDAStructureTypeEnum,
  TypeOfApplicationLink,
} from '@kaltura-ott/tvpil';
import { FooterStore, PageStore } from '@kaltura-ott/tvpil-shared';

import routesPaths from 'routesPaths';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { IMAGE_PARAM } from 'consts';
import { useLoginPageStore, useRootStore, useAppStore } from 'hooks';
import { Node } from 'types';
import { defaultStore } from 'interface';
import Logo from '../Menu/components/Logo/Logo';
import { applyStyle, applyStyles } from '../../../utils/uiDesignElements';

import styles from './Footer.module.scss';

interface Props {
  className?: string;
  pageId?: string;
}

// maybe use router location instead of className
const FooterComponent: React.FC<Props> = ({ className, pageId = '' }) => {
  const {
    appStore: {
      params: {
        dmsSetting: { partnerId },
      },
    },
  } = useAppStore();
  const rootStore = useRootStore();

  const loginPageStore = useLoginPageStore();
  const { children } = rootStore!;
  const { location } = useStableReactRouter();
  const isLoginPage = location.pathname?.includes(routesPaths.login.path);

  let store = rootStore?.children?.find(
    ({ type, subtype }: defaultStore) =>
      type === UIDAStructureTypeEnum.menu && subtype === UIDAPresentationTypeEnum.footer,
  ) as FooterStore;
  if (pageId) {
    const pageStore = children?.find(
      ({ type, referenceId }: defaultStore) => type === UIDAStructureTypeEnum.page && pageId === referenceId,
    ) as PageStore;
    const pageMenu = pageStore?.children?.find(
      ({ type, subtype }: defaultStore) =>
        type === UIDAStructureTypeEnum.menu && subtype === UIDAPresentationTypeEnum.footer,
    ) as FooterStore;
    if (pageMenu) {
      store = pageMenu;
    }
  }

  const imageParam = (index: number) =>
    partnerId === 3201 && index === 0 ? '/height/24/width/57' : '/height/34/width/34';

  const handleRef = useCallback((node: Node) => {
    const footerStore = rootStore?.children?.find(
      ({ type, subtype }: defaultStore) =>
        type === UIDAStructureTypeEnum.menu && subtype === UIDAPresentationTypeEnum.footer,
    ) as FooterStore;
    const uiDesignElements = footerStore?.uiDesignElements;
    const { image } = loginPageStore!;

    if (uiDesignElements && node) {
      applyStyles(node, uiDesignElements);
    }

    if (node && isLoginPage && image) {
      applyStyle(node, '--background', 'linear-gradient(180deg, rgba(36, 37, 64, 0) 0%, rgba(36, 37, 64, 0.5) 100%)');
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!store?.isReady && store) {
      store.init();
    }
  }, [store]);

  if (!store) {
    return (
      <div className={styles.message}>
        <FormattedMessage id='Footer.messageWrong' />
      </div>
    );
  }

  if (!store.isReady) {
    return null;
  }

  const logo = store.items.find(
    (item: any) =>
      item?.typeOfAppLink === UIDAStructureTypeEnum.page && item?.image && item?.name.toLocaleLowerCase() === 'home',
  );

  return (
    <footer ref={handleRef} className={cn(styles.footer, className)}>
      <div className={styles.follow}>
        {logo && <Logo className={styles.logo} image={`${logo.image}${IMAGE_PARAM}`} />}
        <ul className={cn({ [styles.o2]: partnerId === 3201 })}>
          {store.items
            .filter(
              (item: any) =>
                ![TypeOfApplicationLink.external, TypeOfApplicationLink.page].includes(
                  item?.typeOfAppLink as TypeOfApplicationLink, // TODO: add this type to tvpil
                ) && !!item?.inAppLink,
            )
            .map((item: KuxMenuItem, index) => (
              <li key={`default-${item.id}`}>
                <a
                  href={item.inAppLink}
                  rel='noopener noreferrer'
                  style={{
                    backgroundImage: `url('${item.image}${imageParam(index)}')`,
                  }}
                  target='_blank'
                >
                  {item.name}
                </a>
              </li>
            ))}
        </ul>
      </div>

      <div className={styles.externalMenu}>
        {store.items
          .filter((item: any) => item?.typeOfAppLink === TypeOfApplicationLink.external)
          .map((item: KuxMenuItem) => (
            <a key={`external-${item.id}`} href={item.inAppLink} rel='noopener noreferrer' target='_blank'>
              {item?.name}
            </a>
          ))}
      </div>

      <p className={styles.copy}>
        <FormattedMessage id='Footer.companyInfo' />
      </p>
    </footer>
  );
};

export const Footer = observer(FooterComponent);
