import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useParentalControlContext } from 'context/parentalControlContext/parentalControlContext';
import { useParentalPinStore } from 'hooks';
import { FULL_COMPONENT_NAME } from '../../constans';

import styles from '../../ParentalControl.module.scss';

const ParentalInfo = () => {
  const { noThresholdAge } = useParentalPinStore();
  const { ageThreshold } = useParentalControlContext();
  const isLockContentMessage = ageThreshold && +ageThreshold !== +noThresholdAge;

  return (
    <p className={styles.info}>
      {isLockContentMessage ? (
        <FormattedMessage
          defaultMessage='Lock content that is not suitable for age <strong>{age}+ and below</strong>'
          id={`${FULL_COMPONENT_NAME}.textAge`}
          values={{ age: ageThreshold, strong: (text: React.ReactNode) => <b>{text}</b> }}
        />
      ) : (
        <FormattedMessage
          defaultMessage='No Viewing restriction, all content can be watched'
          id={`${FULL_COMPONENT_NAME}.textNoProtection`}
        />
      )}
    </p>
  );
};

export default React.memo(ParentalInfo);
