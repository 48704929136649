import { KalturaPlayerProps } from '../../types';
import { playerServiceManagement } from '../../services/PlayerServiceManagement';
import { playerStorage } from '../../storages';

export function handleCheckMute(prevProps: KalturaPlayerProps) {
  const { muted } = playerStorage.getPlayer();
  const playerService = playerServiceManagement.getPlayerService();
  const { muted: prevMuted } = prevProps;

  if (muted !== prevMuted) {
    playerService.setMuted(muted);
  }
}
