import React, { createContext, useState, useCallback, useMemo, useContext } from 'react';
import { millisecondsInSecond } from 'date-fns';

import { appStore, featureEnabler } from '@kaltura-ott/tvpil-shared';
import { PerformanceEventArgs } from '@kaltura-ott/tvpil-analytics/lib/interfaces/performance-event';

import { FEATURES } from 'consts';
import { PerformanceContextType, PerformanceEndTimeProps, PerformanceEvents } from 'interface';

interface Props {
  children: React.ReactNode;
}

const PerformanceEventsContext = createContext<PerformanceContextType>({
  resetEventsStartTime: () => {},
  clearEvent: () => {},
  getEvent: () => undefined,
  setStartTime: () => {},
  setEndTime: () => {},
});

interface EventsMap {
  [key: string]: {
    startTime?: number;
    interimTime?: number;
  };
}

const PerformanceEventsProvider = ({ children }: Props) => {
  const [eventsMap, setEventsMap] = useState<EventsMap>({});

  function checkIsEnabled(eventName: PerformanceEvents, withDmsToggle: boolean) {
    const isAnalyticsEnabled = featureEnabler.variation(FEATURES.ANALYTICS, false);

    if (!withDmsToggle) {
      return isAnalyticsEnabled;
    }

    const dmsFlag = Object.entries(PerformanceEvents).find(([, value]) => value === eventName)![0];
    const isEventEnabled = (appStore?.dms?.analytics?.events as any)?.[dmsFlag];
    return isAnalyticsEnabled && isEventEnabled;
  }

  function removeEventFromMap(event: PerformanceEvents | string, map: EventsMap) {
    const clearedMap = { ...map };
    delete clearedMap[event];

    return { ...clearedMap };
  }

  const resetEventsStartTime = useCallback(() => {
    setEventsMap((events: EventsMap) => {
      const map = { ...events };
      const eventsKeys = Object.keys(events);
      eventsKeys.forEach((key) => {
        map[key].startTime = Date.now();
      });
      return { ...map };
    });
  }, []); // eslint-disable-line

  const clearEvent = useCallback((eventName: PerformanceEvents, hashKey: string = '') => {
    const name = hashKey ? `${eventName}-${hashKey}` : eventName;
    setEventsMap((events: EventsMap) => {
      return removeEventFromMap(name, events);
    });
  }, []); // eslint-disable-line

  const getEvent = useCallback(async (eventName: PerformanceEvents, hashKey: string = '') => {
    let event;
    const name = hashKey ? `${eventName}-${hashKey}` : eventName;

    await new Promise((resolve) =>
      setEventsMap((events: EventsMap) => {
        event = events[name];
        resolve(true);
        return { ...events };
      }),
    );
    return event;
  }, []); // eslint-disable-line

  const setStartTime = useCallback((eventName: PerformanceEvents, hashKey: string = '', interimTime?: number) => {
    const startTime = interimTime ? Date.now() + interimTime : Date.now(); // increase the startTime
    const name = hashKey ? `${eventName}-${hashKey}` : eventName;

    setEventsMap((events: EventsMap) => ({ ...events, [name]: { startTime } }));
  }, []); // eslint-disable-line

  const setEndTime = useCallback(
    ({
      value,
      withDmsToggle = true,
      hashKey = '',
      param1,
      param2,
      isSendingAllowed = true,
      additionalEventParam,
    }: PerformanceEndTimeProps) => {
      const endTime = Date.now();
      const name = hashKey ? `${value}-${hashKey}` : value;
      const isLoginSuccessEvent = name === PerformanceEvents.loginSuccesses;

      if (!checkIsEnabled(value, withDmsToggle)) {
        // need to clear start time if event disabled because we can call setStartTime before dms is defined(login successes)
        return removeEventFromMap(name, eventsMap);
      }

      return setEventsMap((events: EventsMap) => {
        const startTime = events[name]?.startTime;
        if (startTime) {
          if (isSendingAllowed) {
            appStore?.kuxAnalytics?.send(
              new PerformanceEventArgs({
                metric: value,
                value: (endTime - startTime) / millisecondsInSecond,
                param1,
                param2,
                param3: additionalEventParam,
              }),
            );
          } else if (isLoginSuccessEvent) {
            // save first part of SSO login time
            return {
              ...removeEventFromMap(name, events),
              [PerformanceEvents.interimTimeForloginSuccess]: { interimTime: endTime - startTime },
            };
          }
          return removeEventFromMap(name, events);
        }
        return { ...events };
      });
    }, // eslint-disable-next-line
    [],
  );

  const contextValue = useMemo(() => {
    return { clearEvent, getEvent, setStartTime, setEndTime, resetEventsStartTime };
  }, [clearEvent, getEvent, setStartTime, setEndTime, resetEventsStartTime]);

  return (
    <PerformanceEventsContext.Provider value={contextValue}>
      <>{children}</>
    </PerformanceEventsContext.Provider>
  );
};

const usePerformanceEventsContext = () => useContext(PerformanceEventsContext);

export { PerformanceEventsContext, PerformanceEventsProvider, usePerformanceEventsContext };
