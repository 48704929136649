import { useNavigate, useLocation } from 'react-router-dom';

import routes from 'routesPaths';

export function useGoBack() {
  const navigate = useNavigate();

  const location = useLocation();

  const { state, key } = location!;
  const goBackUrl = state?.goBackUrl;

  return function () {
    if (goBackUrl) {
      navigate!(goBackUrl, { replace: true });
      return;
    }

    if (key === 'default') {
      navigate!(routes.home.path, { replace: true });
    } else {
      navigate!(-1);
    }
  };
}
