import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import { ApplicationError } from 'interface';
import { WRONG_PIN_ERR_CODE } from 'components/widgets/Modal/constants';
import { sendApplicationErrorEvent } from 'services/analytics/applicationErrorEvent';

export const appErrorOnValidatePin = (result: any, context: UIDAPresentationTypeEnum | undefined) => {
  if (result.code !== WRONG_PIN_ERR_CODE) {
    sendApplicationErrorEvent({
      context: context as UIDAPresentationTypeEnum,
      message: result.message,
      code: result.code?.toString(),
      additionalInfo: ApplicationError.Pin,
    });
  }
};
