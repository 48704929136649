import { PlaybackEventArgs, PlaybackAction } from '@kaltura-ott/tvpil-analytics';
import { appStore } from '@kaltura-ott/tvpil-shared';

import { DataAnalyticsType, PlaybackBaseEvent } from './PlaybackBaseEvent';

export class PlaybackActionEvent extends PlaybackBaseEvent {
  readonly playbackType: PlaybackAction;

  constructor(data: DataAnalyticsType, playbackType: PlaybackAction) {
    super(data);
    this.playbackType = playbackType;
  }

  pauseCheckerAtFinishOfPlayback(playbackEventData: PlaybackEventArgs) {
    const { normalizedCurrentTime: currentTime, normalizedDuration: duration } = this.player;

    if (currentTime >= duration && playbackEventData.playbackAction === PlaybackAction.pause) {
      return true;
    }
    return false;
  }

  async sendEvent() {
    this.getPlaybackBaseEventData();

    const playbackEventData = {
      playbackAction: this.playbackType,
      ...this.playbackBaseEventData,
    } as PlaybackEventArgs;

    if (this.pauseCheckerAtFinishOfPlayback(playbackEventData)) {
      return;
    }

    if (playbackEventData.epgId) {
      appStore.kuxAnalytics.send(new PlaybackEventArgs(playbackEventData));
    }
  }
}
