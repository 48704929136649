import { useEffect } from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import { saveAssetToGlobalStorage } from 'utils';

export const useSetAssetToMaxiPlayer = (store: any) => {
  useEffect(() => {
    async function setupHero() {
      // reinit hero rail to get correct item for maxi player
      await store.init();

      const firstItem = store.items[0];
      if (firstItem) {
        saveAssetToGlobalStorage(firstItem);
      }
    }

    if (store.subtype === UIDAPresentationTypeEnum.hero) {
      setupHero();
    }
  }, [store.isReady]);
};
