import { action, observable } from 'mobx';
import { subMinutes, addMinutes } from 'date-fns';

import { formatDayISO } from '@kaltura-ott/tvpil-shared';

import { HALF_DIVIDER } from 'consts';
import { convertWidthToMinutesWidth } from '../utils';

class EpgLocalTimeStampStorage {
  @observable currentTimestamp = 0;

  @observable screenWidthInMinutes = 0;

  @observable viewTimestamp = 0;

  @observable viewTimestampState = 0;

  @observable scrollLeft = 0;

  @action
  init() {
    this.currentTimestamp = Date.now();
    this.screenWidthInMinutes = convertWidthToMinutesWidth(document.body.clientWidth);
    this.viewTimestamp =
      this.viewTimestampState || subMinutes(new Date(), this.screenWidthInMinutes / HALF_DIVIDER).valueOf();
  }

  @action
  getStartDate() {
    return this.viewTimestamp;
  }

  @action
  getEndDate() {
    return addMinutes(this.viewTimestamp, this.screenWidthInMinutes).valueOf();
  }

  @action
  setCurrentTimestamp(value: number) {
    this.currentTimestamp = value;
  }

  @action
  setViewTimestamp(value: number) {
    this.viewTimestamp = value;
  }

  @action
  setViewTimestampState(value: number) {
    this.viewTimestampState = value;
  }

  @action
  clearViewTimestampState() {
    this.viewTimestampState = 0;
  }

  @action
  setScreenWidthInMinutes(value: number) {
    this.screenWidthInMinutes = value;
  }

  @action
  setScrollLeft(value: number) {
    this.scrollLeft = value;
  }

  gridVisibleDays(): string[] {
    const days: Array<string> = [];

    const startTimeDay = formatDayISO(new Date(Math.round(this.getStartDate())));
    const endTimeDay = formatDayISO(new Date(Math.round(this.getEndDate())));

    days.push(startTimeDay);
    if (endTimeDay !== startTimeDay) days.push(endTimeDay);

    return days;
  }

  clear() {
    this.currentTimestamp = 0;
    this.screenWidthInMinutes = 0;
    this.viewTimestamp = 0;
    this.scrollLeft = 0;
  }
}

const epgLocalTimeStampStorage = new EpgLocalTimeStampStorage();

export { epgLocalTimeStampStorage };
