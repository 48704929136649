import React from 'react';
import cn from 'classnames';

import { appStore } from '@kaltura-ott/tvpil-shared';

import { RecordIcon, SvgView } from 'components/presenters/icons/index';
import { getMDIconPath } from 'utils';
import { MD_ICON } from 'consts';

import styles from './TitleInner.module.scss';

export function TitleInner({
  title,
  isRecord = false,
  isSeriesRecord = false,
  isMosaicHeroRailProgram = false,
}: {
  title: string;
  isRecord?: boolean;
  isSeriesRecord?: boolean;
  isMosaicHeroRailProgram?: boolean;
}) {
  return (
    <h6
      className={cn(
        styles.title,
        { [styles.titleWithIcon]: isRecord || isMosaicHeroRailProgram },
        { [styles.seriesRecord]: isRecord && isSeriesRecord },
        { [styles.titleWithMosaicIcon]: isMosaicHeroRailProgram },
      )}
    >
      {title}
      {isRecord && (
        <span className={styles.recordIcon}>
          <RecordIcon active multiple={isSeriesRecord} />
        </span>
      )}
      {isMosaicHeroRailProgram && (
        <span className={styles.mosaicIcon}>
          <SvgView url={`${appStore.staticAssetsUrl}${getMDIconPath(MD_ICON)}`} />
        </span>
      )}
    </h6>
  );
}
