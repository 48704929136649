import React from 'react';

import { RailSkeletonAsset } from 'components/widgets/Rail/components/RailSkeleton/RailSkeleton';
import { DEFAULT_WIDTH } from 'consts';
import { useSeriesCatchUpGridSettingsContext } from 'context/seriesCatchUpGridSettingsContext/seriesCatchUpGridSettingsContext';
import SeriesCatchUpGridCardLogic from '../SeriesCatchUpGridCardLogic/SeriesCatchUpGridCardLogic';

const SeriesCatchUpGridCardResolveRendering = ({ data }: { data: any }) => {
  const { gridSettingsData, itemWidth } = useSeriesCatchUpGridSettingsContext();
  const { imageHeight } = gridSettingsData;
  const columnWidth = Math.round(itemWidth || DEFAULT_WIDTH);

  if (!data?.id) {
    return <RailSkeletonAsset height={imageHeight!} width={columnWidth} />;
  }

  return <SeriesCatchUpGridCardLogic data={data} itemWidth={itemWidth} gridSettingsData={gridSettingsData} />;
};

export default SeriesCatchUpGridCardResolveRendering;
