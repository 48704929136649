import React from 'react';

import { Link } from 'react-router-dom';

import styles from './Card.module.scss';

interface Props {
  clickHandler: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseEnter: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave: (e: React.MouseEvent<HTMLElement>) => void;
  link: string;
  imageHeight: number;
  itemWidth: number;
  children: React.ReactNode;
  handleLinkRef: React.MutableRefObject<null>;
  isMosaicProgram: boolean;
}

function Card({
  handleLinkRef,
  clickHandler,
  onMouseEnter,
  onMouseLeave,
  link,
  itemWidth,
  imageHeight,
  children,
  isMosaicProgram,
}: Props) {
  const state = {
    lastScrollYPosition: window.scrollY + imageHeight,
    isShowStreams: isMosaicProgram,
  };

  return (
    <Link
      className={styles.wrapper}
      onClick={clickHandler}
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role='presentation'
      style={{
        minHeight: imageHeight,
        width: itemWidth,
      }}
      ref={handleLinkRef}
      to={link}
      state={state}
    >
      {children}
    </Link>
  );
}

export default Card;
