import React from 'react';
import { Link, To } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

import { TypeOfApplicationLink } from '@kaltura-ott/tvpil';

import chevronIcon from 'assets/images/chevron.svg';
import { useMenuStore, usePageId, useSearchParam } from 'hooks';
import routes from 'routesPaths';
import { FROM_REFERENCE_ID } from 'consts';
import { resolvePathToDetailsPage, findMenuItems } from 'utils';
import { useRailContext } from 'components/widgets/Rail/RailHorisontal/components/RailProvider/RailProvider';

import styles from './MoreButton.module.scss';

const MoreButton: React.FC = () => {
  const { store } = useRailContext();
  const { showMoreItem } = store;
  const menuStore = useMenuStore();
  const pageId = usePageId();
  const referenceId = useSearchParam(FROM_REFERENCE_ID) || pageId;
  const isLinkInMenu = !!findMenuItems(menuStore, showMoreItem?.inAppLink).itemById;

  if (!showMoreItem) return null;

  let url: To = '';

  switch (showMoreItem.typeOfAppLink) {
    case TypeOfApplicationLink.page:
      url = showMoreItem.link!;
      if (referenceId && !isLinkInMenu) {
        url += `?${FROM_REFERENCE_ID}=${referenceId}`;
      }
      break;
    case TypeOfApplicationLink.external:
      url = showMoreItem.link!;
      break;
    case TypeOfApplicationLink.asset:
      url = resolvePathToDetailsPage({ id: showMoreItem.inAppLink! });
      break;
    case TypeOfApplicationLink.grid:
      url = routes.grid.link(showMoreItem.link!);
      if (referenceId && !isLinkInMenu) {
        url += `?${FROM_REFERENCE_ID}=${referenceId}`;
      }
      break;
    default:
      console.error(Error(`${showMoreItem.typeOfAppLink} - typeOfAppLink is not defined`));
  }

  // @ts-ignore
  let Tag: Link | string = Link;
  let conditionalProps = {
    to: url,
  };

  if (showMoreItem?.typeOfAppLink === TypeOfApplicationLink.external) {
    Tag = 'a';
    conditionalProps = {
      // @ts-ignore
      href: url,
    };
  }
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className={styles.moreButton}>
      <Tag {...conditionalProps}>
        <FormattedMessage id='Rail.moreButton' />
        <SVG src={chevronIcon} />
      </Tag>
    </div>
  );
};

export default MoreButton;
