import React from 'react';
import { featureEnabler } from '@kaltura-ott/tvpil-shared';
import VideoPlayout from 'components/widgets/VideoPlayout/VideoPlayout';
import VideoPlayoutV2 from 'components/widgets/VideoPlayoutV2/VideoPlayoutV2';
import { FEATURES } from 'consts';

function MaxiPlayerViewResolver({ pageReferenceId }: { pageReferenceId: string }) {
  const isEnabledHeroRail = featureEnabler.variation(FEATURES.NEW_HERO_RAIL_ENABLED, false);

  return <>{isEnabledHeroRail ? <VideoPlayoutV2 pageReferenceId={pageReferenceId} /> : <VideoPlayout />}</>;
}

export default MaxiPlayerViewResolver;
