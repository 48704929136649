/* eslint-disable no-console */
import { FormEvent } from 'react';

import { PurchaseStates, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { MediaAsset } from '@kaltura-ott/tvpil-shared/lib/stores/entities/MediaAsset';
import {
  CategoryStore,
  ParentalPinType,
  PinValidateStatus,
  RhinoEPGProgram,
  SubpageStore,
  Subscription,
} from '@kaltura-ott/tvpil-shared';

import { useParentalPinStore } from 'hooks/common/useParentalPinStore';
import { useModalContext } from 'context/modal/modal';
import { PinCodeModalTypes } from '../../PinCodeModalTypesEnum';
import { appErrorOnValidatePin } from '../../utils/appErrorOnValidatePin/appErrorOnValidatePin';
import { checkResetInput } from '../../utils/checkResetInput/checkResetInput';

type assetType = MediaAsset | CategoryStore | SubpageStore | Subscription;

interface Props {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  validatePin?: (pin: string) => Promise<any> | Object | undefined;
  pinCode: string;
  type: PinCodeModalTypes;
  asset: assetType | RhinoEPGProgram;
  rhinoParentalRating?: number;
  callback?: (success: boolean) => void;
  setPinCode: React.Dispatch<React.SetStateAction<string>>;
  setError: React.Dispatch<
    React.SetStateAction<{
      errorMessage: string;
      errorCode: string;
    }>
  >;
  pinLimitError:
    | {
        errorMessage: string;
        errorCode: string;
      }
    | undefined;
  context: UIDAPresentationTypeEnum | undefined;
}

export const usePinCodeModalSubmit = ({
  asset,
  type,
  context,
  pinCode,
  pinLimitError,
  rhinoParentalRating,
  setLoading,
  validatePin,
  callback,
  setPinCode,
  setError,
}: Props) => {
  const parentalPinStore = useParentalPinStore();
  const modalContext = useModalContext();

  return async function submit(event?: FormEvent) {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);

    let result;
    if (validatePin) {
      result = await validatePin(pinCode);
    } else if (type === PinCodeModalTypes.RestrictedContentParental || type === PinCodeModalTypes.EnterParental) {
      result = await parentalPinStore.validatePin(pinCode, ParentalPinType.parental, asset as assetType);
    } else if (type === PinCodeModalTypes.ParentalRhino && rhinoParentalRating) {
      result = await parentalPinStore.validateParentalRating({ pin: pinCode, parentalRating: rhinoParentalRating });
    } else {
      result = await parentalPinStore.validatePin(pinCode, ParentalPinType.adult, asset as assetType);
    }
    setLoading(false);

    if (!result && callback) {
      callback(false);
      return;
    }

    const { message: errorMessage, state, code: errorCode } = result;

    if (state === PurchaseStates.error || state === PinValidateStatus.error) {
      setPinCode('');
      setError(pinLimitError || { errorMessage, errorCode });
      appErrorOnValidatePin(result, context);
      checkResetInput(errorMessage, pinCode, setPinCode);
      return;
    }

    modalContext.closeModal();

    if (callback) {
      callback(true);
    }
  };
};
