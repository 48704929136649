import React, { useEffect } from 'react';

import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { checkLoadingLifecycle } from 'utils';
import { PerformanceEvents } from 'interface';

export interface Props {
  component?: React.ReactElement | any;
  hashKey?: string;
}

/**
 * This component is for comparing lifecycle and limit value from dms and sending a event
 *
 * Please note:
 * If we need to track the maximum permissible value of a loader life cycle please use as <LoaderWrapperForEvents component={<SomeLoadComponent/>} />
 * It's only wrapper.
 */

function LoaderWrapperForEvents({ component = null, hashKey }: Props) {
  const perfomanceContext = usePerformanceEventsContext();
  const resolveKey = hashKey?.toLowerCase();

  useEffect(() => {
    perfomanceContext.setStartTime(PerformanceEvents.spinner, resolveKey);

    return () => {
      checkLoadingLifecycle(resolveKey, perfomanceContext);
    };
  }, []);

  return component;
}

export default LoaderWrapperForEvents;
