import React, { useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import { BODY_OPEN_CLASS_NAME } from 'consts';
import RailCardsList from '../RailCardsList/RailCardsList';

interface Props {
  checkResize: () => void;
}

const RailCardsWrapper = ({ checkResize }: Props) => {
  const resolvedWidth = useRef<number>(0);

  return (
    <AutoSizer onResize={checkResize}>
      {({ height = 0, width = 0 }) => {
        // For avoiding unnecessary rerendering of the RailCardsList component due to a change in width after adding the "open" class to the body element
        const isBlockedBodyScrolling = document.body.classList.contains(BODY_OPEN_CLASS_NAME);

        if (!isBlockedBodyScrolling) {
          resolvedWidth.current = width;
        }
        return <RailCardsList height={height} width={resolvedWidth.current || width} />;
      }}
    </AutoSizer>
  );
};

export default React.memo(RailCardsWrapper);
