import React, { useState, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { useRootStore } from 'hooks';
import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import {
  useApplyStyles,
  useSetAssetToMaxiPlayer,
  useOnInvisible,
  usePerformanceSpinerHook,
  useHandleSkeletonRef,
  useAppStartAndHomePageReady,
  useClearAppStartAndHomePageReady,
} from './hooks';
import RailProvider from './components/RailProvider/RailProvider';
import RailHorisontalResolveRendering from './components/RailHorisontalResolveRendering/RailHorisontalResolveRendering';

interface Props {
  store: RailStoreV2;
}

const RailHorisontal = ({ store }: Props) => {
  const rootStore = useRootStore();
  const [isImagesLoading, setImagesLoading] = useState(false);
  const isSkeletonCondition = useMemo(() => {
    return !store.isReady || isImagesLoading;
  }, [store.isReady, isImagesLoading]);
  const performanceEventsContext = usePerformanceEventsContext();
  const isHomePageFirstRail = useMemo(() => store.id === rootStore?.internalPages[0]?.internalWidgets[0]?.id, [
    store.id,
    rootStore,
  ]);
  const handleRef = useApplyStyles(store);
  const setImagesLoadingForward = useCallback(() => setImagesLoading, []); // eslint-disable-line
  const handleSkeletonRef = useHandleSkeletonRef(store, setImagesLoadingForward);

  useSetAssetToMaxiPlayer(store);
  useOnInvisible(store);
  usePerformanceSpinerHook({ store, isSkeletonCondition, performanceEventsContext });
  useAppStartAndHomePageReady({ store, isImagesLoading, isHomePageFirstRail, performanceEventsContext });
  useClearAppStartAndHomePageReady({ isHomePageFirstRail, performanceEventsContext });

  return (
    <RailProvider
      isSkeletonCondition={isSkeletonCondition}
      handleSkeletonRef={handleSkeletonRef}
      refElement={handleRef}
      store={store}
    >
      <RailHorisontalResolveRendering />
    </RailProvider>
  );
};

export default observer(RailHorisontal);
