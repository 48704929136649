import { useRef } from 'react';
import { useRailContext } from '../../../RailProvider/RailProvider';
import { useRailLastXPosition } from '../useRailLastXPosition/useRailLastXPosition';

function useRailCardsListCombiner(width: number) {
  const stopIndexRef = useRef(0);
  const {
    railCardsListRef,
    isLogoRail,
    railSettings,
    railData: { items, fetchNextPage, isLoading, hasNextPage, totalCount },
    railType,
  } = useRailContext();
  const nextPageHandle = (_: number, stopIndex: number) => {
    if (hasNextPage && !isLoading && stopIndex !== stopIndexRef.current) {
      stopIndexRef.current = stopIndex;

      fetchNextPage();
    }
  };
  const outerRef = useRef<HTMLDivElement | null>(null);
  const listWidth = width - 1;
  const { id } = railSettings;
  const savedOffset = useRailLastXPosition(id, railType);
  // @ts-ignore
  const offset = railCardsListRef?.current?.state?.scrollOffset || savedOffset;

  return {
    isLogoRail,
    outerRef,
    railCardsListRef,
    items,
    offset,
    listWidth,
    savedOffset,
    railSettings,
    nextPageHandle,
    hasNextPage,
    totalCount,
  };
}

export { useRailCardsListCombiner };
