import { useCallback, useEffect, useRef } from 'react';

import { preloadAssetsImage, railObserver } from 'components/widgets/Rail/utils';
import { DivNode } from 'types';
import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

/**
 * Custom hook that handles the skeleton reference for a rail component.
 * This hook os calling init method of railt stor
 * This hook is use to set up a callback function which will be called when the skeleton in the viewport of browser window.
 * The callback function takes a `DivNode` parameter representing the moundet node.
 * If the node parameter is defined/truthy, it means that node has been mounted and after initialization of rail store the preloadImage helper function must be called with necessary parameters
 *
 * @param setImagesLoadingForward - A callback function that changes the local state of component
 * @param store - rail store
 * @returns A callback function that shows/hides the skeleton view
 */

export const useHandleSkeletonRef = (
  store: RailStoreV2,
  setImagesLoadingForward: (value: React.SetStateAction<boolean>) => void,
) => {
  const cleanupFunction = useRef(false);

  useEffect(() => {
    return () => {
      cleanupFunction.current = true;
    };
  }, []);

  return useCallback((node: DivNode) => {
    if (node) {
      // @ts-ignore
      node.callback = async () => {
        await store.init();

        preloadAssetsImage(cleanupFunction, setImagesLoadingForward, store);
      };
      railObserver.observe(node);
    }
  }, []); // eslint-disable-line
};
