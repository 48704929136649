import React from 'react';
import cn from 'classnames';
import { createSkeletonCollection } from 'utils';

import styles from './DeviceListSkeleton.module.scss';

const SKELETON_ITEMS_NUMBER = 5;
const DEVICES_SKELETON_ITEMS_PREFIX = 'device-skeleton';

function DeviceListSkeleton({ isSettings }: { isSettings?: boolean }) {
  const devices = createSkeletonCollection(SKELETON_ITEMS_NUMBER, DEVICES_SKELETON_ITEMS_PREFIX);

  return (
    <div className={cn(styles.deviceListSkeleton, { [styles.settings]: isSettings })}>
      <div className={styles.title} />
      <div className={styles.description} />
      {devices.map((device) => (
        <div key={device.id} className={styles.deviceItem} />
      ))}
    </div>
  );
}

export default DeviceListSkeleton;
