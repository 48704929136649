import { RhinoEPGProgram } from '@kaltura-ott/tvpil-shared';

import { useClickHandler } from '../useClickHandler/useClickHandler';
import { useResolveTitle } from '../useResolveTitle/useResolveTitle';
import { useConditionsResolver } from '../useConditionsResolver/useConditionsResolver';
import { checkTextIsClipped } from '../../utils';

export function useProgramHooksCombiner(program: RhinoEPGProgram) {
  const {
    time,
    width,
    isLive,
    duration,
    isOnlyMd,
    epgParams,
    iconMDUrl,
    startDate,
    isCatchUp,
    isLoading,
    isNotFound,
    isWithoutMd,
    isFutureDate,
    isSeriesRecord,
    isAdultCatchUp,
    mosaicExternalId,
    isMdIconCondition,
    isCatchUpWithNoData,
    isRestrictedByAdult,
    isDescriptionHidden,
    widthForTimeEllipsis,
    isRecordIconCondition,
    isAdultCathUpPlaybackEnabled,
  } = useConditionsResolver(program);
  const title = useResolveTitle({
    isAdultCathUpPlaybackEnabled,
    isRestrictedByAdult,
    isCatchUp,
    isCatchUpWithNoData,
    program,
  });
  const clickHandler = useClickHandler({
    isLive,
    program,
    isLoading,
    isNotFound,
    isFutureDate,
    isAdultCatchUp,
    mosaicExternalId,
    isRestrictedByAdult,
    isAdultCathUpPlaybackEnabled,
  });
  const isTitleEllipsis = checkTextIsClipped(width, title, 'title') && !isDescriptionHidden;
  const isTimeEllipsis = checkTextIsClipped(widthForTimeEllipsis, time, 'time') && !isDescriptionHidden;

  return {
    clickHandler,
    isLoading,
    epgParams,
    width,
    duration,
    startDate,
    isFutureDate,
    title,
    isDescriptionHidden,
    widthForTimeEllipsis,
    time,
    isOnlyMd,
    isWithoutMd,
    isCatchUpWithNoData,
    isAdultCatchUp,
    isRecordIconCondition,
    isMdIconCondition,
    isSeriesRecord,
    iconMDUrl,
    isTitleEllipsis,
    isTimeEllipsis,
    isAdultCathUpPlaybackEnabled,
  };
}
