import { useCallback } from 'react';

import { addHours, startOfHour, subMinutes } from 'date-fns';

import { useEpgContext } from 'context/epgContext/epgContext';
import { formatPixelsToTimestamp } from 'components/pages/EpgV2/utils';
import { config } from 'components/pages/EpgV2/config';
import { epgLocalTimeStampStorage } from 'components/pages/EpgV2/storages';
import { HALF_DIVIDER } from 'consts';

export const useHandleClick = () => {
  const {
    epgParams: { epgStartTimestamp, epgEndTimestamp, ratio },
  } = useEpgContext();

  return useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const index = +e.currentTarget.dataset.index!;
    const channelWidth = formatPixelsToTimestamp(ratio, config.channelWidth);
    const screenTimestamp = formatPixelsToTimestamp(ratio, document.body.clientWidth);
    const rightEdge = epgEndTimestamp - screenTimestamp;
    const leftEdge = epgStartTimestamp - channelWidth;
    const newHour = startOfHour(addHours(epgLocalTimeStampStorage.viewTimestamp, index));
    let newViewTimestamp = subMinutes(newHour, epgLocalTimeStampStorage.screenWidthInMinutes / HALF_DIVIDER).valueOf();

    if (newViewTimestamp > rightEdge) {
      newViewTimestamp = rightEdge;
    }

    if (newViewTimestamp < leftEdge) {
      newViewTimestamp = leftEdge;
    }

    epgLocalTimeStampStorage.setViewTimestamp(newViewTimestamp);
  }, []);
};
