import React from 'react';
import { observer } from 'mobx-react';

import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import { VideoContextProvider } from 'context/videoContext/videoContext';
import { useDataFromRootStore } from './hooks/useDataFromRootStore/useDataFromRootStore';
import VideoContentInner from './components/VideoContentInner/VideoContentInner';

interface Props {
  image?: string;
  program: ViewableEntitiesType;
  isLandscapeRatio?: boolean;
  isOverlay?: boolean;
  onCloseModal?(): void;
  switchToLive?(): void;
  isDataLoaded?: boolean;
}

function VideoContent({
  program,
  image,
  isLandscapeRatio,
  isOverlay,
  onCloseModal,
  switchToLive,
  isDataLoaded,
}: Props) {
  const { firstEpisode, mosaicAsset } = useDataFromRootStore();

  return (
    <VideoContextProvider
      firstEpisode={firstEpisode}
      mosaicAsset={mosaicAsset}
      program={program}
      isOverlay={isOverlay}
      closeOverlay={onCloseModal}
      switchToLive={switchToLive}
    >
      <VideoContentInner image={image} isLandscapeRatio={isLandscapeRatio} isDataLoaded={isDataLoaded} />
    </VideoContextProvider>
  );
}

export default observer(VideoContent);
