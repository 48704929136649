import React from 'react';
import { RentalAsset } from '@kaltura-ott/tvpil-shared/lib/rhino/data/rental/types';

import { RailCardSkeleton } from 'components/widgets/RailV2/components/RailSkeleton/RailSkeleton';
import { RailCardContainerProps } from 'components/widgets/RailV2/components/RailProviderV2/types';
import RentalsCard from '../RentalsCard/RentalsCard';

function RentalsCardContainer({ item, itemWidth, imageHeight }: RailCardContainerProps<RentalAsset>) {
  if (!item?.id) {
    return <RailCardSkeleton height={imageHeight} width={itemWidth} />;
  }
  return <RentalsCard imageHeight={imageHeight} itemWidth={itemWidth} rentalItem={item} />;
}

export default React.memo(RentalsCardContainer);
