import { PlaybackEventService } from './PlaybackEventService';
import { PerformanceService } from './PerformanceService';
import { HeartbeatService } from './HeartbeatService';
import { playerStorage, contextStorage } from '../../storages';

export class AnalyticsService {
  private playbackEventService: PlaybackEventService;

  private performanceService: PerformanceService;

  private heartbeatService: HeartbeatService;

  constructor() {
    const { player } = playerStorage.getPlayer();
    const context = contextStorage.getContext();
    const { heartbeatContext, performanceContext } = context;

    this.performanceService = new PerformanceService(performanceContext, player);
    this.heartbeatService = new HeartbeatService(heartbeatContext, player);
    this.playbackEventService = new PlaybackEventService(context, player);
  }

  addEventListeners() {
    this.heartbeatService.addEventListeners();
    this.performanceService.addEventListeners();
    this.playbackEventService.addEventListeners();
  }

  removeEventListeners() {
    this.heartbeatService.removeEventListeners();
    this.performanceService.removeEventListeners();
    this.playbackEventService.removeEventListeners();
  }
}
