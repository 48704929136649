import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

import chevronIcon from 'assets/images/chevron.svg';
import { useMenuStore, usePageId, useSearchParam } from 'hooks';
import { MoreLinkType } from 'types';

import { FROM_REFERENCE_ID } from 'consts';
import { resolveLink } from './utils/resolveLink/resolveLink';

import styles from './MoreButton.module.scss';

interface Props {
  data: MoreLinkType;
}

const MoreButton = ({ data }: Props) => {
  const menuStore = useMenuStore();
  const pageId = usePageId();
  const referenceId = useSearchParam(FROM_REFERENCE_ID) || pageId;
  const { Tag, conditionalProps } = resolveLink(data, referenceId, menuStore);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className={styles.moreButton}>
      <Tag {...conditionalProps}>
        <FormattedMessage id='Rail.moreButton' />
        <SVG src={chevronIcon} />
      </Tag>
    </div>
  );
};

export default MoreButton;
