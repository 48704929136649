import { featureEnabler } from '@kaltura-ott/tvpil-shared';
import { FEATURES } from 'consts';

/**
 * Indicates whether extended error logging is currently active.
 * This is determined based on the application's running mode and the status of the 'SHOW_EXTENDED_ERROR_LOG' feature flag.
 * It's `true` if the application is in developer mode or if the feature flag is enabled.
 */
export const checkExtendedErrorLogActivity = (): boolean =>
  featureEnabler.variation(FEATURES.SHOW_EXTENDED_ERROR_LOG, false) || import.meta.env.DEV;
