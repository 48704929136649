import React, { createContext, MutableRefObject, useContext, useRef } from 'react';
import { useNavigate, useLocation, useNavigationType, useParams } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

const StableReactRouterContext = createContext<MutableRefObject<any> | null>(null);

const StableReactRouterContextProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navType = useNavigationType();
  const params = useParams();
  const reactRouterRef = useRef({ navigate, location, navType, params });

  return <StableReactRouterContext.Provider value={reactRouterRef}>{children}</StableReactRouterContext.Provider>;
};

const useStableReactRouter = () => {
  const reactRouterRef = useContext(StableReactRouterContext);

  if (reactRouterRef?.current === null) throw new Error('StableNavigate context is not initialized');

  return reactRouterRef?.current!;
};

export { StableReactRouterContext, StableReactRouterContextProvider, useStableReactRouter };
