import React from 'react';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

import chevronIcon from 'assets/images/chevron.svg';

import styles from './Card.module.scss';

interface Props {
  blank?: boolean;
  className?: string;
  link?: string;
  name: string;
  onHandleClick?: Function;
  text?: string;
  title?: string;
}

export default function Card({ blank, className, link, name, onHandleClick, text, title }: Props) {
  const target = blank ? '_blank' : undefined;
  const isClickable = !!link || !!onHandleClick;

  let Tag: any = isClickable ? 'button' : 'div';
  let linkAttr = {};

  if (link) {
    Tag = link.startsWith('http') ? 'a' : Link;
    linkAttr = Tag === 'a' ? { href: link, target } : { to: link };
  }

  const handleClick = (e: React.MouseEvent) => {
    if (onHandleClick) {
      onHandleClick(e);
    }
  };

  return (
    <Tag
      aria-label={title}
      className={cn(styles.card, className, { [styles.clickable]: isClickable })}
      onClick={handleClick}
      target={target}
      title={title}
      {...linkAttr}
    >
      <h5>{name}</h5>
      <div>
        {text}
        {isClickable && <SVG src={chevronIcon} height='16' width='10' />}
      </div>
    </Tag>
  );
}
