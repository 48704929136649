import React from 'react';
import { useIntl } from 'react-intl';

import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';
import { RESPONSE_ERROR, RESPONSE_PENDING } from '../../constants';

import styles from './PinCodeModalDetailsResult.module.scss';

interface Props {
  type: typeof RESPONSE_ERROR | typeof RESPONSE_PENDING;
  onDecline: () => void;
}

function PinCodeModalDetailsResult({ type, onDecline }: Props) {
  const intl = useIntl();
  const translationId =
    type === RESPONSE_ERROR ? 'PinCodeModalDetails.RentErrorMessage' : 'PinCodeModalDetails.RentPendingMessage';
  const defaultMessage =
    type === RESPONSE_ERROR
      ? 'Purchase was not completed, You will not be charged for this purchase'
      : 'Purchase is pending confirmation and will be completed in 8 minutes';

  return (
    <DefaultModal
      className={styles[type]}
      onDecline={onDecline}
      title={intl.formatMessage({ id: translationId, defaultMessage }, { br: <br /> }) as string}
    />
  );
}

export default PinCodeModalDetailsResult;
