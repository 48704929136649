import React from 'react';

import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { ActionButtonTypesEnum } from 'interface';
import findLoginItems from '../findLoginItems';

import styles from './LoginButtons.module.scss';

interface Props {
  items: { inAppLink: string; title: string; type: string }[];
  onSignIn: Function;
}

export default function LoginButtons({ items, onSignIn }: Props) {
  const { signIn, signUp } = findLoginItems(items);

  return items?.length ? (
    <div className={styles.controls}>
      {signIn?.title && (
        <ActionButton type={ActionButtonTypesEnum.primary} className={styles.button} onClick={onSignIn}>
          {signIn.title}
        </ActionButton>
      )}
      {signUp?.title && signUp?.inAppLink && (
        <ActionButton className={styles.button} to={signUp.inAppLink}>
          {signUp.title}
        </ActionButton>
      )}
    </div>
  ) : (
    <span>No items</span>
  );
}
