import React, { createContext, useContext, useMemo } from 'react';

import { CollectionGridSettings } from '@kaltura-ott/tvpil-shared';

interface Props {
  gridSettingsData: CollectionGridSettings;
  children: React.ReactNode;
}

interface CollectionGridSettingsContextType {
  gridSettingsData: CollectionGridSettings;
  itemWidth: number;
}

const CollectionGridSettingsContext = createContext<CollectionGridSettingsContextType>({
  gridSettingsData: {} as CollectionGridSettings,
  itemWidth: 0,
});

const CollectionGridSettingsProvider = ({ gridSettingsData, children }: Props) => {
  const { imageWidth } = gridSettingsData;

  const contextValue = useMemo(() => {
    return {
      gridSettingsData,
      itemWidth: imageWidth ?? 0,
    };
  }, [gridSettingsData, imageWidth]);

  return (
    <CollectionGridSettingsContext.Provider value={contextValue}>{children}</CollectionGridSettingsContext.Provider>
  );
};

const useCollectionGridSettingsContext = () => useContext(CollectionGridSettingsContext);

export { CollectionGridSettingsProvider, useCollectionGridSettingsContext };
