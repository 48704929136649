import React from 'react';

import { RAIL_PAGE_SIZE } from 'consts';
import RailSkeleton from 'components/widgets/RailV2/components/RailSkeleton/RailSkeleton';
import RailProviderV2 from '../components/RailProviderV2/RailProviderV2';
import HeroRailContainer from './components/HeroRailContainer/HeroRailContainer';
import { useHeroRail } from './hooks/useHeroRail/useHeroRail';

interface Props {
  referenceId: string;
  index: number;
  pageReferenceId: string;
}

function HeroRail({ referenceId, index, pageReferenceId }: Props) {
  const { railSettings, railData, railType } = useHeroRail(referenceId, pageReferenceId);

  if (!railSettings) {
    return null;
  }

  if (!railData.items.length) {
    return <RailSkeleton railType={railType} settings={railSettings} items={RAIL_PAGE_SIZE} />;
  }

  return (
    <RailProviderV2
      index={index}
      type={railType}
      settings={railSettings}
      data={railData}
      referenceId={referenceId}
      pageReferenceId={pageReferenceId}
    >
      <HeroRailContainer />
    </RailProviderV2>
  );
}

export default HeroRail;
