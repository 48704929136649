import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { CategoryStore, RhinoEPGProgram, SubpageStore, Subscription } from '@kaltura-ott/tvpil-shared';
import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { MediaAsset } from '@kaltura-ott/tvpil-shared/lib/stores/entities/MediaAsset';

import { ActionButtonTypesEnum } from 'interface';
import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';
import PinCode from './PinCode/PinCode';
import { PinCodeModalTypes } from './PinCodeModalTypesEnum';
import { pincodeLength } from '../constants';
import {
  usePinCodeModalCombiner,
  useSetAutoFocusAndSubmit,
  useGetPinModalMessage,
  useResetErrorMessage,
  useConfirmationErrorMsg,
} from './hooks';

import styles from './PinCodeModal.module.scss';

export interface PinCodeModalProps {
  asset: MediaAsset | CategoryStore | SubpageStore | Subscription | RhinoEPGProgram;
  type: PinCodeModalTypes;
  beforePinCodeContent?: React.ReactNode;
  afterPinCodeContent?: React.ReactNode;
  validatePin?: (pin: string) => Promise<any> | Object | undefined;
  callback?(success: boolean): void;
  onDecline?(): void;
  title?: string;
  description?: string | React.ReactNode;
  className?: string;
  isConfirmErrorMessage?: boolean;
  context?: UIDAPresentationTypeEnum;
  rhinoParentalRating?: number;
}

function PinCodeModal({
  validatePin,
  beforePinCodeContent,
  afterPinCodeContent,
  callback,
  className,
  description,
  title,
  type,
  onDecline,
  asset,
  isConfirmErrorMessage = false,
  context,
  rhinoParentalRating,
}: PinCodeModalProps) {
  const {
    intl,
    error,
    pinCode,
    loading,
    pinTimeout,
    inputWrapper,
    isResetError,
    pinLimitError,
    isPurchasePinLocked,
    submit,
    setError,
    setPinCode,
  } = usePinCodeModalCombiner({ type, asset, context, rhinoParentalRating, validatePin, callback });

  useSetAutoFocusAndSubmit(pinCode, inputWrapper, submit);

  const { pinText, modalTitle } = useGetPinModalMessage(type, title, description);

  useResetErrorMessage(setError, isResetError);

  useConfirmationErrorMsg(isConfirmErrorMessage, setError);

  return (
    <form onSubmit={submit}>
      <DefaultModal
        buttonProps={{
          acceptButtonText: intl.formatMessage({
            id: 'PinCodeModal.DefaultModal.buttonSubscribe',
            defaultMessage: 'Subscribe',
          }),
          acceptButtonType: ActionButtonTypesEnum.primary,
        }}
        className={className}
        loading={loading}
        onDecline={onDecline}
        title={modalTitle}
      >
        {beforePinCodeContent}
        <div>
          <div className={cn(styles.instruction, { [styles.purchaseLock]: isPurchasePinLocked })}>{pinText}</div>
        </div>
        <PinCode
          error={pinLimitError || error}
          inputWrapper={inputWrapper}
          loading={loading}
          pinCode={pinCode}
          pincodeLength={pincodeLength}
          setError={setError}
          setPinCode={setPinCode}
          pinTimeout={pinTimeout}
        />
        {afterPinCodeContent}
      </DefaultModal>
    </form>
  );
}

export default observer(PinCodeModal);
