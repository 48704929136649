import { KuxEpisodeType } from '@kaltura-ott/tvpil';
import { appStore, Linear, ProgramStateV2, Program, Episode, Movie, VodAsset } from '@kaltura-ott/tvpil-shared';

function playoutStoreDataResolver() {
  // TODO tests must be added
  const {
    autoNextEpisode,
    currentAsset,
    isStartOver,
    loadingNextProgram = false,
    type,
    FFEnabled,
    showNextButtonDuration,
    showSeriesPage,
    smartNextEpisode,
    autoplayNextProgram,
  } = appStore.playoutStoreV2;
  const { isSeekWithThumbnailsEnabled } = appStore.rootStore.playerStore! || {};
  const geoBlockingProperties = {
    name: '',
    description: '',
  };
  const mediaFileId = currentAsset?.mediaFileId;
  let asset;
  let catchupAvailableTime;
  let channel;
  let currentEntity;
  let enableCatchUp;
  let enableStartOver: boolean = false;
  let enableTrickPlay: boolean = false;
  let endDate = 0;
  let epgId;
  let episodeName;
  let externalId;
  let introOffset;
  let isAdult: boolean = false;
  let isDisableStartOver;
  let isGeoBlocking: boolean = false;
  let isNotFound: boolean = false;
  let isRecordable: boolean = false;
  let isRecordDefined: boolean = false;
  let isRecordDefinedAsSeries: boolean = false;
  let isRecordDefinedAsOnlySeries: boolean = false;
  let isRecordDefinedAsSingle: boolean = false;
  let isProgramRecord: boolean = false;
  let isVod: boolean = false;
  let isVodEpisode: boolean = false;
  let isPast: boolean = false;
  let linearId;
  let mosaicAsset;
  let mosaicInfo;
  let recapOffset;
  let record;
  let seasonNumber;
  let seriesId;
  let seriesRecord;
  let skipIntroMaxCountdownTime;
  let skipRecapMaxCountdownTime;
  let startDate = 0;
  let programState;
  let isMovie = false;

  if (currentAsset instanceof Linear) {
    const { currentProgram } = currentAsset;
    asset = currentProgram?.data;
    programState = currentProgram?.programState;
    isPast = programState === ProgramStateV2.PAST;
    currentEntity = currentAsset?.currentProgram;
    mosaicAsset = (appStore.playoutStoreV2.mosaicAsset as Linear)?.currentProgram;
    isGeoBlocking = currentAsset?.isGeoBlocked;
    Object.assign(geoBlockingProperties, { ...currentAsset?.geoBlockingProperties });
  } else if (currentAsset instanceof Program) {
    asset = currentAsset?.data;
    currentEntity = currentAsset;
    programState = currentAsset?.programState;
    isPast = programState === ProgramStateV2.PAST;
    isGeoBlocking = !!currentAsset?.linear?.isGeoBlocked;
    isProgramRecord = !!currentAsset?.isProgramRecord;
    mosaicAsset = appStore.playoutStoreV2.mosaicAsset;
    Object.assign(geoBlockingProperties, { ...currentAsset?.linear?.geoBlockingProperties });
  } else if (currentAsset instanceof Episode) {
    introOffset = currentAsset?.introOffset;
    isVodEpisode = true;
    recapOffset = currentAsset?.recapOffset;
    skipIntroMaxCountdownTime = currentAsset?.skipIntroMaxCountdownTime;
    skipRecapMaxCountdownTime = currentAsset?.skipRecapMaxCountdownTime;
  } else if (currentAsset instanceof Movie) {
    isMovie = true;
  }

  if (currentAsset instanceof Linear || currentAsset instanceof Program) {
    isAdult = !!currentEntity?.isAdult || !!currentEntity?.linear?.isAdult;
    catchupAvailableTime = currentEntity?.catchupAvailableTime;
    channel = (mosaicAsset as Program)?.linear?.data.title;
    enableCatchUp = asset?.enableCatchUp;
    enableStartOver = !!asset?.enableStartOver;
    enableTrickPlay = !!asset?.enableTrickPlay;
    endDate = asset?.endDate || 0;
    epgId = asset?.epgId;
    externalId = asset?.externalId;
    isNotFound = !!asset?.isNotFound;
    isRecordable = !!currentEntity?.isRecordable;
    isRecordDefined = !!currentEntity?.record || !!currentEntity?.seriesRecord;
    isRecordDefinedAsOnlySeries = !!(!currentEntity?.record && currentEntity?.seriesRecord);
    isRecordDefinedAsSeries = !!currentEntity?.record?.isSeriesRecord;
    isRecordDefinedAsSingle = !!currentEntity?.record?.isSingleRecord;
    linearId = currentAsset?.linearId;
    mosaicInfo = (mosaicAsset as Program)?.data?.mosaicInfo;
    record = currentEntity?.record;
    seriesRecord = currentEntity?.seriesRecord;
    startDate = asset?.startDate || 0;
  }

  if (currentAsset instanceof Movie || currentAsset instanceof Episode) {
    asset = currentAsset?.data;
    currentEntity = currentAsset;
    enableCatchUp = true;
    enableStartOver = true;
    enableTrickPlay = true;
    isAdult = !!currentAsset?.isAdult;
    isGeoBlocking = (currentAsset as VodAsset)?.isGeoBlocked;
    isVod = true;
    Object.assign(geoBlockingProperties, { ...(currentAsset as VodAsset)?.geoBlockingProperties });
  }

  if (currentAsset instanceof Linear || currentAsset instanceof Program || currentAsset instanceof Episode) {
    episodeName = (asset as KuxEpisodeType)?.episodeName;
    seasonNumber = (asset as KuxEpisodeType)?.seasonNumber;
    seriesId = (asset as KuxEpisodeType)?.metas?.SeriesID;
  }

  const contentType = asset?.metas?.ContentType;
  const genre = currentAsset?.genres;
  const id = asset?.id;
  const imdbId = asset?.metas?.imdb_id;
  const mediaType = asset?.mediaType;
  const subtitleLanguage = currentAsset?.subtitleLanguage;

  let title = asset?.title;

  if (seriesId) {
    title = asset?.tags?.SeriesName?.length ? asset?.tags?.SeriesName[0] : title;
  }

  return {
    asset,
    autoNextEpisode,
    catchupAvailableTime,
    channel,
    contentType,
    currentAsset,
    currentEntity,
    enableCatchUp,
    enableStartOver,
    enableTrickPlay,
    endDate,
    epgId,
    episodeName,
    externalId,
    genre,
    geoBlockingProperties,
    id,
    imdbId,
    introOffset,
    isAdult,
    isDisableStartOver,
    isGeoBlocking,
    isNotFound,
    isRecordable,
    isRecordDefined,
    isRecordDefinedAsOnlySeries,
    isRecordDefinedAsSeries,
    isRecordDefinedAsSingle,
    isProgramRecord,
    isStartOver,
    isVod,
    isVodEpisode,
    linearId,
    loadingNextProgram,
    mediaFileId,
    mediaType,
    mosaicAsset,
    mosaicInfo,
    recapOffset,
    record,
    seasonNumber,
    seriesId,
    seriesRecord,
    showNextButtonDuration,
    showSeriesPage,
    skipIntroMaxCountdownTime,
    skipRecapMaxCountdownTime,
    smartNextEpisode,
    startDate,
    subtitleLanguage,
    title,
    type,
    FFEnabled,
    isPast,
    isSeekWithThumbnailsEnabled: !!isSeekWithThumbnailsEnabled,
    autoplayNextProgram,
    isMovie,
  };
}

export { playoutStoreDataResolver };
