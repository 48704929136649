import { useCallback } from 'react';
import { LAST_RAIL_X_POSITION } from 'consts';

interface Props {
  id: number;
  offset: React.MutableRefObject<number>;
  pageReferenceId?: string;
  referenceId?: string;
}

export const useOnCardClickHook = ({ id, offset, pageReferenceId, referenceId }: Props) =>
  useCallback(
    (linearId?: number) =>
      sessionStorage.setItem(
        LAST_RAIL_X_POSITION,
        JSON.stringify({ id, position: offset.current, linearId, referenceId, pageReferenceId }),
      ),
    [offset.current, id],
  );
