import { useEffect } from 'react';
import { globalStorage } from 'services/globalStorage';

function BaseInitHook({ store, presentPlayer }: any) {
  useEffect(() => {
    (async () => {
      if (store) {
        await store.init();
      }
    })();
  }, [store]);

  useEffect(() => {
    if (!presentPlayer) {
      globalStorage.setPossiblePresentPlayer(false);
    }

    return () => {
      globalStorage.setPossiblePresentPlayer(false);
    };
  }, []);

  return null;
}

export { BaseInitHook };
