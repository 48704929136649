import React from 'react';
import cn from 'classnames';
import { RentalAsset } from '@kaltura-ott/tvpil-shared/lib/rhino/data/rental/types';

import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import { useApplyStyles } from 'components/widgets/RailV2/components/RailView/hooks/useApplyStyles/useApplyStyles';
import DynamicRailViewer from 'components/widgets/RailV2/components/DynamicRailViewer/DynamicRailViewer';
import Title from 'components/widgets/RailV2/components/Title/Title';
import RentalsCardContainer from '../RentalsCardContainer/RentalsCardContainer';

import styles from './RentalsRailContainer.module.scss';

const RentalsRailContainer = () => {
  // using version v2 of the rail provider
  const {
    wrapperRef,
    refElementInner,
    componentHeight,
    settings,
    data: { hasNextPage, totalCount, items },
    data,
    type,
  } = useRailContext<RentalAsset>();

  const handleRef = useApplyStyles(settings);

  return (
    <div ref={handleRef} className={cn(styles.container)}>
      <div ref={refElementInner}>
        <Title
          hasNextPage={!!hasNextPage}
          totalCount={totalCount}
          itemsLength={items.length}
          railType={type}
          forwardHeaderRef={handleRef}
          isLogoRail={false}
          railSettings={settings}
        />
        <div
          ref={wrapperRef}
          style={{
            height: componentHeight,
          }}
        >
          <DynamicRailViewer railData={data}>
            {({ imageHeight, itemWidth, item }) => (
              <RentalsCardContainer imageHeight={imageHeight} itemWidth={itemWidth} item={item} />
            )}
          </DynamicRailViewer>
        </div>
      </div>
    </div>
  );
};

export default RentalsRailContainer;
