import { AssetPresentationRatioEnum } from '@kaltura-ott/tvpil';

import { paramImageResolveForPosterImageByWidth, paramImageResolveParamForLandscapeByWidth } from 'utils';
import { useRootStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import { checkLandscapeOrientation } from './utils/checkLandscapeOrientation';

const WIDTH_MODAL = 850;
const WIDTH_IMAGE_POSTER = 334;

export const useVideoDetailsImage = () => {
  const rootStore = useRootStore();

  const { detailsStore } = rootStore!;
  const { asset: originalAsset, ratio } = detailsStore;
  const asset = globalStorage.detailsAsset ?? originalAsset;

  if (!ratio) return { posterUrl: '', isLandScapeView: undefined };

  const isLandScapeView = checkLandscapeOrientation(ratio);
  let paramImage = paramImageResolveForPosterImageByWidth(WIDTH_IMAGE_POSTER);

  if (isLandScapeView) {
    paramImage = paramImageResolveParamForLandscapeByWidth(WIDTH_MODAL);
  }

  const posterUrl = asset?.data.images[ratio as AssetPresentationRatioEnum]
    ? `${asset?.data.images[ratio as AssetPresentationRatioEnum]?.url}${paramImage}`
    : '';

  return { posterUrl, isLandScapeView };
};
