import React, { createContext, useMemo, useContext } from 'react';

import { observer } from 'mobx-react';
import { KuxImages } from '@kaltura-ott/tvpil';

export interface ContextType {
  muted: boolean;
  asset: any;
  playableAssetId: number;
  loaded: boolean;
  isContentRestricted: boolean;
  isContentGeoBlocking: boolean;
  isContentBlockedAsAdult: boolean;
  isLinearWithoutCurrentProgram: boolean;
  isPlayerCondition: boolean;
  isNoProgramCondition: boolean;
  isLinear: boolean;
  program: any;
  programTitle: string | undefined;
  logoImages: KuxImages;
  mosaicSubstreamTitle: string | undefined;
  programName: string | undefined;
  timeRange: any;
  isImagesCondition: boolean;
  isImagesErrorCondition: boolean;
  isMosaicProgram: boolean;
  watchNowHandler(): void;
  setChannelLogoError(value: boolean): void;
  muteHandler(): void;
  handleInfoButton(): void;
}

interface ProviderInterface extends ContextType {
  children: React.ReactNode;
}

const VideoPlayoutContext = createContext<ContextType>({
  muted: false,
  asset: undefined,
  playableAssetId: 0,
  loaded: false,
  isContentRestricted: false,
  isContentGeoBlocking: false,
  isContentBlockedAsAdult: false,
  isLinearWithoutCurrentProgram: false,
  isNoProgramCondition: false,
  isPlayerCondition: false,
  isLinear: false,
  program: undefined,
  timeRange: undefined,
  programTitle: '',
  logoImages: {},
  mosaicSubstreamTitle: undefined,
  programName: undefined,
  isImagesCondition: false,
  isImagesErrorCondition: false,
  isMosaicProgram: false,
  watchNowHandler: () => {},
  setChannelLogoError: () => {},
  muteHandler: () => {},
  handleInfoButton: () => {},
});

const VideoPlayoutProvider = ({
  muted,
  asset,
  playableAssetId,
  loaded,
  isContentRestricted,
  isContentGeoBlocking,
  isContentBlockedAsAdult,
  isLinearWithoutCurrentProgram,
  isNoProgramCondition,
  isPlayerCondition,
  isLinear,
  program,
  timeRange,
  programTitle,
  logoImages,
  mosaicSubstreamTitle,
  programName,
  isImagesCondition,
  isImagesErrorCondition,
  isMosaicProgram,
  watchNowHandler,
  setChannelLogoError,
  muteHandler,
  handleInfoButton,
  children,
}: ProviderInterface) => {
  const contextValue = useMemo(() => {
    return {
      muted,
      asset,
      playableAssetId,
      loaded,
      isContentRestricted,
      isContentGeoBlocking,
      isContentBlockedAsAdult,
      isLinearWithoutCurrentProgram,
      isNoProgramCondition,
      isPlayerCondition,
      isLinear,
      program,
      timeRange,
      programTitle,
      logoImages,
      mosaicSubstreamTitle,
      programName,
      isImagesCondition,
      isImagesErrorCondition,
      isMosaicProgram,
      watchNowHandler,
      setChannelLogoError,
      muteHandler,
      handleInfoButton,
    };
  }, [
    muted,
    asset,
    playableAssetId,
    loaded,
    isContentRestricted,
    isContentGeoBlocking,
    isContentBlockedAsAdult,
    isLinearWithoutCurrentProgram,
    isNoProgramCondition,
    isPlayerCondition,
    isLinear,
    program,
    programTitle,
    logoImages,
    mosaicSubstreamTitle,
    programName,
    timeRange,
    isImagesCondition,
    isImagesErrorCondition,
    isMosaicProgram,
    watchNowHandler,
    setChannelLogoError,
    muteHandler,
    handleInfoButton,
  ]);

  return <VideoPlayoutContext.Provider value={contextValue}>{children}</VideoPlayoutContext.Provider>;
};

const useVideoPlayoutContext = () => useContext(VideoPlayoutContext);

export { VideoPlayoutProvider, useVideoPlayoutContext };

export default observer(VideoPlayoutProvider);
