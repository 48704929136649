import { CollectionAsset, RailSettings } from '@kaltura-ott/tvpil-shared';

import {
  ADDITIONAL_HEIGHT,
  ADDITIONAL_EPISODE_PADDING,
  INITIAL_OFFSET,
  TITLE_BLOCK_MARGIN,
  CARD_PADDING,
} from '../../constants';

export function calculateItemHeight(railSettings: RailSettings, isLogoRail: boolean) {
  const {
    imageHeight,
    branding: { fontSize },
  } = railSettings;
  const titleBlockHeight = fontSize + TITLE_BLOCK_MARGIN;
  let componentHeight = imageHeight ?? 0;

  if (!isLogoRail) {
    componentHeight += ADDITIONAL_HEIGHT + titleBlockHeight + ADDITIONAL_EPISODE_PADDING;
  }

  // TODO: must be added the logic for railType === RAIL_TYPE.CW

  return componentHeight;
}

export function calculateTotalWidth(list: CollectionAsset[], settings: RailSettings) {
  let width = 0;

  for (let i = 0; i < list.length; i += 1) {
    width += settings.imageWidth ? settings.imageWidth + CARD_PADDING : 0;
  }

  return Math.ceil(width) + INITIAL_OFFSET;
}
