import { useIntl } from 'react-intl';

import { MediaType, QuotaStatusEnum, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import {
  ContinueRailStoreV2,
  DataRailV2,
  Episode,
  Linear,
  LinkAsAsset,
  Logo,
  Movie,
  Program,
  ProgramStateV2,
  Series,
  ViewableEntitiesType,
  VodAsset,
} from '@kaltura-ott/tvpil-shared';

import { useDetectLocation, useParentalPinStore, useRecordRepository, useAppStore } from 'hooks';
import { getSeasonEpisodeTitle, checkIsMosaicProgram } from 'utils';
import { useCardLink } from '../useCardLink/useCardLink';
import { getImageUrl } from '../../helpers';

export function useCardDataResolver(asset: ViewableEntitiesType | Linear | LinkAsAsset, railStore: DataRailV2) {
  const { isSearchPage } = useDetectLocation();

  const { subtype } = railStore;

  // resolve store type
  const isCatchupSeries = subtype === UIDAPresentationTypeEnum.series_catch_up;
  const isContinueWatching = subtype === UIDAPresentationTypeEnum.cw;
  const isHero = subtype === UIDAPresentationTypeEnum.hero;
  const isNpvr = subtype === UIDAPresentationTypeEnum.npvr;
  const isSuggested = subtype === UIDAPresentationTypeEnum.suggested;
  const isRented = subtype === UIDAPresentationTypeEnum.tvod; // Rented badge is shown only on cards in My Rental rail

  const {
    appStore: {
      lastWatchedLinear: { mainHeroRail },
    },
  } = useAppStore();

  const recordRepository = useRecordRepository();
  const parentalPinStore = useParentalPinStore();
  const intl = useIntl();
  let currentAsset = asset;
  // resolve type of Asset

  const isLogoAsset = asset instanceof Logo;

  const isProgramAsset = !!(
    asset instanceof Program ||
    asset instanceof Episode ||
    asset instanceof Series ||
    asset instanceof Movie ||
    (asset instanceof Linear && asset.currentProgram && isHero)
  );
  const isLinearChannel = !!(asset instanceof Linear);
  const isLinearAsset = !!(asset instanceof Linear && asset.currentProgram && asset.currentProgram.data?.notFound);
  const isLinkAsAsset = !!(asset instanceof LinkAsAsset);
  const isRegularAsset = !!(isProgramAsset || isLinearAsset || isLinkAsAsset || asset instanceof Linear);
  const isNoData =
    (isLinearChannel && asset.currentProgram?.data.notFound) || (isLinearChannel && !asset.currentProgram);

  // resolve asset
  if (isProgramAsset && asset instanceof Linear && asset.currentProgram && !isNoData) {
    currentAsset = asset.currentProgram as Program;
  }

  const {
    data: { adult, mediaType, metas, tags },
    isPurchasable,
  } = currentAsset;
  const { isProviderAsset, isAssetPlayOnExternalApp } = currentAsset as VodAsset;
  const {
    data: { seriesId },
  } = currentAsset as Program | Episode;
  const {
    catchUpAirDate,
    isGroupedSeries,
    record,
    seriesRecord,
    contentMarker,
    isNewEpisode,
    data: { id, mosaicInfo },
    linear,
    programState,
    recordBookmark,
    isProgramRecord,
    mosaicProgram,
    rating,
    linearId,
    recordingExpirationInDays,
    totalRecordedEpisodes,
  } = currentAsset as Program;

  let seasonEpisodeTitle;
  let seriesName;
  let title = currentAsset?.data?.title;
  // resolve card type
  const isAggregatedSeriesRecord =
    isNpvr && !!record && !!totalRecordedEpisodes && (record.isSeriesRecord || totalRecordedEpisodes > 1);
  const isExternalProviderAsset = isProviderAsset && isAssetPlayOnExternalApp;
  const isSeriesVod = mediaType === MediaType.Series;
  const isSingleVod = mediaType === MediaType.Movie || mediaType === MediaType.Episode;
  const isVod = isSingleVod || isSeriesVod;
  const isVodEpisode = mediaType === MediaType.Episode;
  const isTypeOfSeries = isCatchupSeries || isAggregatedSeriesRecord || isGroupedSeries || isSeriesVod;
  const isContentRestricted = !!(adult && parentalPinStore.isBlockByAdult(currentAsset) && !isExternalProviderAsset);
  const isMosaic = checkIsMosaicProgram(mosaicInfo) && !!mosaicInfo?.mosaicChannelsInfo?.length;
  const isNpvrEnabled = recordRepository.space.status !== QuotaStatusEnum.NpvrServiceNotAllowed;
  const isRecordIcon = isNpvrEnabled && (record || seriesRecord) && !isCatchupSeries;
  const isSeriesRecord = !!seriesRecord || isAggregatedSeriesRecord;
  const isFirstItemInHeroRail = isHero && linearId === railStore.items[0].linearId;
  const lastWatchedSubLinearExternalId = isFirstItemInHeroRail
    ? mainHeroRail?.lastWatchedSubLinearExternalId
    : undefined;

  let isDisplayCartIcon = isPurchasable && !isCatchupSeries && !isContinueWatching;

  if (!isSearchPage && !railStore.presentEntitlementIndicator) {
    isDisplayCartIcon = false;
  }

  if (!isContentRestricted) {
    seriesName = tags?.SeriesName?.length ? tags.SeriesName[0] : undefined;

    if (!isTypeOfSeries) {
      // @ts-ignore
      seasonEpisodeTitle = getSeasonEpisodeTitle(intl, metas, ':', true, currentAsset?.data?.episodeName);
    }
  }

  if (seriesId) {
    title = seriesName || title;
  }

  // resolve state of program
  const isLive = programState === ProgramStateV2.LIVE && !isTypeOfSeries;
  const isFuture = programState === ProgramStateV2.FUTURE;
  const isFutureProgram = isFuture && !isCatchupSeries;
  const isRecord = isNpvr || (isContinueWatching && isProgramRecord);
  const isSinglePlayableNpvr = isRecord && !isFuture && !isTypeOfSeries;

  const isOnGoingRecord = record?.data.isOngoing;

  // resolve Suppress program
  const isSuppressedEpisode = (railStore as ContinueRailStoreV2).useSuppress && isVodEpisode;

  // resolve playable asset
  const isPlayableAsset =
    !isRecord && !isTypeOfSeries && !isFuture && !isPurchasable && !isSuppressedEpisode && !isLinkAsAsset;

  // resolve MD
  const isMdCondition = !!mosaicInfo?.isMosaicEvent;

  // resolve card link
  const link = useCardLink({
    asset: currentAsset,
    isProgramAsset,
    isLinearChannel,
    isLinearAsset,
    isLinkAsAsset,
    isContinueWatching,
    lastWatchedSubLinearExternalId,
    isPurchasable,
    isLive,
    linear,
    id,
    isSinglePlayableNpvr,
    isSingleVod,
    isSuppressedEpisode,
    isPlayableAsset,
    railStoreSubtype: subtype,
    isAggregatedSeriesRecord,
    isGroupedSeries,
    isFutureProgram,
    isMdCondition,
    isHero,
    isOnGoingRecord,
  });

  // resolve media type
  const mediaTypeParam = currentAsset.data.mediaType.toLowerCase();

  // resolve the title
  if (isContentRestricted) {
    title = intl.formatMessage({
      id: 'Rail.RailCard.restrictedContent',
      defaultMessage: 'Restricted content',
    });
  }

  // resolve image url of the asset
  const imageUrl =
    isProgramAsset || isLinearChannel
      ? getImageUrl({ asset: currentAsset as ViewableEntitiesType, listStore: railStore, isHero }).imageUrl
      : '';

  // resolve the flags

  const isNotHeroAndContinueWatching = !isHero && !isContinueWatching;
  const isDisplayContentMarker = !isRented && isVod && !!contentMarker && !isProviderAsset;
  const isShowPlayButton = (isPlayableAsset || isSinglePlayableNpvr) && !isOnGoingRecord;
  const isChannelInfo = isNotHeroAndContinueWatching && linear?.data;
  const isShowAirTime = isNotHeroAndContinueWatching && !isTypeOfSeries && catchUpAirDate;
  const isShowFutureProgramInfo = isFutureProgram && catchUpAirDate;
  const isChannelAndAirTimeInfoCondition = (isChannelInfo && !isGroupedSeries) || isShowAirTime;
  const isRecordExpires = isNpvr && !isAggregatedSeriesRecord && record && Number.isInteger(recordingExpirationInDays!);
  const isDisplayRating = isNotHeroAndContinueWatching && !isFutureProgram && railStore.presentRating;
  const isShowedProgressBar = !isTypeOfSeries && !isFuture;
  const isMosaicHeroRailProgram = isMosaic && isHero;

  // resolve info button
  const isShowedOnlyInfo = isContentRestricted || isLinkAsAsset || isNoData;

  // resolve bookmark
  let { bookmark } = currentAsset as Program;
  if (isRecord) {
    bookmark = recordBookmark;
  }

  if (isTypeOfSeries) {
    bookmark = undefined;
  }

  return {
    link,
    title,
    isVod,
    isHero,
    isNpvr,
    record,
    rating,
    bookmark,
    linearId,
    seriesId,
    isMosaic,
    isNoData,
    imageUrl,
    isRented,
    seriesName,
    isSingleVod,
    isLogoAsset,
    isSuggested,
    isRecordIcon,
    currentAsset,
    isNewEpisode,
    isShowAirTime,
    isMdCondition,
    isPurchasable,
    isChannelInfo,
    contentMarker,
    isRegularAsset,
    catchUpAirDate,
    mediaTypeParam,
    isTypeOfSeries,
    isProgramAsset,
    isSeriesRecord,
    isOnGoingRecord,
    isCatchupSeries,
    isGroupedSeries,
    isRecordExpires,
    isDisplayRating,
    isFutureProgram,
    isProgramRecord,
    isShowPlayButton,
    isShowedOnlyInfo,
    isDisplayCartIcon,
    seasonEpisodeTitle,
    isContinueWatching,
    mosaicChannelTitle: mosaicProgram?.linear?.data?.title,
    isContentRestricted,
    isShowedProgressBar,
    isSuppressedEpisode,
    totalRecordedEpisodes,
    isFirstItemInHeroRail,
    isDisplayContentMarker,
    isShowFutureProgramInfo,
    isMosaicHeroRailProgram,
    isExternalProviderAsset,
    isAggregatedSeriesRecord,
    recordingExpirationInDays,
    lastWatchedSubLinearExternalId,
    isChannelAndAirTimeInfoCondition,
  };
}
