import { useEffect, useRef, useState } from 'react';

import { useCollectionGridSettings, useProviderLogo } from '@kaltura-ott/tvpil-shared';

import routes from 'routesPaths';
import { useErrorScreenContext } from 'context/error/error';
import { applyStyleHexToHSL, applyStyles } from 'utils';
import { COMPONENT_NAME } from '../../../constants';
import { GridSettingsFetchDataTypes } from '../../../types';
import { useGridMatchPath } from '../../../common/hooks/useGridMatchPath/useGridMatchPath';

function useCollectionGridHooksCombiner() {
  const { type, railId } = useGridMatchPath(routes.collectionGrid.path);
  const [, setForceUpdate] = useState(false); // For force updating of component
  const gridSettings = useCollectionGridSettings({ gridReferenceId: type!, railReferenceId: railId! });
  const providerLogo = useProviderLogo(gridSettings.settings?.providerName);
  const wrapperRef = useRef(null);
  const errorScreenContext = useErrorScreenContext();

  useEffect(() => {
    async function initGridPage() {
      const node = wrapperRef?.current;

      if (node && gridSettings.settings?.branding!) {
        applyStyles(node, gridSettings.settings.branding, false, true);
        applyStyleHexToHSL(node, gridSettings.settings.branding.backgroundColor);
      }
    }

    if (gridSettings && !gridSettings.error) {
      initGridPage();
    } else {
      errorScreenContext.setError({
        error: {
          titleId: `${COMPONENT_NAME}.errorTitle`,
          titleDefault: 'Rail not found',
          messageId: `${COMPONENT_NAME}.errorMessage`,
          messageDefault: 'Please check id',
        },
        goBackButton: {
          translationId: 'GeoError.playerButtonText',
          defaultMessage: 'Go Back',
        },
      });
    }
  }, [type, railId, gridSettings.isLoading]);

  return ({
    gridSettings: gridSettings.settings,
    isLoading: gridSettings.isLoading,
    wrapperRef,
    providerLogo,
    setForceUpdate,
  } as unknown) as GridSettingsFetchDataTypes;
}

export { useCollectionGridHooksCombiner };
