import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { useMaxiPlayerPosterHook } from './hooks/useMaxiPlayerPosterHook';

import styles from './MaxiPlayerPoster.module.scss';

const MaxiPlayerPoster = () => {
  const { isLogoPoster, isCanPlayFired, maxiPlayerPosterUrl } = useMaxiPlayerPosterHook();

  if (isCanPlayFired || !maxiPlayerPosterUrl) {
    return null;
  }

  return (
    <>
      <img
        className={cn(styles.maxiPlayerPoster, { [styles.logoPoster]: isLogoPoster })}
        alt='maxiPlayerPoster'
        src={maxiPlayerPosterUrl}
      />
      <div className={styles.overlay} />
    </>
  );
};

export default observer(MaxiPlayerPoster);
