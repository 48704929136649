import { useCallback } from 'react';

import { applyStyle } from 'utils';
import { Node } from 'types';

import { calculateCardZoom } from 'components/widgets/Card/helpers/calculationCardZoom/calculationCardZoom';

interface Props {
  setRef(value: any): void;
  itemWidth: number;
  imageHeight: number;
}

export const useHandleRef = ({ setRef, itemWidth, imageHeight }: Props) => {
  return useCallback((node: Node) => {
    if (node) {
      setRef(node);
      const parent = node.parentNode as HTMLElement;
      const { zoomToLeft, zoomToRight, zoomToTop } = calculateCardZoom({
        imageHeight,
        itemWidth,
      });

      applyStyle(parent, '--zoom-to-left', `${zoomToLeft}px`);
      applyStyle(parent, '--zoom-horizontal', `${zoomToLeft}px`);
      applyStyle(parent, '--zoom-to-right', `${zoomToRight}px`);
      applyStyle(parent, '--zoom-to-top', `-${zoomToTop}px`);
      applyStyle(parent, '--zoom-to-bottom', `-${zoomToTop}px`);
    }
  }, []);
};
