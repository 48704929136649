import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { useErrorScreenContext } from 'context/error/error';
import { useModalContext } from 'context/modal/modal';
import { getPerformanceAdditionalParams } from 'utils';
import { PerformanceEvents } from 'interface';
import { handleRouteUpdates } from 'components/widgets/Player/handlers';
import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { DescriptionWithAge } from 'components/widgets/Modal/PinCodeModal/DescriptionWithAge/DescriptionWithAge';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import UnPurchasedContentModal from 'components/widgets/Modal/UnPurchasedContentModal/UnPurchasedContentModal';
import { playerStorage, startOverInfoStorage } from 'components/widgets/Player/storages';
import { globalStorage } from 'services/globalStorage';
import { LAST_URL_PLAY_BACK } from 'consts';
import { useGoBack } from 'hooks';
import { useSetGeoError } from '../useSetGeoError/useSetGeoError';
import { useBlockContentResolver } from '../useBlockContentResolver/useBlockContentResolver';

export const useCheckRestrictions = () => {
  const [forceUpdate, setForceUpdate] = useState(false);
  const { openModal, closeModal } = useModalContext();
  const { setError, clearError } = useErrorScreenContext();
  const performanceEventsContext = usePerformanceEventsContext();
  const location = useLocation();
  const goBack = useGoBack();
  const setGeoError = useSetGeoError();
  const {
    isContentBlockedAsAdult,
    geoBlockingProperties,
    isContentRestricted,
    isPlaybackAllowed,
    parentalPinStore,
    parentalRating,
    isNotPurchased,
    isGeoBlocking,
    isPlayerError,
    asset,
  } = useBlockContentResolver();

  useEffect(() => {
    if (isContentRestricted) {
      const { assetId } = getPerformanceAdditionalParams();
      const onDecline = () => {
        goBack();
      };

      performanceEventsContext.setEndTime({
        value: PerformanceEvents.purchase,
        param1: assetId,
      });

      handleRouteUpdates(); // to update url during autoplay

      openModal(
        <PinCodeModal
          callback={(success: boolean) => {
            if (success) {
              // to update performance event start time if pin code modal appears
              performanceEventsContext.setStartTime(PerformanceEvents.playbackStart);
              setForceUpdate(!forceUpdate);
            }
          }}
          asset={asset!}
          onDecline={onDecline}
          description={
            !isContentBlockedAsAdult && parentalRating ? <DescriptionWithAge age={parentalRating} /> : undefined
          }
          type={
            isContentBlockedAsAdult ? PinCodeModalTypes.RestrictedContent : PinCodeModalTypes.RestrictedContentParental
          }
        />,
      );
    }

    if (isNotPurchased) {
      openModal(<UnPurchasedContentModal />);
    }

    if (!isPlaybackAllowed) {
      globalStorage.setPossiblePresentPlayer(false);
    }

    if (isGeoBlocking) {
      startOverInfoStorage.resetStartOverInfo();

      setGeoError();
      return;
    }

    return () => {
      // TO avoid calling the clearError and closeModal if url is not changed
      if (location.pathname !== sessionStorage.getItem(LAST_URL_PLAY_BACK)) {
        clearError();
        closeModal();
        playerStorage.clearIsPlayBackPausedAndSavedCurrentTime();
        globalStorage.clearIsPlayerError();
        sessionStorage.removeItem(LAST_URL_PLAY_BACK);
      }
    };
  }, [
    isContentBlockedAsAdult,
    isContentRestricted,
    isGeoBlocking,
    isNotPurchased,
    isPlaybackAllowed,
    performanceEventsContext,
    geoBlockingProperties,
    asset,
    parentalRating,
    openModal,
    closeModal,
    setError,
    clearError,
    parentalPinStore,
    isPlayerError,
  ]);

  return isPlaybackAllowed;
};
