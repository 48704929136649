import React from 'react';

import { appStore } from '@kaltura-ott/tvpil-shared';

import ConcurrencyModal from 'components/widgets/Player/components/ConcurrencyModal/ConcurrencyModal';
import {
  COMPONENT_NAME,
  CONCURRENCY_ERROR_CODE,
  CONCURRENCY_ICON_PATH,
  CUSTOM_PLAYER_EVENTS,
} from 'components/widgets/Player/constants';
import { concurrencyTtl } from 'components/widgets/Player/utils/concurrencyTtl/concurrencyTtl';

import { getConcurrencyIcon } from 'utils';
import { playerStorage, contextStorage, intlStorage } from 'components/widgets/Player/storages';

export async function concurrencyError(payload: any) {
  const { player, hideUI } = playerStorage.getPlayer();
  const intl = intlStorage.getIntl();
  const {
    modalContext: { openModal },
    toastContext: { showToast },
    routerContext: { goBack },
  } = contextStorage.getContext();
  const code = payload.data?.code || payload.result?.error?.code;

  if (code === CONCURRENCY_ERROR_CODE) {
    player.reset();
  }

  player.dispatchEvent({
    type: CUSTOM_PLAYER_EVENTS.CONCURRENCY_LIMIT,
    code,
  });

  if (hideUI) {
    const toastTtl = concurrencyTtl();

    showToast({
      title: intl.formatMessage({
        defaultMessage: 'Sorry, Too Many Streams',
        id: `${COMPONENT_NAME}.concurrencyToastTitle`,
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Playout is not possible because you reached the streaming limit in your plan',
        id: `${COMPONENT_NAME}.concurrencyToastDescription`,
      }),
      icon: `${appStore.staticAssetsUrl}${getConcurrencyIcon(CONCURRENCY_ICON_PATH)}`,
      toastTtl,
    });

    return;
  }

  await appStore.concurrencyStore.getConcurrencyDetails();
  openModal(<ConcurrencyModal hideUI={hideUI} goBack={goBack} />, { forbidOnRequestClose: true });
}
