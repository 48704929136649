import { SpecialWidgetTypeEnum, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { CollectionRailStoreV2, RailStoreV2 } from '@kaltura-ott/tvpil-shared';
import { MediaAsset } from '@kaltura-ott/tvpil-shared/lib/stores/entities/MediaAsset';

import {
  ADDITIONAL_HEIGHT,
  ADDITIONAL_EPISODE_PADDING,
  CARD_PADDING,
  INITIAL_OFFSET,
  TITLE_BLOCK_MARGIN,
  TITLE_SERIES_MARGIN,
  TITLE_SERIES_SIZE_MULTIPLIER,
} from '../../constants';

export function calculateItemHeight(railStore: RailStoreV2) {
  const {
    items,
    railHeight,
    subtype,
    uiDesignElements: { fontSize },
  } = railStore;
  const isLogoRail = (railStore as CollectionRailStoreV2).specialWidgetType === SpecialWidgetTypeEnum.logo;
  const titleBlockHeight = fontSize + TITLE_BLOCK_MARGIN;
  let componentHeight = railHeight;

  if (!isLogoRail) {
    componentHeight += ADDITIONAL_HEIGHT + titleBlockHeight + ADDITIONAL_EPISODE_PADDING;
  }

  if (
    subtype === UIDAPresentationTypeEnum.cw &&
    (items as MediaAsset[]).some((item) => {
      const { SeasonNumber, EpisodeNumber, EpisodeName } = item.data.metas;
      return SeasonNumber || EpisodeNumber || EpisodeName;
    })
  ) {
    componentHeight += fontSize * TITLE_SERIES_SIZE_MULTIPLIER + TITLE_SERIES_MARGIN;
  }

  return componentHeight;
}

export function calculateItemWidth(railStore: RailStoreV2, i: number) {
  const { items } = railStore;
  const image = railStore.itemImage(items[i].data);
  return image.width + CARD_PADDING;
}

export function calculateItemPositionX(railStore: RailStoreV2, index: number) {
  let positionX = 0;

  for (let i = 0; i < index; i += 1) {
    positionX += calculateItemWidth(railStore, i);
  }

  return Math.ceil(positionX) + INITIAL_OFFSET;
}

export function calculateTotalWidth(railStore: RailStoreV2) {
  const { items } = railStore;
  let width = 0;

  for (let i = 0; i < items.length; i += 1) {
    width += calculateItemWidth(railStore, i);
  }

  return Math.ceil(width) + INITIAL_OFFSET;
}
