import React from 'react';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

import warningIcon from 'assets/images/error.svg';

import styles from './NotAllowedPurchase.module.scss';

function NotAllowedPurchase({ componentName, className }: { componentName: string; className?: string }) {
  const defaultDescription =
    'On the O2 TV website in your device or in the O2 TV STB, you can choose and rent a selected movie from O2 Videotéka. In the rail “Rented movies” all rented movies will be displayed and you can play them';

  return (
    <div className={cn(styles.wrapper, className)}>
      <SVG src={warningIcon} />
      <FormattedMessage
        defaultMessage='Purchase is not allowed from this device'
        id={`${componentName}.NotAllowedPurchase.title`}
        tagName='h3'
      />
      <FormattedMessage
        defaultMessage={defaultDescription}
        id={`${componentName}.NotAllowedPurchase.description`}
        tagName='p'
      />
    </div>
  );
}

export default NotAllowedPurchase;
