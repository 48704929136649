import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { CollectionProgramAsset } from '@kaltura-ott/tvpil-shared';

import { InfoButton } from 'components/presenters/InfoButton/InfoButton';
import AirTimeV2 from 'components/presenters/AirTimeV2/AirTimeV2';
import { useCardBubbleInfoCombiner } from './hooks/useCardBubbleInfoCombiner/useCardBubbleInfoCombiner';
import CardTitle from '../CardTitle/CardTitle';
import { CardProgramChannelInfo } from '../CardProgramChannelInfo/CardProgramChannelInfo';

import styles from './CardBubbleInfo.module.scss';

function CardBubbleInfo() {
  const {
    handleClickInfo,
    ref,
    isChannelInfo,
    isShowedOnlyInfo,
    isAsPlayAsset,
    seasonEpisodeTitle,
    isChannelAndAirTimeInfoCondition,
    data,
    isShowAirTime,
  } = useCardBubbleInfoCombiner();

  return (
    <div
      ref={ref}
      data-type='cardBubbleInfo'
      onClick={handleClickInfo}
      role='presentation'
      className={cn(styles.wrapper, {
        [styles.notTransparent]: !isAsPlayAsset,
        [styles.fullWidth]: isShowedOnlyInfo,
      })}
    >
      <div>
        <CardTitle />
        {!isShowedOnlyInfo && (
          <>
            {!!seasonEpisodeTitle && <p className={styles.description}>{seasonEpisodeTitle}</p>}
            {isChannelAndAirTimeInfoCondition && (
              <div className={styles.channelWrapper}>
                {isChannelInfo && (
                  <div className={styles.channel}>
                    <CardProgramChannelInfo />
                  </div>
                )}
                {isShowAirTime && <AirTimeV2 className={styles.airTime} isInfo data={data as CollectionProgramAsset} />}
              </div>
            )}
            <InfoButton className={styles.infoButton} />
          </>
        )}
      </div>
    </div>
  );
}

export default observer(CardBubbleInfo);
