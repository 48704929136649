import { useRef } from 'react';

import { RentalAsset } from '@kaltura-ott/tvpil-shared/lib/rhino/data/rental/types';

import routes from 'routesPaths';
import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import { useMouseLeaveHandle } from 'components/widgets/CardV2/hooks/useMouseLeaveHandle/useMouseLeaveHandle';
import { useMouseEnterHandle } from 'components/widgets/CardV2/hooks/useMouseEnterHandle/useMouseEnterHandle';
import { useRentalsCardRef } from '../useRentalsCardRef';
import { useRentalsCardImages } from '../useRentalsCardImages';
import { useClickHandler } from '../useClickHandler/useClickHandler';
import { useHandleClickInfo } from '../useHandleClickInfo';
import { useRentalCardRating } from '../useRentalCardRating/useRentalCardRating';

interface Props {
  rentalItem: RentalAsset;
  itemWidth: number;
  imageHeight: number;
}

function useRentalsCard({ rentalItem, itemWidth, imageHeight }: Props) {
  const link = routes.vod.link(rentalItem.id, rentalItem.type);
  // using version v2 of the rail provider
  const {
    onHover,
    nextButtons,
    settings,
    settings: { shouldShowRating },
  } = useRailContext();
  // adults
  const isAdult = !!rentalItem?.isAdult;
  // refs
  const handleLinkRef = useRef(null);
  const { ref, handleRef } = useRentalsCardRef(itemWidth, imageHeight);
  // handlers
  const onMouseEnter = useMouseEnterHandle({ ref, itemWidth, imageHeight, isGrid: false, onHover, nextButtons });
  const onMouseLeave = useMouseLeaveHandle(onHover);
  const clickHandler = useClickHandler({
    data: rentalItem,
    isChannelHasNoProgram: false,
    mediaTypeParam: rentalItem.type,
    isPurchasable: false,
    adult: isAdult,
    imageHeight,
    link,
  });
  const { handleClickInfo } = useHandleClickInfo({
    id: rentalItem?.id!,
    externalId: undefined,
    mediaTypeParam: rentalItem.type,
    isChannelHasNoProgram: false,
  });
  // rating
  const { isDisplayRating, assetRating } = useRentalCardRating({ shouldShowRating, rentalItem });
  // images urls
  const { backgroundImageUrl, defaultImageUrl } = useRentalsCardImages({
    rentalItem,
    imageType: settings.imageType,
    imageWidth: settings.imageWidth!,
    imageHeight,
  });

  return {
    link,
    title: rentalItem.title,
    isAdult,
    handleRef,
    assetRating,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    handleLinkRef,
    handleClickInfo,
    isDisplayRating,
    defaultImageUrl,
    backgroundImageUrl,
  };
}

export { useRentalsCard };
