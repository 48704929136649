import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useConcurrencyStore, useSettingsStore } from 'hooks';
import routes from 'routesPaths';
import { findDevicesStore, findSubscriptionStore } from 'components/pages/Settings/utils';
import { ActionButtonTypesEnum } from 'interface';
import { COMPONENT_NAME } from '../constants';

export function useButtonsArray() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { settingsStore } = useSettingsStore();

  const concurrencyStore = useConcurrencyStore();

  const buttonsArray: object[] = [];
  if (concurrencyStore.concurrencyBuymore) {
    buttonsArray.push({
      onAccept: () => {
        navigate(routes.settings.link(findSubscriptionStore(settingsStore.children)!.link));
      },
      acceptButtonText: intl.formatMessage({
        defaultMessage: 'Buy More Streams',
        id: `${COMPONENT_NAME}.buttonBuyMore`,
      }),
      acceptButtonType: ActionButtonTypesEnum.primary,
    });
  }

  buttonsArray.push({
    onAccept: () => {
      navigate(routes.settings.link(findDevicesStore(settingsStore.children)!.link));
    },
    acceptButtonText: intl.formatMessage({
      defaultMessage: 'Manage Devices',
      id: `${COMPONENT_NAME}.buttonAccept`,
    }),
    acceptButtonType: concurrencyStore.concurrencyBuymore
      ? ActionButtonTypesEnum.secondary
      : ActionButtonTypesEnum.primary,
  });

  return buttonsArray;
}
