import { appStore } from '@kaltura-ott/tvpil-shared';

import { playerStorage, contextStorage, intlStorage, overlayStorage } from 'components/widgets/Player/storages';
import { getErrorContent } from 'components/widgets/Player/utils/getErrorContent/getErrorContent';
import { changeLocalState } from 'components/widgets/Player/utils/customEvents/customEventChangeLocalState';
import networkService from 'services/networkService';
import { getWarningIcon } from 'utils';
import { WARNING_ICON } from 'consts';
import { globalStorage } from 'services/globalStorage';
import { checkConnection } from '../checkConnection/checkConnection';
import { resetError } from '../resetError/resetError';
import { COMPONENT_NAME } from '../../../../constants';

const defaultErrorMessage = 'Playback Error';

export async function criticalError(payload: any) {
  const {
    toastContext: { showToast, clearToast },
    errorContext: { setError, clearError },
    routerContext: router,
  } = contextStorage.getContext();
  const { hideUI } = playerStorage.getPlayer();
  const intl = intlStorage.getIntl();
  const isConnection = await networkService.isNetworkConnection();
  const toastErrorCondition = isConnection && hideUI;
  const code = payload.data?.data?.messages?.[0].code || payload.result?.error?.code || payload.code;
  const goBackResolve = router.location.key !== 'default' ? router.navigate : undefined;
  const errorContent = getErrorContent({
    isConnection,
    checkConnection,
    handleRetry: resetError,
    code,
    goBack: goBackResolve,
  });

  globalStorage.setPossiblePresentPlayer(true);

  if (toastErrorCondition) {
    showToast({
      title: intl.formatMessage({
        defaultMessage: defaultErrorMessage,
        id: `${COMPONENT_NAME}.playerErrorToastTitle`,
      }),
      icon: `${appStore.staticAssetsUrl}${getWarningIcon(WARNING_ICON)}`,
    });
  } else {
    clearToast();
    clearError();
    changeLocalState({ isOpen: false });

    setError({ ...errorContent, playerErrorPayload: payload });
    overlayStorage.setShowErrorOverlay(true);
  }
}
