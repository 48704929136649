import React, { MouseEvent, useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

import checkIcon from 'assets/images/check.svg';

import { useLoginPageStore, useAppStore } from 'hooks';
import { LANGUAGE_TYPE } from 'consts';
import { applyStyle } from 'utils';
import { Node } from 'types';

import styles from './LanguagesList.module.scss';

interface LanguageProps {
  code: string;
  nativeName: string;
  isDefault: boolean;
  latinCode?: string;
}

interface Props {
  languagesHandle?(value: string, type?: string, callBack?: (value: string) => void): void;
  defaultValue: string;
  list: LanguageProps[];
  type: string;
  isDropDown?: boolean;
}

function LanguagesList({ defaultValue, list, isDropDown, type, languagesHandle }: Props) {
  const [language, setLanguage] = useState(defaultValue);
  const {
    appStore: {
      localizationStore: { setDisplayLanguage, setAudioLanguage, setSubTitleLanguage, languageNativeNameByCode },
    },
  } = useAppStore();

  const loginPageStore = useLoginPageStore();

  const handleRef = useCallback((node: Node) => {
    const { uiDesignElements } = loginPageStore;

    if (isDropDown && uiDesignElements && node !== null) {
      applyStyle(node, '--background-color', uiDesignElements.backgroundColor);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    setLanguage(defaultValue);
  }, [defaultValue, setLanguage]);

  function applyLanguage(code: string) {
    if (type === LANGUAGE_TYPE.DISPLAY) {
      setDisplayLanguage(code);
      return;
    }
    if (type === LANGUAGE_TYPE.AUDIO) {
      setAudioLanguage(code);
      return;
    }
    setSubTitleLanguage(code);
  }

  function selectLanguage(event: MouseEvent) {
    event.preventDefault();
    const element = event.currentTarget as HTMLInputElement;
    const { code = '' } = element.dataset;

    setLanguage(code);

    if (language === code) return;

    if (languagesHandle) {
      languagesHandle(code, type, applyLanguage);
      return;
    }

    applyLanguage(code);
  }

  return (
    <ul ref={handleRef} className={cn(isDropDown ? styles.listDropDown : styles.list)}>
      {list.map((lang: any) => (
        <li key={lang.code}>
          <button data-code={lang.code} onClick={selectLanguage} type='button'>
            <SVG className={cn(styles.checkIcon, { [styles.active]: lang.code === language })} src={checkIcon} />
            {languageNativeNameByCode(lang.code) || (
              <FormattedMessage defaultMessage='Language is not provided' id={`LanguagesList.empty.${type}`} />
            )}
          </button>
        </li>
      ))}
    </ul>
  );
}

export default LanguagesList;
