import React, { useCallback, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { HouseholdDevice, HouseholdDeviceRemoveErrorCodeEnum } from '@kaltura-ott/tvpil';

import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';
import { useAppStore, useDeviceStore, useSettingsStore, useConcurrencyStore, useRootStore } from 'hooks';
import { useModalContext } from 'context/modal/modal';
import { getConcurrencyIcon, applyStyles, DateFormatStyle, formatDate } from 'utils';
import routes from 'routesPaths';
import { Node } from 'types';
import DeviceRemovalFrequencyModal from '../DeviceRemovalFrequencyModal/DeviceRemovalFrequencyModal';

import styles from './DeleteDeviceModal.module.scss';

const CONCURRENCY_ICON_PATH = 'play-icon';

interface Props {
  device: HouseholdDevice;
  callback(): void;
}

function DeleteDeviceModal({ device, callback }: Props) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { removeHouseholdDevice, getDeviceList } = useDeviceStore();
  const {
    appStore: {
      localizationStore: { displayLanguage },
      staticAssetsUrl,
    },
  } = useAppStore();

  const rootStore = useRootStore();

  const concurrencyStore = useConcurrencyStore();

  const { settingsStore } = useSettingsStore();

  const modalContext = useModalContext();

  const intl = useIntl();

  const { udid, deviceBrandName, activatedOn, isStreaming, lastActivityTime } = device;
  const isLastActivityTime = !isStreaming && lastActivityTime;

  const handleRef = useCallback((node: Node) => {
    const store = location.pathname === routes.household.path ? rootStore : settingsStore;
    const { uiDesignElements } = store!;

    if (uiDesignElements && node !== null) {
      applyStyles(node, uiDesignElements);
    }
  }, []); // eslint-disable-line

  async function removeDevice() {
    setLoading(true);
    try {
      const result = await removeHouseholdDevice(udid);
      const code = (result.error?.code as any) as HouseholdDeviceRemoveErrorCodeEnum;
      modalContext.closeModal();

      if (result.success) {
        callback();
      } else if (code === HouseholdDeviceRemoveErrorCodeEnum.LimitationPeriod) {
        await concurrencyStore.getConcurrencyDetails();
        modalContext.openModal(<DeviceRemovalFrequencyModal />);
      }
    } catch (e) {
      /* eslint-disable no-console */
      console.error('Delete error');
    }
    setLoading(false);
  }

  return (
    <DefaultModal
      className={styles.deleteDeviceModal}
      buttonProps={{
        onAccept: removeDevice,
        acceptButtonText: intl.formatMessage({
          defaultMessage: 'Logout',
          id: 'DeviceList.DeleteDeviceModal.DefaultModal.Logout',
        }),
      }}
      forwardRef={handleRef}
      loading={loading}
      onDecline={() => getDeviceList()}
      title={intl.formatMessage({
        defaultMessage: 'Logout From Device',
        id: 'DeviceList.DeleteDeviceModal.DefaultModal.title',
      })}
    >
      <div>
        <FormattedMessage
          defaultMessage={
            isStreaming
              ? 'Are you sure you want to logout from this device?{br}Note that streaming will be stopped within a minute'
              : 'Are you sure you want to logout from this device?'
          }
          id={
            isStreaming
              ? 'DeviceList.DeleteDeviceModal.DefaultModal.description'
              : 'DeviceList.DeleteDeviceModal.DefaultModal.shortDescription'
          }
          tagName='p'
          values={{
            br: <br />,
          }}
        />

        <div className={styles.deviceRow}>
          {isStreaming && (
            <div>
              <SVG src={`${staticAssetsUrl}${getConcurrencyIcon(CONCURRENCY_ICON_PATH)}`} />
            </div>
          )}
          <h6 className={styles.device}>{deviceBrandName}</h6>
        </div>
        {isStreaming && (
          <FormattedMessage
            defaultMessage='Now Streaming'
            id='DeviceList.nowStreaming'
            tagName={(props: any) => <div {...props} className={styles.nowStreaming} />}
          />
        )}
        {isLastActivityTime && (
          <div className={styles.lastActive}>
            <FormattedMessage
              defaultMessage='Last active on {time}'
              id='DeviceList.lastActive'
              values={{
                time: formatDate(lastActivityTime, DateFormatStyle.WithFullYear, displayLanguage),
              }}
              tagName='span'
            />
          </div>
        )}
        <span className={styles.activated}>
          <FormattedMessage
            defaultMessage='Activated on {date}'
            id='DeviceList.DeleteDeviceModal.textInfo'
            values={{
              date: formatDate(activatedOn, DateFormatStyle.WithFullYear, displayLanguage),
            }}
          />
        </span>
      </div>
    </DefaultModal>
  );
}

export default DeleteDeviceModal;
