import React, { createContext, useCallback, useMemo, useContext, useState, useEffect, useRef } from 'react';

import {
  Connectivity,
  ConnectivityType,
  DeviceStatus,
  LowFrequencyHeartBeatEventArgs,
} from '@kaltura-ott/tvpil-analytics';
import { featureEnabler } from '@kaltura-ott/tvpil-shared';

import { useAppStore } from 'hooks/common/useAppStore';
import { HALF_DIVIDER, FEATURES } from 'consts';

interface Props {
  children: React.ReactNode;
}

interface PropsValue {
  status: DeviceStatus;
  backendConnectivity: Connectivity;
  connectivity: ConnectivityType;
}

interface EventMetrics {
  status?: DeviceStatus;
  backendConnectivity?: Connectivity;
}

export const defaultEvent = {
  status: DeviceStatus.up,
  backendConnectivity: Connectivity.online,
  connectivity: ConnectivityType.ott,
};

export interface ContextType {
  setMetrics(value: EventMetrics): void;
}

const HeartbeatContext = createContext<ContextType>({
  setMetrics: () => {},
});

const HeartbeatProvider = ({ children }: Props) => {
  const [eventData, setEventData] = useState(defaultEvent);
  const intervalId = useRef<number | undefined>();
  const timerId = useRef<number | undefined>();
  const [isReadyToUpdate, setIsReadyToUpdate] = useState(false);
  const [isMetricsChanged, setIsMetricsChanged] = useState(false);
  const { appStore } = useAppStore();
  const { apiError } = appStore;
  const period = appStore.dms?.analytics?.events?.lowFrequencyHeartbeat?.period;
  const minimumTimeBeforeUpdate = period && period / HALF_DIVIDER;
  const isAnalyticsEnabled = featureEnabler.variation(FEATURES.ANALYTICS, false);
  const isEnabled = isAnalyticsEnabled && period && period > 0;

  const { status, backendConnectivity } = eventData;

  function clearMinimumTimeBeforeUpdate() {
    setIsMetricsChanged(false);
    setIsReadyToUpdate(false);
    window.clearTimeout(timerId.current);
  }

  function clearAllTimers() {
    clearMinimumTimeBeforeUpdate();
    window.clearInterval(intervalId.current);
  }

  function setTimers() {
    let connectivityType = backendConnectivity;

    // if until next time period user still on 'erorr popup' send UNKNOWN
    if (backendConnectivity === Connectivity.offline) {
      connectivityType = Connectivity.unknown;
    }

    const heartbeatData = { ...eventData, backendConnectivity: connectivityType };

    intervalId.current = window.setInterval(() => {
      appStore.kuxAnalytics?.send(new LowFrequencyHeartBeatEventArgs(heartbeatData));
      clearMinimumTimeBeforeUpdate();
      timerId.current = window.setTimeout(() => setIsReadyToUpdate(true), minimumTimeBeforeUpdate);
    }, period);

    timerId.current = window.setTimeout(() => setIsReadyToUpdate(true), minimumTimeBeforeUpdate);
  }

  function sendEvent() {
    if (!isEnabled) {
      return;
    }

    clearAllTimers();
    appStore.kuxAnalytics?.send(new LowFrequencyHeartBeatEventArgs(eventData));
    // if event metrics doesn't change send the same event periodically (each dms period)
    setTimers();
  }

  useEffect(() => {
    // if metric was updated and less than dms period / 2 passed since last event we should send event exactly after dms period / 2
    if (!isReadyToUpdate && backendConnectivity !== Connectivity.unknown) {
      setIsMetricsChanged(true);
      return;
    }

    // to send event immediately if metric was updated and more than dms period / 2 passed since last event
    sendEvent();
  }, [status, backendConnectivity]); // eslint-disable-line

  useEffect(() => {
    if (isReadyToUpdate && isMetricsChanged) {
      sendEvent();
    }
  }, [isReadyToUpdate]); // eslint-disable-line

  useEffect(() => {
    if (!apiError) {
      setEventData((heartbeatData: PropsValue) => ({ ...heartbeatData, backendConnectivity: Connectivity.online }));
      return;
    }

    if (backendConnectivity === Connectivity.online) {
      // check any errors exclude 'no connection'
      if (!window.navigator.onLine) return;

      const { code } = apiError[Object.keys(apiError)[0]];

      const numCode = Number(code);
      const isServerError = !Number.isNaN(numCode) && ((numCode >= 500 && numCode < 600) || numCode === 408);

      if (isServerError) {
        setEventData((heartbeatData: PropsValue) => ({ ...heartbeatData, backendConnectivity: Connectivity.offline }));
      }
    }
  }, [apiError]); // eslint-disable-line

  useEffect(() => {
    // to send event after dms received
    sendEvent();

    return () => {
      clearAllTimers();
    };
  }, [isEnabled]); // eslint-disable-line

  const setMetrics = useCallback((metric: EventMetrics) => {
    setEventData((heartbeatData: PropsValue) => ({ ...heartbeatData, ...metric }));
  }, []); // eslint-disable-line

  const contextValue = useMemo(() => {
    return { setMetrics };
  }, [setMetrics]);

  return <HeartbeatContext.Provider value={contextValue}>{children}</HeartbeatContext.Provider>;
};

const useHeartbeatContext = () => useContext(HeartbeatContext);

export { HeartbeatContext, HeartbeatProvider, useHeartbeatContext };
