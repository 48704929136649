import React from 'react';

import { useEpgContext } from 'context/epgContext/epgContext';
import { useGridHooksCombiner } from '../../../Grid/hooks';
import TimeLine from './components/TimeLine/TimeLine';
import NowLine from './components/NowLine/NowLine';

import styles from './Time.module.scss';

function Time() {
  const {
    refTime,
    epgParams: { epgWidth },
  } = useEpgContext();

  const { onDragStart, onDragEnd, onDragMove } = useGridHooksCombiner();

  return (
    <div
      id='time'
      role='button'
      tabIndex={0}
      className={styles.wrapper}
      onMouseDownCapture={onDragStart}
      onMouseUp={onDragEnd}
      onMouseMove={onDragMove}
      onMouseUpCapture={onDragEnd}
    >
      <NowLine />
      <div ref={refTime} style={{ width: epgWidth }} role='presentation' className={styles.inner}>
        <TimeLine />
      </div>
    </div>
  );
}

export default Time;
