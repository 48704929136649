import { useEffect } from 'react';

import { useRootStore } from 'hooks';
import { LAST_VIEW_SEASON_NUMBER } from 'consts';

export const useAddidionalSeriesRailSetup = () => {
  const rootStore = useRootStore();
  const { detailsStore } = rootStore!;
  const { seriesAdditionalRail } = detailsStore;
  const isReady = seriesAdditionalRail?.isReady;
  const seasonNumber = seriesAdditionalRail?.seasonNumber;

  useEffect(() => {
    const seasonNumberSS = sessionStorage.getItem(LAST_VIEW_SEASON_NUMBER);

    async function setupSeasonNumber() {
      if (isReady && seasonNumberSS && seriesAdditionalRail && Number(seasonNumberSS) !== seasonNumber) {
        await seriesAdditionalRail.setupSeasonNumber(Number(seasonNumberSS));
      }
    }

    setupSeasonNumber();
  }, [seasonNumber, isReady, seriesAdditionalRail]);
};
