import { useEffect } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';

import { useIsNpvr } from 'hooks/common/useIsNpvr';
import routes from 'routesPaths';
import { useRecordRepository } from 'hooks/common/useRecordRepository';
import { useAppStore } from 'hooks/common/useAppStore';
import { LAST_URL } from 'consts';

// Pattern to match numbers in a URL
const numberPattern = /\d+/g;

/**
 * Hook created for checking closing (going back) of Recorded program
 * when on Player page recording was cancelled.
 * So when we are going back we should skip DP and go two steps back
 */
export const useAppNpvrHandler = () => {
  const location = useLocation();
  const {
    appStore: { isReady },
  } = useAppStore();
  const navigate = useNavigate();
  const recordRepository = useRecordRepository();
  const navType = useNavigationType();
  const isNpvr = useIsNpvr();

  useEffect(() => {
    // Check if current location is a 'Details' page
    const isCurrentPageDetails = location.pathname.includes(routes.details.commonPath);
    const splitUrl = location.pathname.split(routes.details.commonPath)[1];
    // Check if previous page was a 'Details' page (needs for case when user navigating in modals)
    const prevUrl = sessionStorage.getItem(LAST_URL) || '';
    const wasPrevPageDetails = prevUrl.includes(routes.details.commonPath);
    // Extract asset ID from URL
    const assetId = splitUrl?.match(numberPattern)?.join('');

    if (isNpvr && !wasPrevPageDetails && isCurrentPageDetails && assetId && isReady) {
      const isAssetRecorded = recordRepository.getSingleRecord(+assetId);

      if (!isAssetRecorded) {
        navigate(navType === 'POP' ? -1 : -2);
      }
    }
  }, [location.pathname, isReady]);
};
