import { useEffect, useRef, useState } from 'react';

import { useDetectLocation, useRecordRepository, useAppStore } from 'hooks';
import { useHandleMenuBtnClick } from './useHandleMenuBtnClick';
import { useHandleRecordBtnClick } from './useHandleRecordBtnClick';
import { getMessageForRecordBtn } from '../utils/translations';
import { BasicRecordButtonProps, useRecordButtonHooksCombinerOutputProps } from '../interface';
import { isActiveRecordIconCheck, isMultipleRecordIconCheck } from '../utils/utils';

function useRecordButtonHooksCombiner({
  assetId,
  seriesId,
  programNotAvailable,
  callback,
  enableCatchUp,
}: BasicRecordButtonProps) {
  const {
    appStore: { apiError },
  } = useAppStore();

  const recordRepository = useRecordRepository();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [pending, setPending] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const { isDetailsSeries } = useDetectLocation();

  const { handleMenuBtnClick } = useHandleMenuBtnClick({
    assetId,
    seriesId,
    programNotAvailable,
    callback,
    enableCatchUp,
    setPending,
    setIsMenuOpen,
    ref,
  });
  const { handleRecordBtnClick } = useHandleRecordBtnClick({
    assetId,
    seriesId,
    programNotAvailable,
    callback,
    enableCatchUp,
    setPending,
    isMenuOpen,
    setIsMenuOpen,
    ref,
  });

  const record = recordRepository.getSingleRecord(assetId);
  const seriesRecord = recordRepository.getSeriesRecord(String(seriesId));

  useEffect(() => {
    if (apiError) {
      setPending(false);
    }
  }, [apiError]);

  const { defaultMessage: actionBtnDefaultMessage, id: actionBtnId } = getMessageForRecordBtn(
    record!,
    seriesRecord!,
    isDetailsSeries,
  );
  const isActiveRecordIcon = isActiveRecordIconCheck(!!record, !!seriesRecord, isDetailsSeries);
  const isMultipleRecordIcon = isMultipleRecordIconCheck(seriesRecord!, isDetailsSeries);

  return {
    record,
    seriesRecord,
    pending,
    isMenuOpen,
    actionBtnDefaultMessage,
    isActiveRecordIcon,
    isMultipleRecordIcon,
    actionBtnId,
    ref,
    handleMenuBtnClick,
    handleRecordBtnClick,
  } as useRecordButtonHooksCombinerOutputProps;
}

export { useRecordButtonHooksCombiner };
