import { useCallback } from 'react';

import { applyStyle } from 'utils';
import { reCalculateCardZoom } from 'components/widgets/Card/helpers/calculationCardZoom/calculationCardZoom';

interface Props {
  ref: React.MutableRefObject<any>;
  itemWidth: number;
  imageHeight: number;
  isGrid: boolean;
  onHover?: (isHover: boolean, value: number) => void;
  nextButtons: React.MutableRefObject<any> | undefined;
}

export const useMouseEnterHandle = ({ ref, itemWidth, imageHeight, isGrid, onHover, nextButtons }: Props) => {
  return useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (!!onHover && !isGrid) {
      e.stopPropagation();
      const parent = ref.current.parentNode;
      const textBlockHeight = ref.current
        .closest('a')
        .style.getPropertyValue('--textBlockHeight')
        .replace(/[^0-9]/g, '');

      onHover(true, +textBlockHeight);

      const { zoomToLeft, zoomToRight } = reCalculateCardZoom({
        imageHeight,
        itemWidth,
        nextButtons,
        bounding: parent.getBoundingClientRect(),
      });

      if (zoomToLeft) {
        applyStyle(parent, '--zoom-to-left', `${zoomToLeft}px`);
      }
      if (zoomToRight) {
        applyStyle(parent, '--zoom-to-right', `${zoomToRight}px`);
      }
    }
  }, []);
};
