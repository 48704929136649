import { useCallback } from 'react';
import { Link, NavigateFunction } from 'react-router-dom';

import { ParentalPinStoreFacade } from '@kaltura-ott/tvpil-shared/lib/facades/parental-pin-store-facade';
import { ViewableEntitiesType, VodAsset } from '@kaltura-ott/tvpil-shared';

import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import { overlayStorage } from 'components/widgets/Player/storages';
import { ContextType } from 'context/modal/interface';
import { playProviderAsset } from 'utils';
import { useDetectLocation } from 'hooks';
import { CUSTOM_APP_EVENTS } from 'consts';
import { PerformanceContextType, PerformanceEvents } from 'interface';
import { handleClickDetailsButton } from '../handleClickDetailsButton/handleClickDetailsButton';

interface Props {
  asset: ViewableEntitiesType;
  performanceContext: PerformanceContextType;
  closeOverlay?: (() => void) | undefined;
  navigate: NavigateFunction;
  modalContext: ContextType;
  parentalPinStore: ParentalPinStoreFacade;
}

export const useHandleWatchResumeClick = ({
  asset,
  performanceContext,
  closeOverlay,
  navigate,
  modalContext,
  parentalPinStore,
}: Props) => {
  const { isLiveVideoPage } = useDetectLocation();

  return useCallback((e: React.MouseEvent<typeof Link>) => {
    const isOverlayOpen = overlayStorage.getShowOverlay();
    const { isExternalProviderAsset } = detailsStoreDataResolver(asset);

    if (isExternalProviderAsset) {
      playProviderAsset(e, asset as VodAsset, modalContext);
      return;
    }

    if (isLiveVideoPage) {
      document.dispatchEvent(new CustomEvent(CUSTOM_APP_EVENTS.SWITCH_TO_CATCH_UP, { detail: { value: asset } }));
    }

    if (isOverlayOpen) {
      performanceContext.setStartTime(PerformanceEvents.playbackResume);
    }

    handleClickDetailsButton({
      e,
      asset,
      isLive: isLiveVideoPage,
      closeOverlay,
      navigate,
      modalContext,
      parentalPinStore,
    });
  }, []);
};
