import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Img from 'components/presenters/Img/Img';
import { useCardCombiner } from './hooks/useCardCombiner/useCardCombiner';
import CardBubbleInfo from './components/CardBubbleInfo/CardBubbleInfo';
import CardTitle from './components/CardTitle/CardTitle';
import CardContent from './components/CardContent/CardContent';
import CardBodyContentRestrictedHook from './components/CardBodyContentRestrictedHook/CardBodyContentRestrictedHook';

import styles from './Card.module.scss';

function Card() {
  const {
    itemWidth,
    imageHeight,
    collectionType,
    data,
    itemImageUrl,
    defaultImage,
    link,
    handleRef,
    isNoDataWithNoLogoImg,
    onMouseEnter,
    clickHandler,
    onMouseLeave,
    handleLinkRef,
  } = useCardCombiner();

  return (
    <Link
      className={cn(styles.wrapper, styles[`type-${collectionType}`])}
      onClick={clickHandler}
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role='presentation'
      style={{
        minHeight: imageHeight,
        width: itemWidth,
      }}
      ref={handleLinkRef}
      to={link}
    >
      <div ref={handleRef} className={styles.inner}>
        <CardBodyContentRestrictedHook handleLinkRef={handleLinkRef} />
        <div className={styles.contentWrapper}>
          <div
            className={cn(styles.imageWrapper, {
              [styles.noDataWithDefaultImg]: isNoDataWithNoLogoImg,
            })}
          >
            {itemImageUrl && (
              <Img className={styles.defaultImg} alt={data?.title!} srcList={[itemImageUrl, defaultImage]} />
            )}
          </div>
          <div className={styles.content}>
            <CardContent />
          </div>
        </div>
        <CardBubbleInfo />
      </div>
      <CardTitle isStaticInfo />
    </Link>
  );
}

export default React.memo(Card);
