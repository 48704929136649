import { HouseholdMigrationErrorMessage, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { AppApiError, appStore, ErrorPriority } from '@kaltura-ott/tvpil-shared';

import { ApplicationError } from 'interface';
import {
  isLoginFormError,
  isAuthError,
  isAppInitError,
  isSelfServicesInitError,
  isSubscriptionsInitError,
  isEpgError,
  isServicesSelectError,
  isPlayoutV2InitError,
  isDetailsInitError,
  isRecordingTypeError,
  isSearchInvokeError,
  isParentalPinError,
} from './checkErrorActionName';

interface Props {
  error: AppApiError;
  isDetailsPage: boolean;
  isSearchPage: boolean;
  isFullPlayer: boolean;
  isNpvr: boolean;
  isEpg: boolean;
}

export const getApplicationErrorEventsAdditionalParams = ({
  error,
  isDetailsPage,
  isSearchPage,
  isFullPlayer,
  isNpvr,
  isEpg,
}: Props) => {
  const { type, actionName, message } = error;

  const isRecordingErrorType = isRecordingTypeError(actionName);
  const isDetailsInitErrorType = isDetailsInitError(actionName);
  const isLoginErrorType = isLoginFormError(actionName);
  const isAuthErrorType = isAuthError(actionName);
  const isAppInitErrorType = isAppInitError(actionName);
  const isSelfServiceInitErrorType = isSelfServicesInitError(actionName);
  const isSubscriptionsInitErrorType = isSubscriptionsInitError(actionName);
  const isEpgErrorType = isEpgError(actionName);
  const isServicesSelectErrorType = isServicesSelectError(actionName) && message !== HouseholdMigrationErrorMessage;
  const isPlayoutV2InitErrorType = isPlayoutV2InitError(actionName);
  const isCategoryTreeErrorType = type === ErrorPriority.high && isAppInitErrorType;
  const isSearchInvokeErrorType = isSearchInvokeError(actionName);
  const isParentalPinErrorType = isParentalPinError(actionName);

  let recordingOrDetailsContextType;
  let otherContextType;
  let additionalInfo;

  if (((!isEpg && !isSearchPage && !isNpvr) || isFullPlayer) && isDetailsInitErrorType) {
    recordingOrDetailsContextType = UIDAPresentationTypeEnum.applications;
  } else if ((isFullPlayer || isNpvr) && isRecordingErrorType) {
    recordingOrDetailsContextType = UIDAPresentationTypeEnum.npvr;
  } else if (isDetailsPage && isRecordingErrorType) {
    recordingOrDetailsContextType = UIDAPresentationTypeEnum.asset_details_page;
  } else if (isNpvr && isDetailsInitErrorType) {
    recordingOrDetailsContextType = UIDAPresentationTypeEnum.npvr;
  } else if (isSearchPage) {
    recordingOrDetailsContextType = UIDAPresentationTypeEnum.search;
  } else if (isEpg) {
    recordingOrDetailsContextType = UIDAPresentationTypeEnum.epg;
  }

  if (isLoginErrorType || isAuthErrorType) {
    otherContextType = UIDAPresentationTypeEnum.login;
    additionalInfo = appStore.userStore?.userData?.id;
  } else if (isCategoryTreeErrorType) {
    otherContextType = UIDAPresentationTypeEnum.applications;
    additionalInfo = ApplicationError.Boot;
  } else if (isSelfServiceInitErrorType) {
    otherContextType = UIDAPresentationTypeEnum.transaction_history;
    additionalInfo = ApplicationError.SelfServices;
  } else if (isSubscriptionsInitErrorType) {
    otherContextType = UIDAPresentationTypeEnum.subscription;
    additionalInfo = ApplicationError.Subscriptions;
  } else if (isEpgErrorType) {
    otherContextType = UIDAPresentationTypeEnum.epg;
    additionalInfo = ApplicationError.Epg;
  } else if (isServicesSelectErrorType) {
    otherContextType = UIDAPresentationTypeEnum.login;
    additionalInfo = ApplicationError.SelectServices;
  } else if (isPlayoutV2InitErrorType) {
    otherContextType = UIDAPresentationTypeEnum.applications;
    additionalInfo = ApplicationError.Playout;
  } else if (isRecordingErrorType) {
    additionalInfo = ApplicationError.Recording;
  } else if (isDetailsInitErrorType) {
    additionalInfo = isFullPlayer ? ApplicationError.Overlay : ApplicationError.Details;
  } else if (isSearchInvokeErrorType) {
    otherContextType = UIDAPresentationTypeEnum.search;
    additionalInfo = ApplicationError.Search;
  } else if (isParentalPinErrorType) {
    otherContextType = UIDAPresentationTypeEnum.parental_control;
    additionalInfo = ApplicationError.Pin;
  }

  return {
    recordingOrDetailsContextType,
    otherContextType,
    additionalInfo,
  };
};
