import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import {
  ContentMarker,
  ExpiresBadge,
  NewEpisodeBadge,
  PurchaseIcon,
  RentedBadge,
  FavoriteIcon,
} from 'components/presenters/icons';
import AirTime from 'components/presenters/AirTime/AirTime';
import PlayButton from 'components/widgets/buttons/PlayButton/PlayButton';

import airTimeStyles from 'components/presenters/AirTime/AirTime.module.scss';

import { AssetCardRatingInfo } from '../components/AssetCardRatingInfo/AssetCardRatingInfo';
import { AssetCardProgramChannelInfo, AssetCardProgressBar } from '../components';
import { useAssetCardContext } from '../AssetCardContext/AssetCardContext';

import styles from './AssetCardContent.module.scss';
import railCommonStyles from '../AssetCardBody/AssetCardBody.module.scss';

const COMPONENT_NAME = 'Rail.RailCard';

function AssetCardContent() {
  const {
    listStore,
    resolvedProps: {
      isLinearAsset,
      isNoData,
      isFutureProgram,
      isProgramAsset,
      isDisplayCartIcon,
      contentMarker,
      isDisplayContentMarker,
      isNewEpisode,
      isRented,
      isShowFutureProgramInfo,
      isContentRestricted,
      catchUpAirDate,
      isHero,
      isPurchasable,
      isRecordExpires,
      recordingExpirationInDays,
      isShowPlayButton,
      isShowedProgressBar,
      linearId,
      isOnGoingRecord,
      isSeriesRecord,
    },
  } = useAssetCardContext();

  const isForceOverlayShowing = isOnGoingRecord && !isSeriesRecord;
  return (
    <div
      className={cn(
        styles.inner,
        { [styles.programAsset]: isProgramAsset },
        { [styles.futureProgram]: isFutureProgram },
        { [styles.linearAsset]: isLinearAsset },
        { [railCommonStyles.noData]: isNoData },
      )}
    >
      {isDisplayCartIcon && <PurchaseIcon className={styles.cart} isBadge />}
      {isDisplayContentMarker && <ContentMarker className={styles.contentMarker} contentMarker={contentMarker!} />}
      {isNewEpisode && <NewEpisodeBadge translationId={COMPONENT_NAME} className={styles.newEpisodeBadge} />}
      {isRented && <RentedBadge className={styles.rentedBadge} translationId={COMPONENT_NAME} />}
      {!isContentRestricted && (
        <>
          {(isShowFutureProgramInfo || isForceOverlayShowing) && (
            <div data-type='futureProgram' className={airTimeStyles.futureProgram}>
              <AirTime className={styles.airTime} catchUpAirDate={catchUpAirDate} showNowLabel={isOnGoingRecord} />
            </div>
          )}

          <AssetCardRatingInfo translationId={COMPONENT_NAME} />

          {isShowPlayButton && <PlayButton className={styles.playBtn} />}
          {isShowedProgressBar && <AssetCardProgressBar />}

          {isRecordExpires && (
            <ExpiresBadge
              className={styles.expires}
              translationId={COMPONENT_NAME}
              expiredInDays={recordingExpirationInDays!}
            />
          )}
        </>
      )}
      {isHero && (
        <div className={styles.channelLarge}>
          <AssetCardProgramChannelInfo />
          <FavoriteIcon
            className={styles.favoriteChannel}
            isPurchasable={isPurchasable}
            linearId={linearId}
            presentOnRail={(listStore as RailStoreV2).presentFavoriteIndicator}
          />
        </div>
      )}
    </div>
  );
}

export default observer(AssetCardContent);
