import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { EntityRating } from '@kaltura-ott/tvpil-shared';

import PlayButton from 'components/widgets/buttons/PlayButton/PlayButton';
import { Rating, RentedBadge } from 'components/presenters/icons';
import { useContentRestriction } from '../RentalsCard/hooks/useContentRestriction';

import styles from './RentalsCardContent.module.scss';

function RentalsCardContent({
  shouldDisplayPlayButton,
  isDisplayRating,
  assetRating,
  isAdult,
}: {
  assetRating: EntityRating;
  shouldDisplayPlayButton: boolean;
  isDisplayRating: boolean;
  isAdult: boolean;
}) {
  // for avoiding wrapping the whole parent component with observer
  const isContentRestricted = useContentRestriction(isAdult);

  return (
    <div className={cn(styles.inner, styles.programAsset)}>
      <RentedBadge className={styles.rentedBadge} translationId='Rail.RailCard' />
      {!isContentRestricted && (
        <>
          {isDisplayRating && (
            <div data-type='ratingInfo' className={styles.ratingInfoRow}>
              <Rating className={styles.rating} rating={assetRating} />
            </div>
          )}
          {shouldDisplayPlayButton && <PlayButton data-type='playButton' className={styles.playBtn} />}
        </>
      )}
    </div>
  );
}

export default observer(RentalsCardContent);
