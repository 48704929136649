import React from 'react';

import { MosaicInfoTag } from '@kaltura-ott/tvpil';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { useAppStore } from 'hooks';
import { playoutStoreDataResolver } from 'utils';
import { changeLocalState } from 'components/widgets/Player/utils/customEvents/customEventChangeLocalState';
import { startOverInfoStorage } from 'components/widgets/Player/storages';
import { StreamsList } from 'components/presenters/StreamsList/StreamsList';

import styles from './MosaicStreams.module.scss';

const MosaicStreams = () => {
  const {
    appStore: { playoutStoreV2 },
  } = useAppStore();
  const { externalId: activeStreamId, mosaicInfo, type } = playoutStoreDataResolver();

  function hideStreams() {
    changeLocalState({ isShowStreams: false });
  }

  if (!mosaicInfo?.mosaicChannelsInfo?.length) {
    hideStreams();
  }

  const handleChooseStream = (index: number) => {
    return async () => {
      const { channelExternalId, programExternalId } = mosaicInfo!.mosaicChannelsInfo![index];
      let externalId;
      let playoutType;

      if (type === PlayoutStoreV2Type.CATCHUP) {
        externalId = programExternalId;
        playoutType = PlayoutStoreV2Type.CATCHUP;
      } else {
        externalId = channelExternalId;
        playoutType = PlayoutStoreV2Type.LIVE;
      }

      startOverInfoStorage.setStartOver(false, false);

      hideStreams();

      await playoutStoreV2.init({
        autoplayNextProgram: true,
        externalId,
        type: playoutType,
        saveLastWatched: true,
      });
    };
  };

  return (
    <div className={styles.wrapper}>
      <StreamsList
        activeStreamId={activeStreamId}
        chooseStream={handleChooseStream}
        mosaicInfo={mosaicInfo as MosaicInfoTag}
        translationId='Player.MosaicStreams'
      />
      <button aria-label='close' className={styles.buttonClose} onClick={hideStreams} type='button' />
    </div>
  );
};

export default MosaicStreams;
