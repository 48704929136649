import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useParentalControlContext } from 'context/parentalControlContext/parentalControlContext';
import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { FULL_COMPONENT_NAME, typePin } from '../../constans';

import styles from '../../ParentalControl.module.scss';

const PurchaseProtection = () => {
  const { onChangePin } = useParentalControlContext();

  return (
    <>
      <p className={styles.name}>
        <FormattedMessage defaultMessage='Purchase Protection' id={`${FULL_COMPONENT_NAME}.textProtection`} />
      </p>
      <div className={styles.block}>
        <ActionButton className={styles.actionButton} onClick={onChangePin(typePin.purchase)}>
          <FormattedMessage defaultMessage='Change Purchase PIN' id={`${FULL_COMPONENT_NAME}.buttonChangePurchase`} />
        </ActionButton>
      </div>
    </>
  );
};

export default React.memo(PurchaseProtection);
