import React from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Rating } from 'components/presenters/icons';
import { useAssetCardContext } from '../../AssetCardContext/AssetCardContext';

import styles from './AssetCardRatingInfo.module.scss';

interface Props {
  translationId: string;
}

export function AssetCardRatingInfo({ translationId }: Props) {
  const intl = useIntl();
  const {
    resolvedProps: { isDisplayRating, isAggregatedSeriesRecord, rating, totalRecordedEpisodes },
  } = useAssetCardContext();

  return (
    <div
      data-type='ratingInfo'
      className={cn(styles.ratingInfoRow, { [styles.withRecordInfo]: isAggregatedSeriesRecord })}
    >
      {isDisplayRating && <Rating className={styles.rating} rating={rating} />}
      {isAggregatedSeriesRecord && (
        <span className={styles.recordInfo}>
          <FormattedMessage
            defaultMessage='{episodesCount, plural, one {# Episode Recorded} other {# Episodes Recorded}}'
            id={`${translationId}.recordedEpisodes.${intl.formatPlural(totalRecordedEpisodes!)}`}
            values={{ episodesCount: totalRecordedEpisodes }}
          />
        </span>
      )}
    </div>
  );
}
