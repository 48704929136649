import React from 'react';
import cn from 'classnames';

import Title from '../Title/Title';
import NextButtons from '../NextButtons/NextButtons';
import RailCardsWrapper from '../RailCardsWrapper/RailCardsWrapper';

import { useRailViewCombiner } from './hooks/useRailViewCombiner/useRailViewCombiner';

import styles from './RailView.module.scss';

const RailView = () => {
  const {
    handleRef,
    wrapperRef,
    headerRef,
    refElementInner,
    isLogoRail,
    componentHeight,
    checkResize,
    railSettings,
    railType,
    totalCount,
    itemsLength,
    hasNextPage,
  } = useRailViewCombiner();

  return (
    <div ref={handleRef} className={cn(styles.rail, { [styles.logo]: isLogoRail })}>
      <div ref={refElementInner}>
        <Title
          hasNextPage={hasNextPage}
          totalCount={totalCount}
          itemsLength={itemsLength}
          railType={railType}
          forwardHeaderRef={headerRef}
          isLogoRail={isLogoRail}
          railSettings={railSettings}
        />
        <div
          ref={wrapperRef}
          style={{
            height: componentHeight,
          }}
        >
          <RailCardsWrapper checkResize={checkResize} />
          <NextButtons />
        </div>
      </div>
    </div>
  );
};

export default RailView;
