import React from 'react';
import cn from 'classnames';

import Buttons from '../Buttons/Buttons';
import PlayerView from '../PlayerView/PlayerView';
import DescriptionView from '../DescriptionView/DescriptionView';
import { useVideoPlayoutContext } from '../VideoPlayoutProvider/VideoPlayoutProvider';

import styles from './VideoContent.module.scss';

const VideoContent: React.FC = () => {
  const { isContentGeoBlocking, isContentRestricted } = useVideoPlayoutContext();
  const isLocked = isContentGeoBlocking || isContentRestricted;

  return (
    <>
      <PlayerView />
      <div className={cn(styles.content, { [styles.locked]: isLocked })}>
        <DescriptionView />
        <Buttons />
      </div>
    </>
  );
};

export default VideoContent;
