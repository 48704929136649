import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import { CatchUpAirDate } from '@kaltura-ott/tvpil-shared';

import { useAppStore } from 'hooks';
import { DateFormatStyle, formatDate } from 'utils';
import LocalTime from 'components/presenters/LocalTime/LocalTime';

import styles from './AirTime.module.scss';

export type Props = {
  catchUpAirDate: CatchUpAirDate;
  className?: string;
  isInfo?: boolean;
  showNowLabel?: boolean;
};

function AirTime({ showNowLabel, catchUpAirDate, className = '', isInfo = false }: Props) {
  const {
    appStore: {
      localizationStore: { displayLanguage, latinLanguage },
    },
  } = useAppStore();
  const locale = latinLanguage || displayLanguage;
  const { startDate, specialLabel } = catchUpAirDate;

  const intl = useIntl();

  const nowText = intl.formatMessage({
    id: 'Rail.RailCard.AirTime.Now',
    defaultMessage: 'Now',
  });

  if (!startDate || Number.isNaN(Number(startDate))) return null;
  return (
    <div className={cn(styles.airTime, className)}>
      {specialLabel && (
        <>
          <FormattedMessage
            defaultMessage={`${specialLabel}`}
            id={`Rail.RailCard.AirTime.${specialLabel}`}
            tagName='span'
          />{' '}
          <LocalTime date={startDate} />
        </>
      )}
      {!specialLabel && startDate > Date.now() && !isInfo && (
        <>
          <span>{formatDate(startDate, DateFormatStyle.OnlyLongWeekday, locale)}</span>
          <span>{formatDate(startDate, DateFormatStyle.WithoutWeekday, locale)}</span>
        </>
      )}
      {!specialLabel && (startDate < Date.now() || isInfo) && (
        <>
          <span>{showNowLabel ? nowText : formatDate(startDate, DateFormatStyle.WithLongWeekday, locale)}</span>
          <LocalTime date={startDate} />
        </>
      )}
    </div>
  );
}

export default AirTime;
