import { HALF_DIVIDER } from 'consts';
import { NEXT_BUTTON_WIDTH } from '../../consts';

export const HEIGHT_GROWTH = 32;
export const PADDING = 5;

interface ZoomParams {
  bounding?: DOMRect;
  imageHeight: number;
  itemWidth: number;
  nextButtons?: any;
}

export function calculateCardZoom({ imageHeight, itemWidth }: ZoomParams) {
  const ratio = itemWidth / imageHeight;
  const widthGrowth = (imageHeight + HEIGHT_GROWTH) * ratio - itemWidth;

  // calculate default zoom
  const zoomToLeft = (widthGrowth / HALF_DIVIDER) * -1;
  const zoomToRight = zoomToLeft;
  const zoomToTop = HEIGHT_GROWTH / HALF_DIVIDER;

  return { zoomToLeft, zoomToRight, zoomToTop };
}

export function reCalculateCardZoom({ bounding, nextButtons, imageHeight, itemWidth }: ZoomParams) {
  const { isLeftNextButton, isRightNextButton } = nextButtons.current;
  const { clientWidth } = document.body;
  const nextLeftButtonWidth = isLeftNextButton ? NEXT_BUTTON_WIDTH : 0;
  const ratio = itemWidth / imageHeight;
  const rightNextButtonWidth = isRightNextButton ? NEXT_BUTTON_WIDTH : 0;
  const widthGrowth = (imageHeight + HEIGHT_GROWTH) * ratio - itemWidth;

  // calculate default zoom
  let zoomToLeft = widthGrowth / HALF_DIVIDER;
  let zoomToRight = widthGrowth / HALF_DIVIDER;

  // change default zoom if after hover part of element will be out of screen
  const leftOverFlow = zoomToLeft + PADDING + nextLeftButtonWidth - bounding?.left!;
  const rightOverFlow = bounding?.right! + zoomToRight + PADDING + rightNextButtonWidth - clientWidth;

  if (leftOverFlow > 0) {
    zoomToLeft = leftOverFlow - zoomToLeft;
    zoomToRight = (leftOverFlow + zoomToRight) * -1;
  } else if (rightOverFlow > 0) {
    zoomToLeft = (rightOverFlow + zoomToLeft) * -1;
    zoomToRight = rightOverFlow - zoomToRight;
  } else {
    zoomToLeft = (widthGrowth / HALF_DIVIDER) * -1;
    zoomToRight = zoomToLeft;
  }

  return { zoomToLeft, zoomToRight };
}
