import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { MasonryScroller, useContainerPosition, usePositioner, useResizeObserver } from 'masonic';
import cn from 'classnames';

import { RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { useRailSkeletonSizer } from 'hooks';
import { checkTotalGridHeight } from '../utils';
import { useWidthForMasonryWrapper, useGridItems } from './hooks';
import RailProvider from '../RailHorisontal/components/RailProvider/RailProvider';
import RailGridInitializer from './components/RailGridInitializer/RailGridInitializer';
import RailGridResolveRendering from './components/RailGridResolveRendering/RailGridResolveRendering';

import styles from './RailGrid.module.scss';

interface Props {
  className?: string;
  store: RailStoreV2;
  isRecordPage?: boolean;
}

function RailGrid({ store, className, isRecordPage = false }: Props) {
  const { columnCount, windowWidth, windowHeight, columnWidth, defaultHeight } = useRailSkeletonSizer(store);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { offset, width } = useContainerPosition(containerRef, [windowWidth, windowHeight]);
  const { gridItems, items } = useGridItems({ store, columnCount });
  const widthForMasonryWrapper = useWidthForMasonryWrapper({ width, columnCount, columnWidth });
  const { lastPageWasLoaded } = (store as RailStoreV2 | any) || {};
  // Workaround for records page
  // due to data invalidation each time it is necessary to change the recalculation of the position
  const resolveDependency = isRecordPage ? store : store.items;

  // masonic does not allow to remove items
  // to not count skeleton items height recalculate grid height if all items have been loaded
  checkTotalGridHeight(containerRef, lastPageWasLoaded, items.length, columnCount, defaultHeight);

  const totalGridHeight = Math.ceil(items.length / columnCount) * defaultHeight;

  const positioner = usePositioner(
    {
      width: widthForMasonryWrapper,
      columnWidth,
    },
    /**
     * store dependency is needed here to re-initiate masonry for the case of the same search string
     * and new rail store entity (items array will become empty in this case)
     */
    [resolveDependency],
  );
  const resizeObserver = useResizeObserver(positioner);
  return (
    <RailProvider store={store} isGrid>
      <RailGridInitializer store={store} />
      <div className={cn(styles.wrapper, className)}>
        <MasonryScroller
          className={cn(styles.grid, {
            [styles.loaded]: lastPageWasLoaded,
            [styles.recordsLoaded]: isRecordPage && lastPageWasLoaded,
          })}
          containerRef={containerRef}
          height={lastPageWasLoaded ? totalGridHeight : windowHeight}
          items={gridItems}
          itemStyle={{ width: columnWidth, height: defaultHeight }}
          offset={offset}
          positioner={positioner}
          render={RailGridResolveRendering}
          resizeObserver={resizeObserver}
          itemKey={(data, index) => data?.id || index}
          overscanBy={3}
          itemHeightEstimate={defaultHeight}
        />
      </div>
    </RailProvider>
  );
}

export default observer(RailGrid);
