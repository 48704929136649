import { SeriesRecord, Record } from '@kaltura-ott/tvpil-shared';

import {
  isSingleRecord,
  isRecordedAsSeries as isRecordedAsSeriesFunc,
  isNotRecorded,
  isOnlyCancelSeriesCondition,
} from './utils';

export const COMPONENT_NAME_FOR_TRANSLATION = 'RecordButton';

export function getCancelRecordBtnMessage(record: Record, seriesRecord: SeriesRecord) {
  const isRecordedAsSeries = isRecordedAsSeriesFunc(record, seriesRecord);
  const isRecordedAsSingle = isSingleRecord(record) && !seriesRecord;

  if (isRecordedAsSeries || isRecordedAsSingle) {
    return {
      defaultMessage: 'Cancel',
      id: `${COMPONENT_NAME_FOR_TRANSLATION}.Cancel`,
    };
  }

  return {
    defaultMessage: 'Cancel Episode',
    id: `${COMPONENT_NAME_FOR_TRANSLATION}.CancelEpisode`,
  };
}

export function getMessageForRecordBtn(record: Record, seriesRecord: SeriesRecord, isDetailsSeries: boolean) {
  const cancelSeriesDefaultMessage = 'Cancel Series';
  const cancelSeriesId = `${COMPONENT_NAME_FOR_TRANSLATION}.CancelSeries`;

  if (isDetailsSeries) {
    return {
      defaultMessage: seriesRecord ? cancelSeriesDefaultMessage : 'Record Series',
      id: seriesRecord ? cancelSeriesId : `${COMPONENT_NAME_FOR_TRANSLATION}.RecordSeries`,
    };
  }

  if (isOnlyCancelSeriesCondition(record, seriesRecord, isDetailsSeries)) {
    return {
      defaultMessage: cancelSeriesDefaultMessage,
      id: cancelSeriesId,
    };
  }

  const cancelRecordBtnMessage = getCancelRecordBtnMessage(record, seriesRecord);

  return {
    defaultMessage: record ? cancelRecordBtnMessage.defaultMessage : 'Record',
    id: isNotRecorded(record, seriesRecord) ? `${COMPONENT_NAME_FOR_TRANSLATION}.Record` : cancelRecordBtnMessage.id,
  };
}
