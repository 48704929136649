import React, { MouseEvent, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useIntl, FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

import { HouseholdDevice } from '@kaltura-ott/tvpil';
import { featureEnabler } from '@kaltura-ott/tvpil-shared';

import { useAppStore, useDeviceStore, useToastMessage } from 'hooks';
import { useModalContext } from 'context/modal/modal';
import routes from 'routesPaths';
import { FEATURES } from 'consts';
import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { DateFormatStyle, formatDate, getConcurrencyIcon } from 'utils';
import { PerformanceEvents } from 'interface';
import trashIcon from 'assets/images/trash.svg';
import DeleteDeviceModal from './DeleteDeviceModal/DeleteDeviceModal';

import styles from './DeviceList.module.scss';

const CONCURRENCY_ICON_PATH = 'play-icon';
const COMPONENT_NAME = 'DeviceList';

const DeviceList: React.FC<{ center?: boolean }> = ({ center = false }) => {
  const { householdList, addDevice } = useDeviceStore();
  const {
    appStore: {
      localizationStore: { displayLanguage },
      params,
      staticAssetsUrl,
    },
    appStore,
  } = useAppStore();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const modalContext = useModalContext();
  const performanceEventsContext = usePerformanceEventsContext();
  const skipPerformanceEvent = featureEnabler.variation(FEATURES.LOGIN_FORM, false);

  useEffect(() => {
    if (skipPerformanceEvent) return;

    if (location.pathname === routes.household.path) {
      performanceEventsContext.setEndTime({ value: PerformanceEvents.appStart });
    }
  }, []);

  useToastMessage(COMPONENT_NAME);

  function openModal(e: MouseEvent) {
    const element = e.currentTarget as HTMLInputElement;
    const { udid } = element.dataset;

    const device = householdList.find((item) => item.udid === udid);

    if (device) {
      modalContext.openModal(
        <DeleteDeviceModal
          callback={async () => {
            if (location.pathname === routes.household.path) {
              const success = await addDevice();
              if (success) {
                navigate(routes.home.path, { replace: true });
              }
            } else if (udid === params?.udid) {
              // logout done in tvpil
              await appStore.rebootApp();
              navigate(routes.home.path);
            }
          }}
          device={device}
        />,
        { forbidOnRequestClose: true },
      );
    }
  }

  return (
    <div className={styles.list}>
      {householdList.map(
        ({ activatedOn, deviceBrandName, name, udid, isStreaming, lastActivityTime }: HouseholdDevice) => (
          <button
            key={udid}
            className={cn(styles.device, { [styles.currentDevice]: udid === params?.udid, [styles.center]: center })}
            data-udid={udid}
            onClick={openModal}
            title={intl.formatMessage({ id: `${COMPONENT_NAME}.buttonRemoveDevice`, defaultMessage: 'Remove device?' })}
            type='button'
          >
            <div className={styles.deviceInfo}>
              {isStreaming && (
                <div>
                  <SVG src={`${staticAssetsUrl}${getConcurrencyIcon(CONCURRENCY_ICON_PATH)}`} />
                </div>
              )}
              <div>
                {deviceBrandName || name}
                <div className={styles.itemActivated}>
                  {isStreaming && (
                    <FormattedMessage
                      defaultMessage='Now Streaming'
                      id={`${COMPONENT_NAME}.nowStreaming`}
                      tagName={(props: any) => <div {...props} className={styles.nowStreaming} />}
                    />
                  )}
                  {!isStreaming && lastActivityTime && (
                    <FormattedMessage
                      defaultMessage='Last active on {time}'
                      id={`${COMPONENT_NAME}.lastActive`}
                      values={{
                        time: formatDate(lastActivityTime, DateFormatStyle.WithFullYear, displayLanguage),
                      }}
                      tagName='div'
                    />
                  )}
                  <FormattedMessage
                    defaultMessage='Activated on {time}'
                    id={`${COMPONENT_NAME}.activatedText`}
                    values={{
                      time: formatDate(activatedOn, DateFormatStyle.WithFullYear, displayLanguage),
                    }}
                    tagName='div'
                  />
                </div>
              </div>
            </div>
            <SVG className={styles.itemDeleteIcon} src={trashIcon} />
          </button>
        ),
      )}
    </div>
  );
};

export default observer(DeviceList);
