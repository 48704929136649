import { useLocation, useParams } from 'react-router-dom';

import { MediaType } from '@kaltura-ott/tvpil';

import { FUTURE_RECORDINGS, RECORDINGS } from 'consts';
import { getNormalizeMediaType } from 'utils';
import routes from 'routesPaths';
import { useSearchParam } from './useSearchParam';

function useIsNpvr() {
  const location = useLocation();
  const { programId, mediaType: mediaTypeParam } = useParams<{ programId: string; mediaType: MediaType }>();
  const mediaType = mediaTypeParam && getNormalizeMediaType(mediaTypeParam);
  const isFutureNpvr = useSearchParam('type') === FUTURE_RECORDINGS;
  const isNpvr =
    useSearchParam('type') === RECORDINGS ||
    isFutureNpvr ||
    !!(programId && mediaType && location.pathname === routes.program.link(programId, mediaType.toLowerCase()));

  return isNpvr;
}

export { useIsNpvr };
