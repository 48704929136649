import React from 'react';
import { observer } from 'mobx-react';

import { useHeadingLinksCombiner } from '../../hooks/useHeadingLinksCombiner/useHeadingLinksCombiner';
import MenuItems from '../MenuItems/MenuItems';
import Logo from '../Logo/Logo';

import styles from './HeadingLinks.module.scss';

function HeadingLinks() {
  const {
    logo,
    items,
    isLogo,
    logoSource,
    isNpvrEnabled,
    isOnBoardingView,
    isOnBoardingWihoutLogo,
  } = useHeadingLinksCombiner();

  if (isOnBoardingWihoutLogo) {
    return null;
  }

  return (
    <nav className={styles.nav}>
      {isLogo && <Logo key={logo?.data.linkItem?.id} className={styles.logo} image={logoSource} />}
      {!isOnBoardingView && <MenuItems items={items} isNpvrEnabled={isNpvrEnabled} />}
    </nav>
  );
}

export default observer(HeadingLinks);
