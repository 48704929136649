import React from 'react';

import { Channel as ChannelType } from '@kaltura-ott/tvpil-shared';

import { FavoriteIcon, PurchaseIcon } from 'components/presenters/icons';
import { useEpgContext } from 'context/epgContext/epgContext';
import { useChannelCombiner } from './hooks';

import styles from './Channel.module.scss';

interface Props {
  channel: ChannelType;
}

function Channel({ channel }: Props) {
  const { title, channelNumber, id, isEntitled } = channel;
  const { settings } = useEpgContext();
  // getting channel image by ratio and type
  const { originImage } = useChannelCombiner(channel, {
    channelIconRatio: settings?.channelIconRatio!,
    channelIconType: settings?.channelIconType,
  });

  return (
    <li className={styles.item}>
      {!isEntitled && <PurchaseIcon className={styles.purchase} />}
      {isEntitled && !!channelNumber && <span className={styles.number}>{channelNumber}</span>}
      {originImage?.url ? (
        <img alt={title} className={styles.logo} src={originImage.url} />
      ) : (
        <span className={styles.name}>{title}</span>
      )}
      <FavoriteIcon
        className={styles.favorite}
        isPurchasable={!isEntitled}
        linearId={id}
        presentOnRail={Boolean(settings?.shouldShowFavoriteIndicator)}
      />
    </li>
  );
}

export default React.memo(Channel);
