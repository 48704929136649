import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { useMaxiPlayerPosterHook } from './hooks/useMaxiPlayerPosterHook';
import MaxiPlayerPosterHook from './components/MaxiPlayerPosterHook/MaxiPlayerPosterHook';

import styles from './MaxiPlayerPosterV2.module.scss';

const MaxiPlayerPosterV2 = ({ pageReferenceId }: { pageReferenceId: string }) => {
  const {
    isPosterHidden,
    isLogoPoster,
    maxiPlayerPosterUrl,
    setLiveProgramPosterImages,
    assetId,
  } = useMaxiPlayerPosterHook(pageReferenceId);

  if (isPosterHidden) {
    return null;
  }

  return (
    <>
      {assetId && <MaxiPlayerPosterHook assetId={assetId} setLiveProgramPosterImages={setLiveProgramPosterImages} />}
      <img
        className={cn(styles.maxiPlayerPoster, { [styles.logoPoster]: isLogoPoster })}
        alt='maxiPlayerPoster'
        src={maxiPlayerPosterUrl}
      />
      <div className={styles.overlay} />
    </>
  );
};

export default observer(MaxiPlayerPosterV2);
