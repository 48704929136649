import React, { useCallback, useState, MouseEventHandler } from 'react';

import { config } from 'components/pages/Epg/config';
import LocalTimeV2 from 'components/presenters/LocalTimeV2/LocalTimeV2';
import TimeLineHooks from './components/TimeLineHooks/TimeLineHooks';

import styles from '../../Time.module.scss';

const TimeLine = () => {
  const [items, setItems] = useState<{ time: number; callBack: MouseEventHandler<HTMLDivElement> }[]>([]);
  const forwardSetItems = useCallback(
    (array: { time: number; callBack: MouseEventHandler<HTMLDivElement> }[]) => setItems(array),
    [],
  );

  return (
    <>
      <TimeLineHooks forwardSetItems={forwardSetItems} />
      {items.map(({ time, callBack }, index) => {
        return (
          <div
            key={time}
            data-time={time}
            className={styles.hour}
            data-index={index}
            style={{
              width: config.hourScale,
            }}
            onClick={callBack}
            role='button'
            tabIndex={0}
          >
            <LocalTimeV2 time={time} />
          </div>
        );
      })}
    </>
  );
};

export default TimeLine;
