import React from 'react';
import cn from 'classnames';

import { RecordIcon, SvgView } from 'components/presenters/icons';
import { getMDIconPath } from 'utils';
import { MD_ICON } from 'consts';
import { useAppStore } from 'hooks/common/useAppStore';
import { Props } from '../../types';

import styles from './CardTitleStaticView.module.scss';

function CardTitleStaticView({
  title,
  isRecord,
  isWrapperWithIcon,
  isWithSeriesRecord,
  seasonEpisodeTitle,
  isSeriesType,
  isMosaicProgram,
}: Props) {
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  return (
    <>
      <div
        className={cn(
          styles.wrapper,
          { [styles.wrapperAsStaticInfoWithIcon]: isWrapperWithIcon },
          { [styles.seriesRecord]: isWithSeriesRecord },
        )}
      >
        <div className={styles.titleWrapper}>
          <h5>{title}</h5>
          {isMosaicProgram && (
            <span className={styles.mosaicIcon}>
              <SvgView url={`${staticAssetsUrl}${getMDIconPath(MD_ICON)}`} />
            </span>
          )}
          {isRecord && (
            <span className={styles.recordIcon}>
              <RecordIcon active multiple={isSeriesType} />
            </span>
          )}
        </div>
        {seasonEpisodeTitle && <p>{seasonEpisodeTitle}</p>}
      </div>
    </>
  );
}

export default CardTitleStaticView;
