import React, { createContext, useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

import { useDetectLocation } from 'hooks/common/useDetectLocation';
import { useToastStore } from 'hooks/common/useToastStore';

import successIcon from 'assets/images/icon-success.svg';
import errorIcon from 'assets/images/icon-error-big.svg';

import styles from './toast.module.scss';

export const TOAST_TIMEOUT = 10000;
export const SHOW_TOAST_DELAY = 100;

export const defaultProps = {
  className: '',
  success: false,
  error: false,
  isAutoHidden: true,
  icon: undefined,
  title: '',
  subtitle: '',
};

interface Props {
  children: React.ReactNode;
}

export interface ToastProps {
  className?: string;
  success?: boolean;
  error?: boolean;
  isAutoHidden?: boolean;
  icon?: string;
  title: string;
  subtitle?: string;
  toastTtl?: number;
  onClear?(): void;
}

export interface ContextType {
  showToast(value: ToastProps): void;
  clearToast(): void;
}

const ToastMessageContext = createContext<ContextType>({
  showToast: () => {},
  clearToast: () => {},
});

const ToastMessageProvider = ({ children }: Props) => {
  const [toast, setToast] = useState<ToastProps>(defaultProps);
  const { isDetailsPage } = useDetectLocation();
  const toastStore = useToastStore();
  const location = useLocation();
  let timeout: NodeJS.Timeout;
  let showToastTimeout: NodeJS.Timeout;

  const clearToast = useCallback(() => {
    setToast((toastData: ToastProps) => {
      clearTimeout(timeout);

      if (toastData.onClear) {
        toastData.onClear();
      }
      return defaultProps;
    });
  }, []);

  const showToast = useCallback(
    ({
      className,
      success,
      error,
      icon,
      title,
      subtitle,
      toastTtl = TOAST_TIMEOUT,
      onClear,
      isAutoHidden = true,
    }: ToastProps) => {
      clearTimeout(timeout);
      clearTimeout(showToastTimeout);
      // eslint-disable-next-line
      showToastTimeout = setTimeout(() => {
        setToast({ className, success, error, icon, title, subtitle, onClear, isAutoHidden });
        toastStore.clearAll();
        // eslint-disable-next-line
        timeout = setTimeout(() => {
          if (isAutoHidden) {
            clearToast();
          }
        }, toastTtl);
      }, SHOW_TOAST_DELAY);
    },
    [clearToast],
  );

  useEffect(() => {
    clearToast();
    toastStore.clearAll();
  }, [location.pathname]);

  const contextValue = useMemo(() => {
    return { showToast, clearToast };
  }, [showToast, clearToast]);

  const { className, subtitle, title, success, error, icon } = toast;
  const toastIcon = (success && successIcon) || (error && errorIcon) || icon;
  const isToastVisible = success || error || !!icon;

  return (
    <ToastMessageContext.Provider value={contextValue}>
      <div
        className={cn(styles.toastWrapper, className, {
          [styles.visible]: isToastVisible,
          [styles.details]: isDetailsPage,
        })}
      >
        <div className={cn(styles.toast, { [styles.success]: success, [styles.error]: error })}>
          {toastIcon && <SVG className={styles.icon} src={toastIcon} />}
          <div className={styles.textColumn}>
            <div>{title}</div>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          </div>
          <button aria-label='close' className={styles.cross} onClick={clearToast} type='button' />
        </div>
      </div>
      {children}
    </ToastMessageContext.Provider>
  );
};

const useToastMessageContext = () => useContext(ToastMessageContext);

export { ToastMessageProvider, useToastMessageContext, ToastMessageContext };
