import React, { createContext } from 'react';

import { ModalContext } from 'context/modal/modal';
import { ContextType as ModalContextType } from 'context/modal/interface';
import { ErrorScreenContext } from 'context/error/error';
import { ContextType as ErrorScreenContextType } from 'context/error/types';
import { PerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { ToastMessageContext, ContextType as ToastContextType } from 'context/toast/toast';
import { ContextType as HeartbeatContextType, HeartbeatContext } from 'context/heartbeat/heartbeat';
import { PerformanceContextType } from 'interface';
import { NavigationContext, ContextType as RouterContextType } from 'context/navigationContext/navigationContext';

export interface ContextType {
  heartbeatContext: HeartbeatContextType;
  modalContext: ModalContextType;
  errorContext: ErrorScreenContextType;
  performanceContext: PerformanceContextType;
  toastContext: ToastContextType;
  routerContext: RouterContextType;
}
interface Props {
  children: React.ReactNode;
}

const CombinedContext = createContext<ContextType>({
  heartbeatContext: { setMetrics: () => {} },
  modalContext: { openModal: () => {}, closeModal: () => {} },
  errorContext: { setError: () => {}, clearError: () => {}, stopLoadingError: () => {} },
  performanceContext: {
    resetEventsStartTime: () => {},
    clearEvent: () => {},
    getEvent: () => undefined,
    setStartTime: () => {},
    setEndTime: () => {},
  },
  toastContext: { showToast: () => {}, clearToast: () => {} },
  routerContext: {
    goBack: () => {},
    navType: '',
  },
});

const CombinedContextProvider = ({ children }: Props) => {
  return (
    <NavigationContext.Consumer>
      {(routerContext) => (
        <ErrorScreenContext.Consumer>
          {(errorContext) => (
            <HeartbeatContext.Consumer>
              {(heartbeatContext) => (
                <PerformanceEventsContext.Consumer>
                  {(performanceContext) => (
                    <ModalContext.Consumer>
                      {(modalContext) => (
                        <ToastMessageContext.Consumer>
                          {(toastContext) => (
                            <CombinedContext.Provider
                              value={{
                                errorContext,
                                heartbeatContext,
                                modalContext,
                                performanceContext,
                                toastContext,
                                routerContext,
                              }}
                            >
                              {children}
                            </CombinedContext.Provider>
                          )}
                        </ToastMessageContext.Consumer>
                      )}
                    </ModalContext.Consumer>
                  )}
                </PerformanceEventsContext.Consumer>
              )}
            </HeartbeatContext.Consumer>
          )}
        </ErrorScreenContext.Consumer>
      )}
    </NavigationContext.Consumer>
  );
};

export { CombinedContext, CombinedContextProvider };
