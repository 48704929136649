import { globalStorage } from 'services/globalStorage';
import { CONCURRENCY_ERROR, CONCURRENCY_ERROR_CODE } from '../../constants';
import { playerStorage, contextStorage } from '../../storages';
import { PlayerEvent } from '../../types';
import { criticalError } from './helpers/criticalError/criticalError';
import { concurrencyError } from './helpers/concurrencyError/concurrencyError';
import { handleKsExpiredError } from './helpers/resetError/helpers/handleKsExpiredError';

export async function handleError(event: PlayerEvent) {
  const { payload, target } = event;
  const { player } = playerStorage.getPlayer();
  const { severity } = payload;
  const { currentTime } = target;
  const {
    errorContext: { clearError },
  } = contextStorage.getContext();
  if (currentTime!) {
    playerStorage.setSavedCurrentTime(currentTime);
  }

  globalStorage.setIsPlayerError(true);

  const isConcurrencyErr =
    payload.data?.data?.messages?.[0].code === CONCURRENCY_ERROR ||
    payload.result?.error?.code === CONCURRENCY_ERROR_CODE;

  // Only call handleKsExpiredError if it hasn't been handled yet
  if (!globalStorage.isKsErrorHandled) {
    const ksExpiredErrorHandled = await handleKsExpiredError(payload);

    if (ksExpiredErrorHandled) {
      return;
    }
  }

  if (severity === player.Error?.Severity?.CRITICAL && !isConcurrencyErr) {
    criticalError(payload);

    return;
  }

  clearError();
  playerStorage.clearIsPlayBackPausedAndSavedCurrentTime(); // To reset saved parameters if the error is not critical
  globalStorage.clearIsPlayerError();

  if (isConcurrencyErr) {
    concurrencyError(payload);
  }
}
