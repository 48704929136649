import React from 'react';

import { Program } from '@kaltura-ott/tvpil-shared';

import { getImageUrl } from '../../helpers';
import { useAssetCardContext } from '../../AssetCardContext/AssetCardContext';

export const AssetCardProgramChannelInfo = () => {
  const {
    listStore,
    resolvedProps: { isHero, currentAsset, mosaicChannelTitle },
  } = useAssetCardContext();
  const { linear } = currentAsset as Program;

  if (!linear) {
    return null;
  }

  const { title } = linear.data;
  const image = getImageUrl({ asset: currentAsset, listStore, isHero })?.image;

  if (isHero && image?.url) {
    return <img data-type='logo' alt={title} src={image.url} />;
  }

  if (!isHero) {
    return <span>{mosaicChannelTitle || title}</span>;
  }

  return null;
};
