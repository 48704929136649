import React from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import { useVideoContext } from 'context/videoContext/videoContext';

import styles from '../../VideoContent.module.scss';

type Props = {
  isCatchupSeries: boolean;
};

export const Tags = ({ isCatchupSeries }: Props) => {
  const { program } = useVideoContext();
  const { tags } = program.data;
  const { parentalRatingString } = program;

  return (
    <div className={styles.specifications}>
      {parentalRatingString && (
        <div className={cn(styles.label, styles.rating)}>
          <div className={styles.short}>
            <FormattedMessage
              defaultMessage={parentalRatingString}
              id={`${COMPONENT_NAME}.VideoContent.ratingTitle.${parentalRatingString}`}
            />{' '}
            <FormattedMessage
              defaultMessage={parentalRatingString}
              id={`${COMPONENT_NAME}.VideoContent.ratingDescription.${parentalRatingString}`}
            />
          </div>
        </div>
      )}
      {!isCatchupSeries && (
        <>
          {tags?.Quality?.length > 0 &&
            tags.Quality.map((quality: string) => (
              <div key={quality} className={styles.label}>
                {quality}
              </div>
            ))}
          {tags?.audioType?.length > 0 &&
            tags.audioType.map((item: any) => (
              <div key={item} className={styles.label}>
                {item}
              </div>
            ))}
        </>
      )}
    </div>
  );
};
