import React from 'react';
import { observer } from 'mobx-react';

import { useEpgContext } from 'context/epgContext/epgContext';
import { useGridHooksCombiner } from './hooks/useGridHooksCombiner/useGridHooksCombiner';
import Programs from './components/Programs/Programs';
import GridMovementHooks from './components/GridMovementHooks/GridMovementHooks';
import Channels from './components/Channels/Channels';

import styles from './Grid.module.scss';

function Grid() {
  const { epgRef } = useEpgContext();
  const { onDragStart, onDragEnd, onDragMove } = useGridHooksCombiner();

  return (
    <div
      ref={epgRef}
      role='button'
      tabIndex={0}
      className={styles.wrapper}
      onMouseDown={onDragStart}
      onMouseUp={onDragEnd}
      onMouseMove={onDragMove}
      onMouseLeave={onDragEnd}
      onMouseUpCapture={onDragEnd}
    >
      <Channels />
      <GridMovementHooks />
      <Programs />
    </div>
  );
}

export default observer(Grid);
