import { useEffect, useState } from 'react';
import { millisecondsInSecond } from 'date-fns';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared';

import { HUNDRED_UNITS } from 'consts';
import { playerStorage } from 'components/widgets/Player/storages';
import { playoutStoreDataResolver } from 'utils';
import { useDetectLocation, useIsNpvr } from 'hooks';

interface Props {
  currentAsset: any;
  duration: number | undefined;
  isOverlay?: boolean;
  isPast: boolean;
}

function useBookmark({ currentAsset, duration, isOverlay, isPast }: Props) {
  const [bookmarkInfo, setBookmarkInfo] = useState({
    watchedDuration: 0,
    finishedWatching: false,
  });
  const [remainingTime, setRemainingTime] = useState(duration);

  const isNpvr = useIsNpvr();
  const { isLiveVideoPage } = useDetectLocation();

  useEffect(() => {
    if (!currentAsset) return;

    let { bookmark } = currentAsset;
    const { recordBookmark } = currentAsset;
    const { type } = playoutStoreDataResolver();
    const isRecord = isNpvr || (isOverlay && type === PlayoutStoreV2Type.NPVR);

    if (isRecord) {
      bookmark = recordBookmark;
    }

    if (bookmark) {
      setRemainingTime(bookmark.duration - bookmark.position);
      if (bookmark.finishedWatching) {
        setBookmarkInfo((prevState) => ({ ...prevState, watchedDuration: HUNDRED_UNITS, finishedWatching: true }));
        if (!bookmark.position || bookmark.position > bookmark.duration) {
          // if finishedWatching and backend sent wrong position that equals 0
          setRemainingTime(1);
        }
      } else {
        setBookmarkInfo((prevState) => ({
          ...prevState,
          watchedDuration: (bookmark.position / bookmark.duration) * HUNDRED_UNITS,
          finishedWatching: false,
        }));
      }
    } else if (isLiveVideoPage && isPast) {
      const resolveDuration = Math.ceil(duration! / millisecondsInSecond);
      const resolveCurrentTime = Math.ceil(playerStorage.getSavedCurrentTime());

      setRemainingTime(resolveDuration - resolveCurrentTime);
      setBookmarkInfo({
        watchedDuration: (resolveCurrentTime / resolveDuration) * HUNDRED_UNITS,
        finishedWatching: false,
      });
    }
  }, [currentAsset?.bookmark, currentAsset?.recordBookmark, isPast]);

  return { bookmarkInfo, remainingTime };
}

export { useBookmark };
