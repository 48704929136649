import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import { CatchUpAirDate } from '@kaltura-ott/tvpil-shared';

import { RecordIcon } from 'components/presenters/icons';
import AirTime from 'components/presenters/AirTime/AirTime';
import LocalTime from 'components/presenters/LocalTime/LocalTime';
import { DateFormatStyle, formatDate } from 'utils';

import styles from './EpisodeContent.module.scss';

export interface Props {
  isContentRestricted?: boolean;
  isSeriesRecord: boolean;
  isShowExpirationTime?: boolean;
  isRecordIconCondition: boolean;
  duration?: number;
  convertDurationToMinute: number | string;
  episodeNumber?: number;
  catchupAvailableTime?: number;
  episodeName?: string;
  description: string;
  channelName: string;
  displayLanguage: string;
  catchUpAirDate: CatchUpAirDate;
}

function EpisodeContent({
  isRecordIconCondition,
  isShowExpirationTime,
  isSeriesRecord,
  description,
  episodeName,
  episodeNumber,
  convertDurationToMinute,
  duration,
  channelName,
  isContentRestricted = false,
  catchUpAirDate,
  catchupAvailableTime,
  displayLanguage,
}: Props) {
  return (
    <div className={styles.content}>
      {isContentRestricted ? (
        <>
          <header>
            <h4 className={styles.restrictedTitle}>
              <FormattedMessage defaultMessage='Restricted Content' id='Rail.RailCard.restrictedContent' />
            </h4>
          </header>
          <p className={styles.restrictionDescription}>
            <FormattedMessage defaultMessage='Enter PIN to view' id='VideoDetails.Episode.enterPinToView' />
          </p>
        </>
      ) : (
        <>
          <header>
            {duration && <span className={styles.duration}>{convertDurationToMinute}m</span>}
            {episodeNumber && (
              <span className={styles.episodeNumber}>
                <FormattedMessage
                  defaultMessage='Episode {number}'
                  id='VideoDetails.Episode.episode'
                  values={{ number: episodeNumber }}
                />
              </span>
            )}
            {episodeName && <h5>{episodeName}</h5>}
            {isRecordIconCondition && <RecordIcon active className={styles.recordIcon} multiple={!!isSeriesRecord} />}
          </header>
          {description && <p className={styles.description}>{description}</p>}
          <div className={styles.info}>
            <span className={styles.channel}>{channelName}</span>
            {catchUpAirDate && (
              <>
                {', '}
                <AirTime catchUpAirDate={catchUpAirDate} className={styles.airTime} isInfo />
              </>
            )}
            {isShowExpirationTime && (
              <span className={styles.expires}>
                <FormattedMessage
                  defaultMessage='Expires on {value}'
                  id='VideoDetails.Episode.expires'
                  values={{
                    value: formatDate(catchupAvailableTime as number, DateFormatStyle.WithoutWeekday, displayLanguage),
                  }}
                />{' '}
                <LocalTime date={catchupAvailableTime} />
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default observer(EpisodeContent);
