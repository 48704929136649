import React from 'react';
import { FormattedMessage } from 'react-intl';

import routes from 'routesPaths';
import Img from 'components/presenters/Img/Img';
import MainLayout from 'components/presenters/layouts/main/MainLayout';
import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import { APPLICATION_SPINNER_EVENT_NAME } from 'utils/getAdditionalEventParam';
import Loader from 'components/presenters/Loader/Loader';
import { SeriesCatchUpGridSettingsProvider } from 'context/seriesCatchUpGridSettingsContext/seriesCatchUpGridSettingsContext';
import { useSeriesCatchUpGridHooksCombiner } from './hooks/useSeriesCatchUpGridHooksCombiner/useSeriesCatchUpGridHooksCombiner';
import { COMPONENT_NAME } from '../constants';
import SeriesCathUpGridCardsView from './components/SeriesCathUpGridCardsView/SeriesCathUpGridCardsView';
import GridUpdatingHook from '../common/components/GridUpdatingHook/GridUpdatingHook';

import styles from './SeriesCatchUpGrid.module.scss';

function SeriesCatchUpGrid() {
  const { isLoading, gridSettings, wrapperRef, providerLogo, setForceUpdate } = useSeriesCatchUpGridHooksCombiner();

  return (
    <MainLayout>
      <GridUpdatingHook setForceUpdate={setForceUpdate} path={routes.seriesCatchUpGrid.path} />
      <div ref={wrapperRef} className={styles.wrapper}>
        {!!providerLogo && <Img alt='provider logo' className={styles.providerLogo} srcList={providerLogo} />}
        {isLoading ? (
          <div className={styles.loaderWrapper}>
            <LoaderWrapperForEvents hashKey={APPLICATION_SPINNER_EVENT_NAME.GRID_PAGE} component={<Loader />} />
          </div>
        ) : (
          <>
            <FormattedMessage
              defaultMessage={gridSettings.title}
              id={`${COMPONENT_NAME}.title.${gridSettings.title}`}
              tagName='h3'
            />
            <SeriesCatchUpGridSettingsProvider gridSettingsData={gridSettings}>
              <SeriesCathUpGridCardsView />
            </SeriesCatchUpGridSettingsProvider>
          </>
        )}
      </div>
    </MainLayout>
  );
}

export default React.memo(SeriesCatchUpGrid);
