import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import { useModalContext } from 'context/modal/modal';
import { useOnClickOutside } from 'hooks/common/useOnclickOutside';
import { ButtonProps } from '../defaultModalButtonProps';
import { CLOSE_BTN_AREA } from '../constants';
import { DefaultModalButtons } from './components/DefaultModalButtons/DefaultModalButtons';

import styles from './DefaultModal.module.scss';

interface Props {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  buttonProps?: ButtonProps;
  buttonsArray?: ButtonProps[];
  loading?: boolean;
  confirm?: boolean;
  className?: string;
  icon?: JSX.Element;
  forwardRef?: any;
  onDecline?(): void;
  skipClickOutside?: boolean;
}

function DefaultModal({
  title,
  confirm,
  children,
  buttonProps,
  buttonsArray,
  loading,
  description,
  className,
  icon,
  forwardRef,
  onDecline,
  skipClickOutside = false,
}: Props) {
  const modalContext = useModalContext();
  const ref = useRef<HTMLDivElement | null>(null);

  const handleDecline = () => {
    modalContext.closeModal();

    if (onDecline) {
      onDecline();
    }
  };

  useOnClickOutside(ref, (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;

    if (skipClickOutside || target.ariaLabel?.includes(CLOSE_BTN_AREA)) return;

    // in case we have several modals opened one by one
    if (!target.closest(`.${styles.defaultModal}`)) {
      handleDecline();
    }
  });

  useEffect(() => {
    if (forwardRef) {
      forwardRef(ref?.current);
    }
  }, [forwardRef]);

  return (
    <div className={cn(styles.defaultModal, className)} ref={ref}>
      {confirm && <i className={styles.iconSuccess} />}
      {icon}
      {title && <h2 className={styles.title}>{title}</h2>}
      {description && <p dangerouslySetInnerHTML={{ __html: description }} />}
      {children}
      <DefaultModalButtons
        handleDecline={handleDecline}
        loading={loading}
        buttonsArray={buttonsArray}
        buttonProps={buttonProps}
      />
    </div>
  );
}

export default DefaultModal;

export { DefaultModal };
