import { useCallback } from 'react';

import { useRootStore } from 'hooks';
import { applyStyles } from 'utils';
import { Node } from 'types';

export const useHandleRef = () => {
  const {
    detailsStore: { assetDetailsPage },
  } = useRootStore();

  return useCallback(
    (node: Node) => {
      if (assetDetailsPage?.uiDesignElements && node) {
        applyStyles(node, assetDetailsPage.uiDesignElements);
      }
    },
    [assetDetailsPage], // eslint-disable-line
  );
};
