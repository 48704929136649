import React from 'react';
import { useIntl } from 'react-intl';
import { useRootStore } from 'hooks';
import { useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { UIDAStructureTypeEnum, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { PageStore } from '@kaltura-ott/tvpil-shared';

import { useModalContext } from 'context/modal/modal';
import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';
import { ActionButtonTypesEnum } from 'interface';
import IconClose from 'assets/images/icon-error-big.svg';

import styles from './NoSpaceModal.module.scss';

export default function NoSpaceModal() {
  const rootStore = useRootStore();
  const intl = useIntl();
  const navigate = useNavigate();
  const modalContext = useModalContext();

  const recordsPage = rootStore?.children?.find(
    ({ type, subtype }: any) => type === UIDAStructureTypeEnum.page && subtype === UIDAPresentationTypeEnum.npvr,
  ) as PageStore;

  function moveToRecordsPage() {
    modalContext.closeModal();
    navigate(recordsPage?.link);
  }

  return (
    <div className={styles.modal}>
      <DefaultModal
        buttonProps={{
          acceptButtonType: ActionButtonTypesEnum.primary,
          acceptButtonText: intl.formatMessage({ defaultMessage: 'Recordings Page', id: 'NoSpaceModal.buttonRecords' }),
          onAccept: moveToRecordsPage,
        }}
        description={intl.formatMessage({
          defaultMessage: 'To free up some space go to recordings page and delete some recordings.',
          id: 'NoSpaceModal.description',
        })}
        icon={<SVG src={IconClose} />}
        title={intl.formatMessage({ defaultMessage: "You don't have enough space", id: 'NoSpaceModal.title' })}
      />
    </div>
  );
}
