import { PLATFORM } from 'consts';
import { ckeckTouchSupport } from '../../utils/ckeckTouchSupport';

// eslint-disable-next-line consistent-return
export const useCheckPlatform = () => {
  const { navigator } = window;

  if (navigator && ckeckTouchSupport()) {
    if (/iPad|iPhone|iPod|MacIntel|Macintosh/.test(navigator.userAgent)) {
      return PLATFORM.IOS;
    }
    if (/Android/.test(navigator.userAgent)) {
      return PLATFORM.ANDROID;
    }
  }
};
