import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Subscription } from '@kaltura-ott/tvpil-shared';
import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import { APPLICATION_SPINNER_EVENT_NAME, applyStyles } from 'utils';
import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import { useParentalPinStore, useSettingsStore } from 'hooks';
import { Node } from 'types';
import routes from 'routesPaths';
import { useModalContext } from 'context/modal/modal';
import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import SkeletonView from './SkeletonView/SkeletonView';
import Card from '../Card/Card';
import AdditionPlans from './AdditionPlans/AdditionPlans';
import Banner from '../Banner/Banner';
import Offer from '../Offers/Offer';
import { COMPONENT_NAME } from './constants';
import { findSubscriptionStore } from '../utils';
import TariffPlans from './TariffPlans/TariffPlans';

import styles from './Subscriptions.module.scss';

function Subscriptions() {
  const parentalPinStore = useParentalPinStore();
  const { settingsStore } = useSettingsStore();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const modalContext = useModalContext();
  const subscriptionStore = findSubscriptionStore(settingsStore.children)!;

  const handleRef = useCallback((node: Node) => {
    const { uiDesignElements } = subscriptionStore;

    if (uiDesignElements && node !== null) {
      applyStyles(node, uiDesignElements);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    (async () => {
      await subscriptionStore.init();
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return (
      <LoaderWrapperForEvents hashKey={APPLICATION_SPINNER_EVENT_NAME.SUBSCRIPTIONS} component={<SkeletonView />} />
    );
  }

  function formatLink(id: number) {
    return `${routes.purchase.path}/${id}`;
  }

  const checkRestrictions = (subscription: Subscription, link: string) => (e: React.MouseEvent) => {
    if (parentalPinStore.isBlockByAdult(subscription)) {
      e.preventDefault();

      modalContext.openModal(
        <PinCodeModal
          asset={subscription}
          callback={(success: boolean) => {
            if (success) {
              navigate(link);
            }
          }}
          context={UIDAPresentationTypeEnum.subscription}
          type={PinCodeModalTypes.RestrictedContent}
        />,
      );
    }
  };

  const offers = subscriptionStore.offers.slice();
  const heroes = subscriptionStore.heroes.slice();
  const additionPlans = subscriptionStore?.additionPlans;
  const mySubscriptions = subscriptionStore?.mySubscriptions;

  return (
    <div ref={handleRef} className={styles.wrapper}>
      <div className={styles.subscriptions}>
        <FormattedMessage defaultMessage='My Subscriptions' id={`${COMPONENT_NAME}.MySubscriptions`} tagName='h2' />
        <TariffPlans />
        {additionPlans?.length > 0 && <AdditionPlans subscriptionStore={subscriptionStore} />}
        {mySubscriptions?.length > 0 && (
          <>
            <FormattedMessage defaultMessage='Subscriptions' id={`${COMPONENT_NAME}.subscriptionsTitle`} tagName='h3' />
            {subscriptionStore.mySubscriptions.map((subscription) => {
              const {
                packageData: { id, title },
              } = subscription;
              const link = formatLink(id);
              return <Card key={id} link={link} name={title} onHandleClick={checkRestrictions(subscription, link)} />;
            })}
          </>
        )}

        {(heroes.length > 0 || offers.length > 0) && (
          <>
            <FormattedMessage defaultMessage='Some offers for you' id={`${COMPONENT_NAME}.offersTitle`} tagName='h3' />
            {heroes.length > 0 &&
              heroes.map(({ packageData }) => (
                <Banner
                  key={packageData.id}
                  className={styles.banner}
                  hero={packageData}
                  link={formatLink(packageData.id)}
                />
              ))}
            {offers.length > 0 && (
              <div className={styles.offers}>
                {offers.map((offer) => {
                  const { price, packageData } = offer;
                  const link = formatLink(packageData.id);
                  return (
                    <Offer
                      key={packageData.id}
                      link={link}
                      offer={{ price, ...packageData }}
                      onHandleClick={checkRestrictions(offer, link)}
                    />
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default observer(Subscriptions);
