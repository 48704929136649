export const BODY_OPEN_CLASS_NAME = 'open';
export const MIN_CARD_WIDTH = 320;
export const IMAGE_PARAM = '/height/0/width/0';
export const DEFAULT_WIDTH = 300;
export const DEFAULT_HEIGHT = 200;
export const DEFAULT_RATIO = '1x1';
export const PLAYER_MOUNT_NODE = 'kaltura_player';
export const BASE = 'base';
export const SVG_ADDITIONAL_PARAM = '?type=svg';
export const ROOT = 'root';
export const ONE_YEAR = 31536000;

export const MARGIN_BOTTOM = 120;
export const MARGIN_CARD_BOTTOM = 3;
export const MARGIN_HORIZONTAL = 130;
export const MARGIN_RIGHT = 8;

export const LAST_RAIL_X_POSITION = 'last-rail-x-position';
export const LAST_SCROLL_Y_POSITION = 'last-scroll-y-position';
export const LAST_DRAG_X_POSITION = 'last-drag-x-position';
export const FUTURE_RECORDINGS = 'futureRecordings';
export const MD_UI_DISABLE = 'MdUiDisable';
export const UI_MODE = 'uiMode';
export const RECORDINGS = 'recordings';
export const VOLUME = 'kux-volume';
export const MUTED = 'kux-muted';
export const LANGUAGE_TYPE = {
  AUDIO: 'audio',
  SUBTITLE: 'subtitle',
  DISPLAY: 'display',
};
export const LAST_VIEW_SEASON_NUMBER = 'last-view-season-number';

export const ORIENTATION = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
};

export const PLATFORM = {
  IOS: 'iOS',
  ANDROID: 'Android',
};

export const SINGLE_RECORD = 'singleRecording';
export const SERIES_RECORD = 'seriesRecording';
export const SINGLE_RECORD_ACTIVE = 'singleRecordingActive';
export const SERIES_RECORD_ACTIVE = 'seriesRecordingActive';
export const PENDING_RECORD = 'spinner';
export const IS_ENABLE_UPDATED_DEVICE_MANAGEMENT = 'IS_ENABLE_UPDATED_DEVICE_MANAGEMENT';

export const FEATURE_MULTILANGUAGE = 'FEATURE_MULTILANGUAGE';
export const LAST_URL = 'lastLocation';
export const LAST_URL_PLAY_BACK = 'lastUrlPlayBack';

export const ERROR_ACTION_NAMES = {
  APP_INIT: 'AppStore.init',
  APP_REBOOT: 'AppStore.rebootApp',
  DETAILS_INIT: 'DetailsStore.init',
  EPG_INIT: 'EpgStore.init',
  EPG_SET_VISIBLE: 'EpgStore.setVisibleProgram',
  LOGIN_AUTH: 'LoginStore.loginOAuthUser',
  LOGIN_JWT: 'LoginStore.loginByJwtToken',
  LOGIN_OTT: 'LoginStore.loginByOttUsernameAndPassword',
  LOGIN_SSO: 'LoginStore.loginBySSOUsernameAndPassword',
  PARENTAL_PIN_UPDATE: 'ParentalPinStore.updateParentalPin',
  PLAYOUT_INIT: 'PlayoutStoreV2.init',
  PURCHASE_PIN_UPDATE: 'ParentalPinStore.updatePurchasePin',
  RECORDING_ADD: 'RecordRepository.addRecord',
  RECORDING_REMOVE: 'RecordRepository.removeRecord',
  SEARCH_INVOKE: 'RailStoreV2.getData',
  SELFSERVICE_INIT: 'SelfServiceStore.init',
  SERVICES_SELECT: 'ServicesStore.selectService',
  SUBSCRIPTIONS_INIT: 'TransactionStore.initSubscriptions',
};

export const FEATURES = {
  ANALYTICS: 'ANALYTICS',
  GDPR: 'GDPR',
  LOGIN_FORM: 'LOGIN_FORM',
  AUTH_PARAMS: 'AUTH_PARAMS',
  OTT_LOGIN: 'OTT_LOGIN',
  LOGIN_FORM_TYPE: 'LOGIN_FORM_TYPE',
  LOGIN_METHOD: 'LOGIN_METHOD',
  SHOW_EXTENDED_ERROR_LOG: 'SHOW_EXTENDED_ERROR_LOG',
  SENTRY_TRACES_SAMPLE_RATE: 'SENTRY_TRACES_SAMPLE_RATE',
  SENTRY_SAMPLE_RATE: 'SENTRY_SAMPLE_RATE',
  PIN_LAST_WATCHED_CHANNEL_TO_THE_TOP_OF_EPG: 'PIN_LAST_WATCHED_CHANNEL_TO_THE_TOP_OF_EPG',
  IS_NEW_EPG: 'IS_NEW_EPG',
  IS_NEW_COLLECTION_GRID: 'IS_NEW_COLLECTION_GRID',
  IS_NEW_COLLECTION_RAIL: 'IS_NEW_COLLECTION_RAIL',
  BROADPEAK_KEEP_ALIVE_FREQUENCY: 'BROADPEAK_KEEP_ALIVE_FREQUENCY',
  IS_NEW_SERIES_COLLECTION: 'IS_NEW_SERIES_COLLECTION',
  SENTRY_IGNORE_ERRORS: 'SENTRY_IGNORE_ERRORS',
  IS_NEW_SERIES_COLLECTION_RAIL: 'IS_NEW_SERIES_COLLECTION_RAIL',
  NEW_HERO_RAIL_ENABLED: 'NEW_HERO_RAIL_ENABLED',
  NEW_RENTAL_RAIL_ENABLED: 'NEW_RENTAL_RAIL_ENABLED',
  ENABLE_NEW_RECOMMENDATION_RAIL: 'ENABLE_NEW_RECOMMENDATION_RAIL',
};

export const LOGIN_TYPE = {
  OTT: 'OTT',
};

export const LOGIN_METHOD = {
  LOGIN_FORM: 'LOGIN_FORM',
  EXTERNAL_LOGIN: 'EXTERNAL_LOGIN',
};

export const CUSTOM_APP_EVENTS = {
  IS_SKELETON_VIEW: 'IS_SKELETON_VIEW',
  SHOW_OSD: 'SHOW_OSD',
  SWITCH_TO_CATCH_UP: 'SWITCH_TO_CATCH_UP',
};

export const BACK_NAVIGATION_TRY = 'backNavigationTry';
export const LAST_CHANGED_LANGUAGE = 'LAST_CHANGED_LANGUAGE';
export const NETWORK_ERROR = 'client::request-network-error';
export const NETWORK_ERROR_FOR_DISPLAY = 106;
export const NETWORK_ERROR_GEO = 554;
export const DO_NOT_SHOW_AGAIN = 'DoNotShowAgain';
export const FROM_REFERENCE_ID = 'fromReferenceId';
export const PREV_LOCATION_KEY = 'PREV_LOCATION_KEY';
export const SERIES_ID = 'seriesid';

export const SEARCH_STRING = 'searchString';
export const SEARCH_HISTORY_ICON_PATH = 'searchHistoryIcon';
export const SEARCH_ICON_PATH = 'searchIcon';

export const WARNING_ICON = 'warning';

export const FAVORITE_ICON_PATH = 'favoriteFilled';
export const FAVORITE_ICON_BIG = 'favoriteBig';
export const FAVORITE_ICON_FILLED = 'favoriteFilled';
export const MD_ICON = 'MD_icon';
export const PENDING_PURCHASE_SMALL = 'pendingPurchaseSmall';
export const SHOPPING_CART_ICON = 'shopping-cart';
export const CLOCK = 'clock';
export const VOICE_ICON = 'voiceIcon';
export const SUBTITLES_ICON = 'subtitlesIcon';

export const GEOBLOCK_ICON = 'geoblock';
export const LOCK_ICON = 'lock';

export const SILENT_LOGIN_COOKIE = 'silentLoginCookie';
export const UDID = 'udid';
export const DISPLAY_NAME = 'displayName';

export const O2_ENV_GROUP = [3201, 3206];
export const YETTEL_ENV_GROUP = [3200, 3204, 5002];

export const MS_IN_SEC = 1000;
export const RAIL_PAGE_SIZE = 20;
export const MIN_OR_SEC_IN_HOUR = 60;
export const SEC_IN_HOUR = 3600;
export const HALF_DIVIDER = 2;
export const HUNDRED_UNITS = 100;
export const DEFAULT_SEEK_JUMP = 10;
export const TIMESTAMP_THRESHOLD = 10000000000;

export const STAR_ICON = {
  YELLOW: 'star.svg',
  WHITE: 'star-white.svg',
};

export const ERROR_CODES = {
  UNHANDLED_ERROR: 7000,
  KS_EXPIRED: '500016',
};

export const SENTRY_DEFAULT_VALUES = {
  SAMPLE_RATE: 0.5,
  TRACES_SAMPLE_RATE: 0,
};

export const RHINO_NETWORK_ERROR = 'NETWORK_ERROR';
