import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { featureEnabler, RailStoreV2 } from '@kaltura-ott/tvpil-shared';
import { FEATURES } from 'consts';

import { checkIsLogoRail } from './checkIsLogoRail';

export function checkIsSeriesCatchUpRail(rail: RailStoreV2) {
  const isEnabledSeriesCatchUpRail = featureEnabler.variation(FEATURES.IS_NEW_SERIES_COLLECTION_RAIL, false);

  return (
    rail?.subtype === UIDAPresentationTypeEnum.series_catch_up && !checkIsLogoRail(rail) && isEnabledSeriesCatchUpRail
  );
}
