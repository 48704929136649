import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';

import { useDeviceStore, useRootStore } from 'hooks';
import { applyStyles } from 'utils';
import OnBoardingLayout from 'components/presenters/layouts/onboarding/OnBoardingLayout';
import DeviceList from 'components/presenters/DeviceList/DeviceList';
import DeviceListSkeleton from 'components/presenters/DeviceList/DeviceListSkeleton/DeviceListSkeleton';
import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import routes from 'routesPaths';
import { Node } from 'types';

import loginStyles from 'components/widgets/Login/Login.module.scss';
import styles from './DeviceLimit.module.scss';

const COMPONENT_NAME = 'DeviceLimit';
const limitTextDefaultMessage =
  'You reached the limit of <strong>{householdList}</strong> out of <strong>{householdList}</strong> devices per platform for your account.<br />If you want to continue, you can delete one of these devices:';

function DeviceLimit() {
  const rootStore = useRootStore();
  const { deviceLimitExceeded, householdList } = useDeviceStore();

  const handleRef = useCallback((node: Node) => {
    if (node) {
      const { uiDesignElements } = rootStore!;

      if (uiDesignElements && node !== null) {
        applyStyles(node, uiDesignElements, false, true);
      }
    }
  }, []); // eslint-disable-line

  const isDeviceReady = householdList.length !== 0;

  if (!deviceLimitExceeded) {
    return <Navigate to={routes.home.path} />;
  }

  return (
    <OnBoardingLayout className={styles.layout} refElement={handleRef}>
      <div className={styles.center}>
        {isDeviceReady ? (
          <>
            <div className={loginStyles.fixedTitle}>
              <FormattedMessage id={`${COMPONENT_NAME}.title`} defaultMessage='Device Limit Reached' tagName='h1' />
              <FormattedMessage
                defaultMessage={limitTextDefaultMessage}
                id={`${COMPONENT_NAME}.limitText`}
                tagName='p'
                values={{
                  householdList: householdList.length,
                  br: <br />,
                  strong: (text: React.ReactNode) => <b>{text}</b>,
                }}
              />
            </div>

            <DeviceList center />
          </>
        ) : (
          <LoaderWrapperForEvents hashKey={COMPONENT_NAME} component={<DeviceListSkeleton />} />
        )}
      </div>
    </OnBoardingLayout>
  );
}

export default observer(DeviceLimit);
