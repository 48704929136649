import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { useRailContext } from 'components/widgets/Rail/RailHorisontal/components/RailProvider/RailProvider';
import { AssetType } from 'components/widgets/Rail/typesOfAsset';

import { useCardDataResolver } from '../hooks';
import AssetCardResolveRendering from '../AssetCardResolveRendering/AssetCardResolveRendering';
import { AssetCardContext } from '../AssetCardContext/AssetCardContext';

interface Props<T = AssetType> {
  listIndex: number;
  asset: T;
  disableHover?: boolean;
  children?: React.ReactNode;
}

const AssetCardProvider = observer(({ asset, disableHover = false, listIndex }: Props) => {
  const { store } = useRailContext();
  const resolvedProps = useCardDataResolver(asset, store);

  const contextValue = useMemo(() => {
    return {
      listStore: store,
      asset,
      disableHover,
      listIndex,
      isGrid: false,
      resolvedProps,
    };
  }, [store, asset, disableHover, resolvedProps, listIndex]);

  return (
    <AssetCardContext.Provider value={contextValue}>
      <AssetCardResolveRendering />
    </AssetCardContext.Provider>
  );
});

export { AssetCardProvider };
