import { useCallback } from 'react';
import { LAST_RAIL_X_POSITION } from 'consts';

interface Props {
  id: number;
  offset: any;
}

export const useOnCardClickHook = ({ id, offset }: Props) =>
  useCallback(
    (linearId?: number) =>
      sessionStorage.setItem(LAST_RAIL_X_POSITION, JSON.stringify({ id, position: offset.current, linearId })),
    [offset.current], // eslint-disable-line
  );
