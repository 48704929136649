import { useLocation } from 'react-router-dom';

import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import { overlayStorage } from 'components/widgets/Player/storages';
import { useDetectLocation, useIsNpvr } from 'hooks';
import { resolveLinkForMd } from 'utils';
import routes from 'routesPaths';

export function useResolvePathToWatch(asset: ViewableEntitiesType) {
  const location = useLocation();
  const isOverlayOpen = overlayStorage.getShowOverlay();
  const { id, mediaType: assetMediaType, isVodSingle } = detailsStoreDataResolver(asset);
  const { isMdUiDisable } = useDetectLocation();
  const isNpvr = useIsNpvr();
  const mediaType = assetMediaType.toLowerCase();

  if (isOverlayOpen) {
    return null;
  }

  let link = routes.catchUp.link(id, mediaType);

  if (isNpvr) {
    link = routes.program.link(id, mediaType);
  }

  if (isVodSingle) {
    link = routes.vod.link(id, mediaType);
  }

  link += resolveLinkForMd(isMdUiDisable, link); // to avoid displaying MD components in the player

  return {
    to: link,
    state: { goBackUrl: `${location.pathname}${location.search}` },
  };
}
