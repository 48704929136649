import React from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import ActionButton from 'components/widgets/ActionButton/ActionButton';
import Languages from 'components/widgets/LanguagesDropDown/LanguagesDropDown';
import { useDownloadPage } from './hooks/useDownloadPage';

import styles from './DownloadPageChecker.module.scss';

function DownloadPageChecker({ children }: { children: JSX.Element }) {
  const {
    to,
    isChangingLangEnabled,
    isAppDownloadRequired,
    actionFrameIconUrl,
    bannerUrl,
    handleRef,
    isTablet,
  } = useDownloadPage();

  if (!isAppDownloadRequired) {
    return children;
  }

  return (
    <div ref={handleRef} className={cn(styles.container, { [styles.noImage]: !bannerUrl, [styles.tablet]: isTablet })}>
      <div className={styles.languages}>{isChangingLangEnabled && <Languages />}</div>
      <div className={styles.actionFrame}>
        {actionFrameIconUrl && (
          <img className={styles.actionFrameIcon} src={actionFrameIconUrl} alt='actionFrameIcon' />
        )}
        <h1 className={styles.headline}>
          <FormattedMessage id='Download.headline' defaultMessage="It's Better In The App" />
        </h1>
        <p className={styles.descrption}>
          <FormattedMessage
            id='Download.description'
            defaultMessage='Download our mobile app to watch your favorite content and for the best experience'
          />
        </p>
        <ActionButton className={styles.linkTo} to={to}>
          <FormattedMessage defaultMessage='Download App' id='Download.linkTo' />
        </ActionButton>
      </div>
      {bannerUrl && <img className={styles.banner} src={bannerUrl} alt='banner' />}
    </div>
  );
}

export default DownloadPageChecker;
