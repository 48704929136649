import React from 'react';

import { observer } from 'mobx-react';
import { ScrollRestoration } from 'react-router-dom';

import {
  useAppBeforeUnLoad,
  useAppBranding,
  useAppCheckCookieConfirmation,
  useAppErrorReaction,
  useAppEvents,
  useAppHistoryListener,
  useAppImagesPreload,
  useAppParentalPin,
  useAppPreloadAfterLoad,
  useAppPreparePlayer,
  useAppSentryInit,
  useApplicationErrorEvents,
  useDetailsPageBackNavigation,
  useScrollToTop,
  useAppGDPRCustomScript,
  useAppTitleManager,
  useAppAwakened,
  useAppNpvrHandler,
} from 'hooks';
import { cutStringUpTo } from 'utils/cutStringUpTo';

function AppHooksCombiner() {
  // apply the gdpr custom script
  useAppGDPRCustomScript();
  // apply branding rules
  useAppBranding();
  // adding and removing an event listener
  useAppEvents();
  // setting and deleting values in the browser's session storage on an beforeunload event
  useAppBeforeUnLoad();
  // initializing sentry under certain conditions
  useAppSentryInit();
  // to check apiError field from tvpill
  useAppErrorReaction();
  // to check parental
  useAppParentalPin();
  // for manipulation when changing the url
  useAppHistoryListener();
  // for manipulation with 2 step back navigation for details pages
  useDetailsPageBackNavigation();
  // to check if the player is available
  useAppPreparePlayer();
  // to confirmation cookie
  useAppCheckCookieConfirmation();
  // to preload images from static repo
  useAppImagesPreload();
  // to preload EPG
  useAppPreloadAfterLoad();
  // application error analytics
  useApplicationErrorEvents();
  // auto scroll to page top if user comes to new page
  useScrollToTop();
  // configure app title
  useAppTitleManager();
  // app awaking after stand-by mode
  useAppAwakened();
  // to go 2 steps back after cancelling recording
  useAppNpvrHandler();

  return (
    <ScrollRestoration
      getKey={(location) => {
        // cut the string up to the "details"  to apply the same behavior for page and details page on it
        // for example: page/homepage/details-info/123 -> /page/homepage
        // a result we will have the same scroll position for page and details page(opened as modal)
        if (location.pathname.includes('details')) {
          return cutStringUpTo(location.pathname, 'details');
        }
        return location.pathname;
      }}
    />
  );
}

export default observer(AppHooksCombiner);
