import { useEffect, useState } from 'react';

import { getImageList, getRatingImageList } from 'utils';
import { useAppStore } from '../common/useAppStore';

function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);

    const handleImageLoadError = () => reject(new Error(`Image loading failed: ${src}`));

    img.onerror = handleImageLoadError;
    img.onabort = handleImageLoadError;
    img.src = src;
  });
}

export function useAppImagesPreload() {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);
  const {
    appStore: { dms, staticAssetsUrl },
  } = useAppStore();

  useEffect(() => {
    async function effect() {
      const list = [...getImageList(staticAssetsUrl!), ...getRatingImageList(staticAssetsUrl!, dms?.ratingMap)];
      const imagesPromiseList: Promise<any>[] = [];

      list.forEach((item) => imagesPromiseList.push(preloadImage(item)));
      await Promise.all(imagesPromiseList);
      setImagesPreloaded(true);
    }

    if (!imagesPreloaded && staticAssetsUrl) {
      effect();
    }
  }, [dms, staticAssetsUrl]);
}
