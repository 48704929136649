import { MediaType } from '@kaltura-ott/tvpil';

import routes from 'routesPaths';
import { AssetWithSeriesId } from 'types';
import { resolvePathToDetailsPage } from 'utils';

interface Props {
  data: AssetWithSeriesId;
  isSingleVod?: boolean;
  isFutureProgram: boolean;
  collectionType: string | undefined;
  mediaTypeParam: MediaType;
  isChannelAsset: boolean;
  isAsPlayAsset: boolean;
}

export function resolveLinkProgramAsset({
  data,
  collectionType,
  mediaTypeParam,
  isFutureProgram,
  isSingleVod,
  isChannelAsset,
  isAsPlayAsset,
}: Props) {
  let link;

  if (isChannelAsset) {
    link = routes.live.link(data.id, data.type);
  } else if (isSingleVod) {
    link = routes.vod.link(data.id, data.type);
  } else if (isAsPlayAsset) {
    link = routes.catchUp.link(data.id, data.type);
  } else {
    const { seriesId } = data as AssetWithSeriesId;

    link = resolvePathToDetailsPage({
      id: data.id,
      subtype: collectionType,
      mediaTypeParam,
      seriesId,
      isFuture: isFutureProgram, // for avoid the bugs with future recording
    });
  }

  return link;
}
