import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import ActionButton from 'components/widgets/ActionButton/ActionButton';
import Input from 'components/widgets/common/Input/Input';
import { ActionButtonTypesEnum } from 'interface';
import { ReactComponent as UserIcon } from 'assets/images/user.svg';
import { ReactComponent as LockIcon } from 'assets/images/padlock.svg';
import { ReactComponent as ViewIcon } from 'assets/images/view.svg';
import { ReactComponent as ActiveViewIcon } from 'assets/images/activeView.svg';

import routes from 'routesPaths';

interface ObjectProps {
  errorMessage: string;
  value: string;
  isFailedValidation: boolean;
}

interface Props {
  handleSubmit: (e: React.FormEvent<EventTarget>) => void;
  validateOnBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleStateChange(): void;
  isPending: boolean;
  isVisiblePassword: boolean;
  form: { email: ObjectProps; password: ObjectProps };
}

export default function FormWrapper({
  handleSubmit,
  validateOnBlur,
  handleChange,
  handleStateChange,
  isPending,
  isVisiblePassword,
  form,
}: Props) {
  const intl = useIntl();
  const Element = isVisiblePassword ? ActiveViewIcon : ViewIcon;

  return (
    <form onSubmit={handleSubmit}>
      <Input
        disabled={isPending}
        error={form.email.errorMessage}
        icon={<UserIcon />}
        isShowError={form.email.isFailedValidation}
        name='email'
        onBlur={validateOnBlur}
        onChange={handleChange}
        placeholder={intl.formatMessage({ id: 'Input.Email.placeholder', defaultMessage: 'Email' })}
        type='text'
        value={form.email.value}
      />
      <Input
        disabled={isPending}
        error={form.password.errorMessage}
        icon={<LockIcon />}
        toggleIcon={<Element data-target='viewIcon' onClick={handleStateChange} />}
        isShowError={form.password.isFailedValidation}
        name='password'
        onBlur={validateOnBlur}
        onChange={handleChange}
        placeholder={intl.formatMessage({ id: 'Input.Password.placeholder', defaultMessage: 'Password' })}
        type={isVisiblePassword ? 'text' : 'password'}
        value={form.password.value}
      />
      <ActionButton disabled={isPending} type={ActionButtonTypesEnum.primary} submit>
        <FormattedMessage defaultMessage='Sign in' id='Forms.SignIn.submitButton' />
      </ActionButton>
      <p>
        <Link to={routes.forgotPassword.path}>
          <FormattedMessage defaultMessage='Forgot password?' id='Forms.SignIn.forgotPassword' />
        </Link>
      </p>
    </form>
  );
}
