import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared';

import { playoutStoreDataResolver } from 'utils';
import { CUSTOM_PLAYER_EVENTS } from '../../constants';
import { playerStorage, startOverInfoStorage } from '../../storages';
import { isLive } from '../../utils/isLive/isLive';

export async function handleCheckIsStartOverOutsidePlayer() {
  const { isStartOver, isSetOutsidePlayer } = startOverInfoStorage.getStartOver();
  const { player } = playerStorage.getPlayer();
  const { id, type } = playoutStoreDataResolver();

  if (player && isStartOver && isSetOutsidePlayer) {
    if (type === PlayoutStoreV2Type.LIVE) {
      player.dispatchEvent({
        type: CUSTOM_PLAYER_EVENTS.SWITCH_TO_START_OVER,
        payload: { epgId: id, startTime: 0, normalizedStartTime: 0 },
      });
    } else {
      // not for live
      player.normalizedCurrentTime = 0;
      // to reset isSetOutsidePlayer
      startOverInfoStorage.setStartOver(isLive());
    }
  }
}
