import React from 'react';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import routes from 'routesPaths';
import profileIcon from 'assets/images/profile.svg';

import styles from '../../Menu.module.scss';

function ProfileIcon() {
  return (
    <NavLink
      className={({ isActive }) => (isActive ? cn(styles.profileActive, styles.userProfile) : styles.userProfile)}
      to={routes.settings.path}
    >
      <SVG src={profileIcon} />
      <FormattedMessage defaultMessage='UserProfile' id='Menu.userProfileText' />
    </NavLink>
  );
}

export default ProfileIcon;
