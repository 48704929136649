import { useRef, useCallback } from 'react';

import { useHandleRef } from '../useHandleRef/useHandleRef';
import { useInitializationHook } from '../useInitializationHook/useInitializationHook';
import { useUpdateTimestamp } from '../useUpdateTimestamp/useUpdateTimestamp';
import { useEventsListener } from '../useEventsListener/useEventsListener';
import { useEpgHooksCombinerOutputProps } from '../../interface';

function useEpgHooksCombiner() {
  const forwardGridMovement = useRef<HTMLDivElement | null>(null);
  const firstVisibleIndex = useRef<number | null>(null);
  const gridStartYPosition = useRef<number>(0);
  const ref = useRef<HTMLDivElement | null>(null);
  const nowLineRef = useRef<HTMLDivElement | null>(null);
  const refTime = useRef<HTMLDivElement | null>(null);
  const userActive = useRef(false); // is epg touched by user
  const handleRef = useHandleRef();
  const setFirstVisibleIndex = useCallback((id: number) => {
    firstVisibleIndex.current = id;
  }, []);
  const { epgParams } = useInitializationHook();

  useUpdateTimestamp({ userActive, ref });

  const { setUserActive } = useEventsListener({
    ref,
    refTime,
    userActive,
    epgParams,
  });

  return {
    setUserActive,
    setFirstVisibleIndex,
    handleRef,
    nowLineRef,
    epgParams,
    gridStartYPosition,
    userActive,
    refTime,
    ref,
    forwardGridMovement,
  } as useEpgHooksCombinerOutputProps;
}

export { useEpgHooksCombiner };
