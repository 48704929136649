import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useParentalControlContext } from 'context/parentalControlContext/parentalControlContext';
import ActionButton from 'components/widgets/ActionButton/ActionButton';
import AgeRanges from '../AgeRanges/AgeRanges';
import ParentalInfo from '../ParentalInfo/ParentalInfo';
import { FULL_COMPONENT_NAME, typePin } from '../../constans';

import styles from '../../ParentalControl.module.scss';

const ParentalBlock = () => {
  const { onChangePin } = useParentalControlContext();

  return (
    <>
      <p className={styles.name}>
        <FormattedMessage defaultMessage='Viewing Restriction' id={`${FULL_COMPONENT_NAME}.textRestriction`} />
      </p>
      <div className={styles.block}>
        <ParentalInfo />
        <AgeRanges />
        <ActionButton className={styles.actionButton} onClick={onChangePin(typePin.parental)}>
          <FormattedMessage defaultMessage='Change Parental PIN' id={`${FULL_COMPONENT_NAME}.buttonChangeParental`} />
        </ActionButton>
      </div>
    </>
  );
};

export default React.memo(ParentalBlock);
