import { useLayoutEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

import routes from 'routesPaths';
import { LAST_URL } from 'consts';

export function useScrollToTop() {
  const { pathname } = useLocation();
  const navType = useNavigationType();

  useLayoutEffect(() => {
    const prevUrl = sessionStorage.getItem(LAST_URL) || '';

    if (
      navType !== 'POP' &&
      !pathname.includes(routes.details.commonPath) &&
      !prevUrl.includes(routes.details.commonPath)
    ) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
}
