import React from 'react';

import { config } from 'components/pages/EpgV2/config';
import { useEpgContext } from 'context/epgContext/epgContext';
import Channel from './components/Channel/Channel';

import styles from './Channels.module.scss';

function Channels() {
  const { channels } = useEpgContext();
  const tvChannels = channels ?? [];

  return (
    <ul
      className={styles.list}
      style={{
        width: config.channelWidth,
      }}
    >
      {tvChannels.map((channel) => (
        <Channel key={channel.id} channel={channel} />
      ))}
    </ul>
  );
}

export default Channels;
