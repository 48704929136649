import { secondsToMilliseconds } from 'date-fns';

import { appStore } from '@kaltura-ott/tvpil-shared';

import { getWarningIcon } from 'utils';
import { SLOW_CONNECTION_ICON_PATH } from '../../constants';
import { contextStorage, intlStorage, overlayStorage, playerStorage } from '../../storages';

let isToastVisible = false;
let prevBitrate = 0;
let slowConnectionTimeout: NodeJS.Timeout;

export function handleVideoTrackChanged(e: any) {
  const {
    toastContext: { showToast, clearToast },
  } = contextStorage.getContext();
  const { minBitrateFromVideoTracks } = playerStorage.getVideoTrackInfo();
  const ICON_SLOW_CONNECTION = `${appStore.staticAssetsUrl}${getWarningIcon(SLOW_CONNECTION_ICON_PATH)}`;
  const intl = intlStorage.getIntl();
  const activeTrackBitrate = e.payload.selectedVideoTrack.bandwidth;
  const userBitrate = appStore?.userStore?.userBitrate! * 1000000 || Infinity;
  const isNotSlowConnectionHandle = userBitrate < activeTrackBitrate || !minBitrateFromVideoTracks;
  const isLowestBitrate = activeTrackBitrate <= minBitrateFromVideoTracks && activeTrackBitrate < prevBitrate;
  prevBitrate = activeTrackBitrate;

  if (isNotSlowConnectionHandle) {
    clearTimeout(slowConnectionTimeout);
    return;
  }

  if (isLowestBitrate) {
    clearTimeout(slowConnectionTimeout);
    const timer = secondsToMilliseconds(appStore?.dms?.slowConnectionSamplingPeriod || 0);

    slowConnectionTimeout = setTimeout(() => {
      isToastVisible = true;

      if (!overlayStorage.getShowOverlay()) {
        showToast({
          title: intl.formatMessage({
            defaultMessage: 'Slow Connection',
            id: 'Player.slowConnectionToastDescription',
          }),
          icon: ICON_SLOW_CONNECTION,
          isAutoHidden: false,
        });
      }
    }, timer);
  } else {
    clearTimeout(slowConnectionTimeout);

    // to not close mosaic toast to early
    if (isToastVisible) {
      isToastVisible = false;
      clearToast();
    }
  }
}
