import { useCallback, useEffect } from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { DataRailV2, PageStore } from '@kaltura-ott/tvpil-shared';

import { railObserver } from 'components/widgets/Rail/utils';
import { applyStyles, applyStyleHexToHSL } from 'utils';
import { Node } from 'types';
import { useRailSkeletonSizer, useRootStore } from 'hooks';

function useRecordsCombiner(store: PageStore) {
  const rootStore = useRootStore();
  const pageStore = rootStore?.children?.find(({ subtype }) => subtype === UIDAPresentationTypeEnum.npvr) as PageStore;
  const npvrStore = (pageStore?.children?.find(
    ({ subtype }) => subtype === UIDAPresentationTypeEnum.npvr,
  ) as unknown) as DataRailV2;
  const isViewMessage = pageStore?.totalRecordsRail?.items.length! === 0 && pageStore?.totalRecordsRail?.isReady;
  const { columnCount } = useRailSkeletonSizer(npvrStore);
  const isReady = !!pageStore?.isReady && !!pageStore?.totalRecordsRail?.isReady;

  useEffect(() => {
    (async function () {
      if (pageStore && !pageStore.isReady) {
        await pageStore.initRecordsPage();
      }
    })();

    return () => {
      pageStore?.resetStore();
      pageStore?.totalRecordsRail?.resetStore();
    };
  }, [pageStore]);

  const handleRef = useCallback((node: Node) => {
    const { uiDesignElements } = store;

    if (uiDesignElements && node !== null) {
      applyStyles(node, uiDesignElements, false, true);
      applyStyleHexToHSL(node, uiDesignElements.backgroundColor);
    }
  }, []); // eslint-disable-line

  const handleSkeletonRef = useCallback((node: Node) => {
    if (node) {
      railObserver.observe(node);
    }
  }, []);

  return {
    handleSkeletonRef,
    handleRef,
    isReady,
    columnCount,
    isViewMessage,
    pageStore,
    npvrStore,
  };
}

export { useRecordsCombiner };
