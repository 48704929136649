import { useCallback } from 'react';

import { MediaType, UserActionType } from '@kaltura-ott/tvpil-analytics';

import { userActionEvents } from 'utils';
import { globalStorage } from 'services/globalStorage';

export const useHandleClickInfoButton = (currentAsset: any) => {
  return useCallback(() => {
    globalStorage.setDetailsAsset(currentAsset);
    userActionEvents(UserActionType.details, currentAsset?.currentProgram?.data?.id!, MediaType.live);
  }, [currentAsset]);
};
