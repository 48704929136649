import {
  useLinears,
  useAssetImage,
  CollectionAsset,
  isCollectionVodAsset,
  getProgramAiringDate,
  isCollectionLinkAsset,
  isCollectionMovieAsset,
  CollectionProgramAsset,
  isCollectionSeriesAsset,
  shouldPlayOnExternalApp,
  isCollectionLinearAsset,
  isCollectionChannelAsset,
  isCollectionProgramAsset,
  isCollectionEpisodeAsset,
  isCollectionInAppLinkAdult,
  getCollectionAssetChannelId,
  isCollectionAssetPurchasable,
  getCollectionAssetContentMarker,
} from '@kaltura-ott/tvpil-shared';

interface Props {
  data: CollectionAsset;
  imageType?: number;
  imageHeight: number | undefined;
  isGrouped: boolean;
}

function useCollectionCardMainProps({ data, imageType, imageHeight, isGrouped }: Props) {
  const { height, url, width } = useAssetImage(data.images, { imageType, imageHeight: imageHeight! * 2 }); // imageHeight * 2 for increasinf the imag size
  const contentMarker = getCollectionAssetContentMarker(data);
  const isLinearAsset = isCollectionLinearAsset(data);
  const isLinkAsAsset = isCollectionLinkAsset(data);
  const isVod = isCollectionVodAsset(data);
  const isProgramAsset = isCollectionProgramAsset(data);
  const isTypeOfSeries = isCollectionSeriesAsset(data);
  const isCollectionMovie = isCollectionMovieAsset(data);
  const isCollectionEpisode = isCollectionEpisodeAsset(data);
  const adult = data.isAdult;
  const isCollectionInAppAdultLink = isCollectionInAppLinkAdult(data);
  const isAssetPlayOnExternalApp = shouldPlayOnExternalApp(data);
  const isFuture = isProgramAsset && data.startDate > Date.now();
  const isChannelAsset = isCollectionChannelAsset(data);
  const isCatchupSeries = isGrouped && isProgramAsset && Boolean(data.seriesId);
  const { linears } = useLinears(); // TODO: must be replaced by useChannelsDictionary
  const catchUpAirDate = getProgramAiringDate(data as CollectionProgramAsset);
  const channelInfo = isLinearAsset ? linears.get(getCollectionAssetChannelId(data)) : undefined;
  const isPurchasable = isCollectionAssetPurchasable(data, linears);
  const hasSeriesId = (isCollectionEpisode || isProgramAsset) && Boolean(data.seriesId);
  const isSingleVod = isCollectionEpisode || isCollectionMovie;

  return {
    imageProps: { height, url, width },
    contentMarker,
    isLinkAsAsset,
    isPurchasable,
    isVod,
    isProgramAsset,
    isTypeOfSeries,
    isCollectionEpisode,
    adult,
    isAssetPlayOnExternalApp,
    isFuture,
    isCatchupSeries,
    catchUpAirDate,
    channelInfo,
    hasSeriesId,
    isSingleVod,
    isChannelAsset,
    isCollectionInAppAdultLink,
  };
}

export { useCollectionCardMainProps };
