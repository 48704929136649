import { useToastMessage } from 'hooks';
import { onValidatePinChange } from '../../helpers/onValidatePinChange';
import { useOnDeclineChangePin } from '../useOnDeclineChangePin/useOnDeclineChangePin';
import { useOnChangeAgeRange } from '../useOnChangeAgeRange/useOnChangeAgeRange';
import { useOnValidatePinConfirm } from '../useOnValidatePinConfirm/useOnValidatePinConfirm';
import { useOnSuccessChangedPin } from '../useOnSuccessChangedPin/useOnSuccessChangedPin';
import { useOnChangeAndConfirmPin } from '../useOnChangeAndConfirmPin/useOnChangeAndConfirmPin';
import { useHandleClick } from '../useHandleClick/useHandleClick';
import { useParentalControlInit, useParentalControlSetAgeThreshold } from '..';

export const useParentalControlCombiner = (setAgeThreshold: (value: React.SetStateAction<string>) => void) => {
  const { onDeclineChangePin } = useOnDeclineChangePin();
  const { onChangeAgeRange } = useOnChangeAgeRange(setAgeThreshold);
  const { onValidatePinConfirm } = useOnValidatePinConfirm(onDeclineChangePin);
  const { onSuccessChangedPin } = useOnSuccessChangedPin();
  const { onChangePin } = useOnChangeAndConfirmPin({
    onDeclineChangePin,
    onValidatePinChange,
    onSuccessChangedPin,
    onValidatePinConfirm,
  });
  const { handleClick } = useHandleClick(onChangePin);

  return {
    handleClick,
    useToastMessage,
    onChangeAgeRange,
    useParentalControlInit,
    useParentalControlSetAgeThreshold,
  };
};
