import { Location } from 'react-router-dom';

import { HouseholdMigrationErrorMessage } from '@kaltura-ott/tvpil';
import { appStore, ErrorPriority, ToastMessage } from '@kaltura-ott/tvpil-shared';

import { isLoginFormError } from 'utils';
import { ERROR_ACTION_NAMES } from 'consts';
import routes from 'routesPaths';

export function resolveError(location: Location) {
  const errList = appStore.apiError!;
  const toastList = appStore.toastStore.list as ToastMessage[];
  const { onRetry, title, message, code, type, actionName, onCancel } = errList[Object.keys(errList)[0]];
  const isDetailsInitError = type === ErrorPriority.high && actionName === ERROR_ACTION_NAMES.DETAILS_INIT;
  const isMigrationError = message === HouseholdMigrationErrorMessage;
  const isLoginError = isLoginFormError(actionName);
  const isToastError = (type === ErrorPriority.medium || isDetailsInitError) && !toastList.length;
  const isFullScreenError =
    (type === ErrorPriority.high && !isLoginError && !isDetailsInitError) ||
    (isDetailsInitError && location.pathname.includes(routes.details.commonPath));

  return {
    location,
    code,
    title,
    message,
    actionName,
    onRetry,
    onCancel,
    isToastError,
    isMigrationError,
    isFullScreenError,
  };
}
