import { UserActionType } from '@kaltura-ott/tvpil-analytics';
import { useProvider } from '@kaltura-ott/tvpil-shared';

import ProviderActivationModal from 'components/widgets/Modal/ProviderActivationModal/ProviderActivationModal';
import { DO_NOT_SHOW_AGAIN } from 'consts';
import { ContextType as ModalContext } from 'context/modal/interface';
import { useCallback } from 'react';
import localStorageService from 'services/localStorageService';
import { CollectionGridDeepLinkAsset } from 'types';
import { userActionEvents } from 'utils';

export function usePlayProviderAsset(data: CollectionGridDeepLinkAsset | undefined, modalContext: ModalContext) {
  const { providerName, id } = data!;
  const providerSettings = useProvider(providerName!);

  return useCallback(
    (e: React.MouseEvent<any>, isExternalProviderAsset: boolean) => {
      e.preventDefault();

      const { showPopupBeforePlayback, providerImageUrl } = providerSettings!;
      const isDirectPlayback =
        !showPopupBeforePlayback || localStorageService.getItem(`${providerName}${DO_NOT_SHOW_AGAIN}`);
      const watchAsset = async () => {
        const link = isExternalProviderAsset ? data?.deeplink : undefined;

        if (typeof link === 'string') {
          userActionEvents(UserActionType.deepLink, id, providerName, !isDirectPlayback);

          window.location.href = link;
        }
      };

      if (isDirectPlayback) {
        watchAsset();
        return;
      }

      modalContext.openModal(
        <ProviderActivationModal
          provider={providerName!}
          providerLogo={providerImageUrl!}
          providerSettings={providerSettings!}
          watchAsset={watchAsset}
        />,
      );
    },
    [providerSettings],
  );
}
