/* eslint-disable no-undef */
// @ts-nocheck
import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { AppStore } from '@kaltura-ott/tvpil-shared';

import { CombinedContext, CombinedContextProvider } from 'context/combinedContext/combinedContext';
import { PLAYER_MOUNT_NODE } from 'consts';
import { CHANGE_LOCAL_STATE } from './constants';
import {
  addRegularEventListener,
  removeRegularEventListener,
  updateActions,
  unmountActions,
  mountActions,
  focusOnPlayerNode,
} from './utils';
import { playerStorage } from './storages';
import { playerServiceManagement } from './services/PlayerServiceManagement';
import { PlayerService } from './services/PlayerService';
import { AnalyticsService } from './services/analytics/AnalyticsService';
import KalturaPlayerView from './components/KalturaPlayerView/KalturaPlayerView';
import { handleError, handleLoadMedia, handleCheckIsStartOverOutsidePlayer } from './handlers';
import { KalturaPlayerProps, KalturaPlayerDefaultProps } from './types';

// eslint-disable-next-line no-use-before-define
export type Props = typeof KalturaPlayer.defaultProps & {
  assetId: number;
  className?: string;
  muted?: boolean;
  hideUI?: boolean;
  appStore?: AppStore;
  isShowStreams?: boolean;
  isMaxi?: boolean;
};

interface State {
  isOpen: boolean;
  isControlsReady: boolean;
  isShowStreams: boolean;
}

@inject('appStore')
@observer
class KalturaPlayer extends PureComponent<KalturaPlayerProps & WrappedComponentProps, State> {
  static defaultProps: KalturaPlayerDefaultProps = {
    muted: false,
    hideUI: false,
    intl: null,
  };

  static contextType = CombinedContext;

  private playerRef = React.createRef<HTMLDivElement>();

  constructor(props: KalturaPlayerProps) {
    super(props);
    this.state = {
      isOpen: false,
      isControlsReady: false,
      isShowStreams: !!props.isShowStreams,
    };

    playerServiceManagement.setPlayerService(new PlayerService(PLAYER_MOUNT_NODE));
  }

  async componentDidMount() {
    try {
      const { hideUI, muted, assetId, intl } = this.props;

      await mountActions(hideUI, muted, assetId, intl, this.context);
      this.addErrorListener();
      this.addEventListeners();
      this.analyticsService = new AnalyticsService();
      this.analyticsService.addEventListeners();
      await handleLoadMedia();
      handleCheckIsStartOverOutsidePlayer();
      focusOnPlayerNode();
    } catch (e) {
      console.error(e);
    }
  }

  async componentDidUpdate(prevProps: KalturaPlayerProps) {
    updateActions(prevProps, this.props);
  }

  componentWillUnmount() {
    this.removeEventListeners();
    unmountActions();
  }

  addErrorListener = () => {
    const { player } = playerStorage.getPlayer();
    if (player) {
      player.addEventListener(player.Event.ERROR, handleError);
    }
  };

  onChangeLocalState = ({ detail }) => {
    // changing the local state through a custom event
    this.setState(detail);
  };

  addEventListeners = async () => {
    const { player } = playerStorage.getPlayer();

    if (player) {
      document.addEventListener(CHANGE_LOCAL_STATE, this.onChangeLocalState);

      addRegularEventListener(player);
    }
  };

  removeEventListeners = () => {
    const { player } = playerStorage.getPlayer();

    if (player) {
      document.removeEventListener(CHANGE_LOCAL_STATE, this.onChangeLocalState);
      removeRegularEventListener(player);

      if (this.analyticsService) {
        this.analyticsService.removeEventListeners();
      }
    }
  };

  render() {
    const {
      className,
      hideUI,
      appStore: { apiError },
    } = this.props;
    const { isOpen, isControlsReady, isShowStreams } = this.state;

    return (
      <KalturaPlayerView
        isMaxi
        hideUI={hideUI}
        isOpen={isOpen}
        isControlsReady={isControlsReady}
        isShowStreams={isShowStreams}
        className={className}
        apiError={apiError}
        playerRef={this.playerRef}
      />
    );
  }
}

const withContext = (WrappedComponent) => {
  return (props) => (
    <CombinedContextProvider>
      <WrappedComponent {...props} />
    </CombinedContextProvider>
  );
};

export { KalturaPlayer }; // for unit tests

export default withContext(injectIntl(KalturaPlayer));
