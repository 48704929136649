import React, { useEffect } from 'react';

import { SubpageStore } from '@kaltura-ott/tvpil-shared';
import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import routes from 'routesPaths';
import { useParentalPinStore, useSettingsStore } from 'hooks';
import { useModalContext } from 'context/modal/modal';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';

export const useParentalControlRedirect = () => {
  const { location, navigate } = useStableReactRouter();
  const modalContext = useModalContext();
  const parentalControlStore = useParentalPinStore();
  const { settingsStore } = useSettingsStore();
  const settingsSubPage = settingsStore.children.find((subPage) =>
    location.pathname.includes((subPage as SubpageStore).link),
  );

  useEffect(() => {
    if (settingsSubPage && parentalControlStore.isBlockByParentRating(settingsSubPage)) {
      const onDecline = () => {
        navigate(routes.settings.path);
      };

      modalContext.openModal(
        <PinCodeModal
          asset={settingsSubPage}
          onDecline={onDecline}
          type={PinCodeModalTypes.EnterParental}
          context={UIDAPresentationTypeEnum.parental_control}
        />,
      );
    }
  }, [location.pathname]);
};
