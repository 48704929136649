import { useCallback } from 'react';

import { applyStyle } from 'utils';
import { Node } from 'types';
import { calculateCardZoom } from '../../../helpers';
import { useAssetCardBodyResolveData } from '../useAssetCardBodyResolveData/useAssetCardBodyResolveData';

interface Props {
  setRef(value: any): void;
}

export const useHandleRef = ({ setRef }: Props) => {
  const { height, width } = useAssetCardBodyResolveData();

  return useCallback(
    (node: Node) => {
      if (node) {
        setRef(node);
        const parent = node.parentNode as HTMLElement;
        const { zoomToLeft, zoomToRight, zoomToTop } = calculateCardZoom({
          height,
          width,
        });

        applyStyle(parent, '--zoom-to-left', `${zoomToLeft}px`);
        applyStyle(parent, '--zoom-horizontal', `${zoomToLeft}px`);
        applyStyle(parent, '--zoom-to-right', `${zoomToRight}px`);
        applyStyle(parent, '--zoom-to-top', `-${zoomToTop}px`);
        applyStyle(parent, '--zoom-to-bottom', `-${zoomToTop}px`);
      }
    },
    [], // eslint-disable-line
  );
};
