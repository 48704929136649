import { useRef } from 'react';

import { CollectionAsset, isCollectionProgramAsset } from '@kaltura-ott/tvpil-shared';

import routes from 'routesPaths';
import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import { useMouseLeaveHandle } from 'components/widgets/CardV2/hooks/useMouseLeaveHandle/useMouseLeaveHandle';
import { useMouseEnterHandle } from 'components/widgets/CardV2/hooks/useMouseEnterHandle/useMouseEnterHandle';
import { useRecommendationCardRef } from '../useRecommendationCardRef';
import { useRecommendationCardImages } from '../useRecommendationCardImages';
import { useClickHandler } from '../useClickHandler/useClickHandler';
import { useHandleClickInfo } from '../useHandleClickInfo';
import { useRecommendationCardRating } from '../useRecommendationCardRating/useRecommendationCardRating';
import { useRecommendationChannelInfo } from '../useRecommendationChannelInfo/useRecommendationChannelInfo';

interface Props {
  item: CollectionAsset;
  itemWidth: number;
  imageHeight: number;
}

function useRecommendationCard({ item, itemWidth, imageHeight }: Props) {
  const link = routes.catchUp.link(item.id, item.type);
  // using version v2 of the rail provider
  const {
    onHover,
    nextButtons,
    settings,
    settings: { shouldShowRating },
  } = useRailContext();
  // adults
  const isAdult = !!item?.isAdult;
  // refs
  const handleLinkRef = useRef(null);
  const { ref, handleRef } = useRecommendationCardRef(itemWidth, imageHeight);
  // handlers
  const onMouseEnter = useMouseEnterHandle({ ref, itemWidth, imageHeight, isGrid: false, onHover, nextButtons });
  const onMouseLeave = useMouseLeaveHandle(onHover);
  const clickHandler = useClickHandler({
    data: item,
    isChannelHasNoProgram: false,
    mediaTypeParam: item.type,
    isPurchasable: false,
    imageHeight,
    link,
  });
  const { handleClickInfo } = useHandleClickInfo({
    id: item?.id!,
    externalId: undefined,
    mediaTypeParam: item.type,
    isChannelHasNoProgram: false,
  });
  // rating
  const { isDisplayRating, assetRating } = useRecommendationCardRating({ shouldShowRating, item });
  // images urls
  const { backgroundImageUrl, defaultImageUrl } = useRecommendationCardImages({
    item,
    imageType: settings.imageType,
    imageWidth: settings.imageWidth!,
    imageHeight,
  });
  // title and channelName
  const { title, channelName } = useRecommendationChannelInfo(item);
  // collectionProgramAsset type checking for AirTime presentation
  const collectionProgramAsset = isCollectionProgramAsset(item) ? item : undefined;

  return {
    id: item.id,
    link,
    title,
    isAdult,
    handleRef,
    assetRating,
    channelName,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    handleLinkRef,
    handleClickInfo,
    isDisplayRating,
    defaultImageUrl,
    backgroundImageUrl,
    collectionProgramAsset,
  };
}

export { useRecommendationCard };
