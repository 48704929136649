import React from 'react';

import { VodAsset } from '@kaltura-ott/tvpil-shared/lib/stores/entities';
import { UserActionType } from '@kaltura-ott/tvpil-analytics';

import localStorageService from 'services/localStorageService';
import { DO_NOT_SHOW_AGAIN } from 'consts';
import { ContextType as ModalContext } from 'context/modal/interface';
import ProviderActivationModal from 'components/widgets/Modal/ProviderActivationModal/ProviderActivationModal';
import { userActionEvents } from './userActionEvents';

export function playProviderAsset(e: React.MouseEvent<any>, providerAsset: VodAsset, modalContext: ModalContext) {
  e.preventDefault();

  const { provider, providerLogo, providerSettings, watch } = providerAsset;
  const watchProviderAsset = watch.bind(providerAsset);

  const isDirectPlayback =
    !providerSettings?.showPopupBeforePlayback || localStorageService.getItem(`${provider}${DO_NOT_SHOW_AGAIN}`);

  const watchAsset = async () => {
    const link = watchProviderAsset();

    if (typeof link === 'string') {
      userActionEvents(UserActionType.deepLink, providerAsset.id, provider, !isDirectPlayback);
      window.location.href = link;
    }
  };

  if (isDirectPlayback) {
    watchAsset();
    return;
  }

  modalContext.openModal(
    <ProviderActivationModal
      provider={provider!}
      providerLogo={providerLogo!}
      providerSettings={providerSettings!}
      watchAsset={watchAsset}
    />,
  );
}
