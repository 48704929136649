import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

import { AppStore, MenuStore, PageStore } from '@kaltura-ott/tvpil-shared';
import { UIDAStructureTypeEnum, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import { usePageId } from 'hooks/common/usePageId';
import { defaultStore } from 'interface';

function useMenuStore() {
  const { appStore } = useContext(MobXProviderContext) as {
    appStore: AppStore;
  };
  const { children } = appStore.rootStore!;
  const pageId = usePageId();

  let store = appStore.rootStore.children.find(
    ({ type, subtype }: defaultStore) =>
      type === UIDAStructureTypeEnum.menu && subtype === UIDAPresentationTypeEnum.menu,
  ) as MenuStore;

  if (pageId) {
    const pageStore = children.find(
      ({ type, referenceId }: defaultStore) => type === UIDAStructureTypeEnum.page && pageId === referenceId,
    ) as PageStore;
    const pageMenu = pageStore?.children?.find(
      ({ type, subtype }: defaultStore) =>
        type === UIDAStructureTypeEnum.menu && subtype === UIDAPresentationTypeEnum.menu,
    ) as MenuStore;
    if (pageMenu) {
      store = pageMenu;
    }
  }
  return store;
}

export { useMenuStore };
