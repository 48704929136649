import { KalturaVideoTrack } from '../../types';
import { playerStorage } from '../../storages';

const filterUniqueQualities = (qualities: Array<KalturaVideoTrack>, currentTrack: KalturaVideoTrack): Array<any> => {
  const arrLength = qualities.length - 1;
  const previousTrack = qualities[arrLength];
  const filteredQualities = [...qualities];
  const isTheSameTrack = arrLength > -1 && currentTrack.label === previousTrack.label;

  if (isTheSameTrack) {
    if (currentTrack.bandwidth > previousTrack.bandwidth) {
      filteredQualities[arrLength] = currentTrack;
    }
  } else {
    filteredQualities.push(currentTrack);
  }
  return filteredQualities;
};

export const filterQualities = () => {
  const { player } = playerStorage.getPlayer();
  const videoTracks = player.getTracks('video');

  return videoTracks
    .sort((a: KalturaVideoTrack, b: KalturaVideoTrack) => {
      return a.height < b.height ? 1 : -1;
    })
    .filter((t: KalturaVideoTrack) => {
      const maxBitrate = player.config?.abr?.restrictions?.maxBitrate ? player.config.abr.restrictions.maxBitrate : 0;
      return (maxBitrate ? t.bandwidth <= maxBitrate : t.bandwidth) && t.height;
    })
    .reduce(filterUniqueQualities, [])
    .map((t: KalturaVideoTrack) => ({
      label: t.label,
      active: !player.isAdaptiveBitrateEnabled() && t.active,
      value: t,
    }));
};
