import React from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import ParentalControl from '../ParentalControl/ParentalControl';
import VideoSettings from '../VideoSettings/VideoSettings';
import Subscriptions from '../Subscriptions/Subscriptions';
import SelfService from '../SelfService/SelfService';
import Languages from '../Languages/Languages';
import Favorites from '../Favorites/Favorites';
import Devices from '../Devices/Devices';

interface Props {
  subtype: string;
}

export default function SettingsComponentResolver({ subtype }: Props) {
  switch (subtype) {
    case UIDAPresentationTypeEnum.subscription:
      return <Subscriptions />;
    case UIDAPresentationTypeEnum.parental_control:
      return <ParentalControl />;
    case UIDAPresentationTypeEnum.language:
      return <Languages />;
    case UIDAPresentationTypeEnum.devices:
      return <Devices />;
    case UIDAPresentationTypeEnum.link_list:
      return <SelfService />;
    case UIDAPresentationTypeEnum.preferred_video_quality:
      return <VideoSettings />;
    case UIDAPresentationTypeEnum.favorites:
      return <Favorites />;
    default:
      return <h2>No subpage yet</h2>;
  }
}
