import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import routes from 'routesPaths';
import { playoutStoreDataResolver } from 'utils';
import { LAST_URL } from 'consts';
import { ActionButtonTypesEnum } from 'interface';
import DefaultModal from '../DefaultModal/DefaultModal';

const COMPONENT_NAME = 'UnPurchasedContentModal';

function UnPurchasedContentModal() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { key } = useLocation();
  const { id, mediaType } = playoutStoreDataResolver();
  const prevUrl = sessionStorage.getItem(LAST_URL) || '';
  const detailsPath = routes.detailsInfo.link(id as number, mediaType?.toLowerCase());

  function onAccept() {
    const path = prevUrl === routes.home.path ? detailsPath : `${prevUrl}${detailsPath}`;
    navigate(path);
  }

  const handleDecline = () => {
    if (key === 'default') {
      navigate(routes.home.path, { replace: true });
    } else {
      navigate(-1);
    }
  };

  return (
    <DefaultModal
      buttonProps={{
        acceptButtonText: intl.formatMessage({
          defaultMessage: 'See Details',
          id: `${COMPONENT_NAME}.buttonText`,
        }),
        acceptButtonType: ActionButtonTypesEnum.primary,
        onAccept,
      }}
      description={intl.formatMessage({
        defaultMessage: 'You are trying to watch not entitled content. <br/> You need to make a purchase first',
        id: `${COMPONENT_NAME}.description`,
      })}
      title={intl.formatMessage({
        defaultMessage: "Sorry, You Can't Watch this Content",
        id: `${COMPONENT_NAME}.title`,
      })}
      onDecline={handleDecline}
    />
  );
}

export default UnPurchasedContentModal;
