import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import BackgroundLoaderWrapper from 'components/presenters/BackgroundLoader/BackgroundLoaderWrapper';
import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import SkeletonView from '../components/SkeletonView/SkeletonView';
import VideoContent from '../components/VideoContent/VideoContent';
import Episodes from './components/Episodes/Episodes';
import { COMPONENT_NAME } from '../constants';
import { useVideoDetailsCombiner } from './hook/useVideoDetailsCombiner/useVideoDetailsCombiner';

import styles from './VideoDetails.module.scss';

function VideoDetails() {
  const {
    asset,
    posterUrl,
    isEpisodes,
    detailsInfo,
    defaultImage,
    isLandScapeView,
    landScapeViewDefined,
    seriesAdditionalRail,
    scheduledRecordingSeriesRail,
    setError,
    handleRef,
    handleClick,
    closeHandler,
  } = useVideoDetailsCombiner();

  if (!asset?.id && detailsInfo.loaded) {
    setError();

    return null;
  }

  return (
    <div className={styles.wrapper} onClick={handleClick} role='presentation'>
      <div ref={handleRef} className={cn(styles.detailsModal, styles.posterView)}>
        {posterUrl && (
          <BackgroundLoaderWrapper
            className={cn(styles.bgImage, { [styles.bgImageLandscape]: isLandScapeView })}
            filterClassName={styles.bgBlur}
            image={posterUrl}
            defaultImage={defaultImage}
          />
        )}
        {asset ? (
          <>
            {landScapeViewDefined && (
              <VideoContent
                image={posterUrl}
                isLandscapeRatio={isLandScapeView}
                program={asset}
                isDataLoaded={detailsInfo.loaded}
              />
            )}
            {isEpisodes && (
              <Episodes
                program={asset}
                seriesStore={seriesAdditionalRail!}
                scheduledStore={scheduledRecordingSeriesRail}
              />
            )}
          </>
        ) : (
          <LoaderWrapperForEvents hashKey={COMPONENT_NAME} component={<SkeletonView />} />
        )}
        <button aria-label='close' className={styles.buttonClose} onClick={closeHandler} type='button' />
      </div>
    </div>
  );
}

export default observer(VideoDetails);
