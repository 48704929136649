import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { featureEnabler, RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { FEATURES } from 'consts';
import { checkIsLogoRail } from './checkIsLogoRail';

export function checkIsRentalsRail(rail: RailStoreV2) {
  const isEnabledRentalsRail = featureEnabler.variation(FEATURES.NEW_RENTAL_RAIL_ENABLED, false);

  return rail?.subtype === UIDAPresentationTypeEnum.tvod && !checkIsLogoRail(rail) && isEnabledRentalsRail;
}
