import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { featureEnabler, RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { FEATURES } from 'consts';
import { checkIsLogoRail } from './checkIsLogoRail';

export function checkIsRecommendationRail(rail: RailStoreV2) {
  const isEnabledRecommendationRail = featureEnabler.variation(FEATURES.ENABLE_NEW_RECOMMENDATION_RAIL, false);

  return rail?.subtype === UIDAPresentationTypeEnum.suggested && !checkIsLogoRail(rail) && isEnabledRecommendationRail;
}
