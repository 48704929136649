import { useEpgContext } from 'context/epgContext/epgContext';
import { useOnHandleDragEnd, useOnHandleDragStart, useOnHandleDragContinue } from '../../handlers';
import { useSetUpEpgPositionOnTvChannelReady } from '../useSetUpEpgPositionOnTvChannelReady/useSetUpEpgPositionOnTvChannelReady';

export function useGridHooksCombiner() {
  const { userActive, forwardGridMovement, epgRef, gridStartYPosition } = useEpgContext();
  const onDragStart = useOnHandleDragStart(userActive);
  const onDragEnd = useOnHandleDragEnd();
  const onDragMove = useOnHandleDragContinue(forwardGridMovement);

  useSetUpEpgPositionOnTvChannelReady({ epgRef, gridStartYPosition });

  return {
    onDragStart,
    onDragEnd,
    onDragMove,
  };
}
