import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useIntl, FormattedMessage } from 'react-intl';

import { useDeviceStore, useSettingsStore, useConcurrencyStore } from 'hooks';
import DeviceList from 'components/presenters/DeviceList/DeviceList';
import DeviceListSkeleton from 'components/presenters/DeviceList/DeviceListSkeleton/DeviceListSkeleton';
import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import { APPLICATION_SPINNER_EVENT_NAME } from 'utils';
import { findDevicesStore } from '../utils';
import SettingsContentWrapper from '../SettingsContentWrapper/SettingsContentWrapper';

const COMPONENT_NAME = 'Settings.Devices.SettingsContentWrapper';

function Devices() {
  const { settingsStore } = useSettingsStore();
  const concurrencyStore = useConcurrencyStore();
  const { householdList, getDeviceList, totalStreamingDevices } = useDeviceStore();
  const intl = useIntl();
  const devicesLimit = concurrencyStore.household?.devicesLimit;
  const concurrentLimit = concurrencyStore.household?.concurrentLimit;
  const devicesSubPage = findDevicesStore(settingsStore.children)!;

  useEffect(() => {
    devicesSubPage.init();
  }, []);

  useEffect(() => {
    (async () => {
      await concurrencyStore.getConcurrencyLimitations(true);
      await getDeviceList();
    })();
  }, []);

  const description = (
    <>
      <FormattedMessage
        defaultMessage='{devicesLimit, plural, one {Your plan allows you to connect up to # device, } other {Your plan allows you to connect up to # devices, }}'
        id={`${COMPONENT_NAME}.Description.devicesLimit.${intl.formatPlural(devicesLimit || 0)}`} // TODO necessary to discuss what to show in this case
        values={{ devicesLimit }}
      />
      <FormattedMessage
        defaultMessage='{concurrentLimit, plural, one {you can log out from your connected devices {br} Your plan allows you up to # stream at a time, } other {you can log out from your connected devices {br} Your plan allows you up to # streams at a time, }}'
        id={`${COMPONENT_NAME}.Description.concurrentLimit.${intl.formatPlural(concurrentLimit || 0)}`} // TODO necessary to discuss what to show in this case
        values={{ concurrentLimit, br: <br /> }}
      />
      <FormattedMessage
        defaultMessage='{totalStreamingDevices, plural, one {# device currently streaming} other {# devices currently streaming}}'
        id={`${COMPONENT_NAME}.Description.totalStreamingDevices.${intl.formatPlural(totalStreamingDevices || 0)}`} // TODO necessary to discuss what to show in this case
        values={{ totalStreamingDevices }}
      />
    </>
  );

  const isDeviceReady = householdList.length !== 0;

  return (
    <SettingsContentWrapper
      description={description}
      title={intl.formatMessage({
        defaultMessage: 'Devices',
        id: `${COMPONENT_NAME}.title`,
      })}
      isSkeletonView={!isDeviceReady}
    >
      {isDeviceReady ? (
        <DeviceList />
      ) : (
        <LoaderWrapperForEvents
          hashKey={APPLICATION_SPINNER_EVENT_NAME.DEVICES}
          component={<DeviceListSkeleton isSettings />}
        />
      )}
    </SettingsContentWrapper>
  );
}

export default observer(Devices);
