import { observable, action } from 'mobx';

class CustomScriptService {
  @observable isReady: boolean = false;

  @action('Prepare script')
  prepareScript = async (scriptUrl: string, scriptId: string) => {
    if (scriptId && scriptUrl) {
      await this.loadScript(scriptId, scriptUrl);
      this.isReady = true;
    }
  };

  @action('Load script')
  async loadScript(id: string, url: string): Promise<void | Event> {
    if (document.getElementById(id) && this.isReady) {
      return Promise.resolve();
    }
    return new Promise((resolve: (this: GlobalEventHandlers, ev: Event) => any, reject: OnErrorEventHandler) => {
      const script = document.createElement('script');
      script.async = true;
      script.id = id;
      script.src = url;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }
}

export default new CustomScriptService();
