import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useRootStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import MainLayout from 'components/presenters/layouts/main/MainLayout';
import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';

import styles from './NotFound.module.scss';

function NotFound() {
  const { navigate } = useStableReactRouter();
  const rootStore = useRootStore();

  useEffect(() => {
    const { uiDesignElements } = rootStore!;
    if (uiDesignElements) {
      document.body.style.backgroundColor = uiDesignElements.backgroundColor;
    }
    globalStorage.setIsNotFound(true);

    return () => {
      globalStorage.clearIsNotFound();
    };
  }, []);

  const handleClick = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <MainLayout>
      <div className={styles.notFound}>
        <p className={styles.error}>
          <FormattedMessage defaultMessage='404 Not Found Page' id='NotFound.errorText' />
        </p>

        <div className={styles.buttons}>
          <ActionButton to='/'>
            <FormattedMessage defaultMessage='Home' id='NotFound.buttonHome' />
          </ActionButton>
          <ActionButton onClick={handleClick}>
            <FormattedMessage defaultMessage='Back' id='NotFound.buttonBack' />
          </ActionButton>
        </div>
      </div>
    </MainLayout>
  );
}

export default NotFound;
