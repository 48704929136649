import React from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import SVG from 'react-inlinesvg';

import { getImageByHeightV2 } from '@kaltura-ott/tvpil-shared';
import { AssetPresentationRatioEnum } from '@kaltura-ott/tvpil';

import { getFavoriteChannelIcon } from 'utils';
import { useToastMessageContext } from 'context/toast/toast';
import { useAppStore } from 'hooks';
import { FAVORITE_ICON_FILLED, FAVORITE_ICON_BIG } from 'consts';
import { LOGO_HEIGHT } from 'components/pages/Settings/Favorites/constants';
import { setFavoriteResultsHandler } from '../helper/setFavoriteResultsHandler';

import styles from '../Favorites.module.scss';

function Channel({ data }: any) {
  const {
    data: { title, images },
    isFavorite,
  } = data;

  const intl = useIntl();
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  const toastMessageContext = useToastMessageContext();
  const originImage = getImageByHeightV2(LOGO_HEIGHT, AssetPresentationRatioEnum['16x9'], images);

  async function handleClick() {
    toastMessageContext.clearToast();

    const isToggleSuccess = await data.setFavorite(!isFavorite);

    setFavoriteResultsHandler(data, isToggleSuccess, intl, toastMessageContext);
  }
  return (
    <div className={styles.channel} tabIndex={0} role='button' onClick={handleClick}>
      <SVG
        className={styles.starIcon}
        src={`${staticAssetsUrl}${getFavoriteChannelIcon(isFavorite ? FAVORITE_ICON_FILLED : FAVORITE_ICON_BIG)}`}
      />
      {originImage?.url ? <img src={originImage.url} alt={title} /> : <span className={styles.title}>{title}</span>}
    </div>
  );
}

export default observer(Channel);
