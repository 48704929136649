import React, { useCallback } from 'react';

import { UserActionType } from '@kaltura-ott/tvpil-analytics';
import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { RentalAsset } from '@kaltura-ott/tvpil-shared/lib/rhino/data/rental/types';

import { useModalContext } from 'context/modal/modal';
import PinCodeModalV2 from 'components/widgets/Modal/PinCodeModalV2/PinCodeModalV2';
import { DescriptionWithAge } from 'components/widgets/Modal/PinCodeModal/DescriptionWithAge/DescriptionWithAge';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModalV2/PinCodeModalTypesEnum';
import { useIsUrlToFullPlayer, useParentalPinStore, useSettingsStore } from 'hooks';
import { resolvePathToDetailsPage, userActionEvents } from 'utils';
import { LAST_SCROLL_Y_POSITION } from 'consts';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { globalStorage } from 'services/globalStorage';
import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';

interface Props {
  data: RentalAsset;
  link: string;
  mediaTypeParam: string;
  imageHeight: number;
  isPurchasable: boolean;
  adult: boolean;
  mosaicExternalId?: string;
  isChannelHasNoProgram: boolean;
}

export const useClickHandler = ({
  data,
  link,
  mediaTypeParam,
  imageHeight,
  isPurchasable,
  adult,
  isChannelHasNoProgram,
  mosaicExternalId,
}: Props) => {
  const { onCardClick } = useRailContext();
  const { navigate } = useStableReactRouter();
  const { settingsStore } = useSettingsStore();
  const parentalPinStore = useParentalPinStore();
  const isUrlToFullPlayer = useIsUrlToFullPlayer(link);
  const modalContext = useModalContext();
  // needs for parental rating check in case of url to full player and parental rating is blocked
  const parentalRating = Number(data?.parentalRating);

  return useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (isChannelHasNoProgram) {
        e.preventDefault();

        return;
      }

      globalStorage.clearDetailsAsset(); // Temporary solution to avoid opening a detail page with an asset saved in globalStorage

      sessionStorage.setItem(LAST_SCROLL_Y_POSITION, window.scrollY.toString());

      if (onCardClick) {
        onCardClick(data?.id!);
      }

      const isAdultPinNotEntered = !parentalPinStore.isAdultPinEntered;
      const isRestricted = !!(adult && isAdultPinNotEntered);

      if (isRestricted) {
        e.preventDefault();
        e.stopPropagation();

        const type = PinCodeModalTypes.RestrictedContent;

        modalContext.openModal(
          <PinCodeModalV2
            parentalRating={Number(parentalRating)}
            context={UIDAPresentationTypeEnum.applications}
            type={type}
          />,
        );

        return;
      }

      if (isPurchasable) {
        e.preventDefault();

        userActionEvents(UserActionType.details, data?.id, mediaTypeParam);

        navigate(
          resolvePathToDetailsPage({
            id: data?.id!,
            mediaTypeParam: mediaTypeParam.toLowerCase(),
            externalId: mosaicExternalId ?? '',
          }),
          {
            state: {
              lastScrollYPosition: window.scrollY + imageHeight,
            },
          },
        );
        return;
      }

      if (isUrlToFullPlayer && parentalPinStore.isBlockedByParentalRating(parentalRating!)) {
        e.preventDefault();

        modalContext.openModal(
          <PinCodeModalV2
            callback={(success: boolean) => {
              if (success) {
                navigate(link);
              }
            }}
            parentalRating={parentalRating}
            context={UIDAPresentationTypeEnum.applications}
            description={parentalRating && <DescriptionWithAge age={parentalRating} />}
            type={PinCodeModalTypes.RestrictedContentParental}
          />,
        );
        return;
      }

      if (link.includes('http')) {
        e.preventDefault();
        window.open(link, '_blank');
      }

      if (link.includes('details')) {
        userActionEvents(UserActionType.details, data?.id, mediaTypeParam);
      }
    },
    [modalContext, link, parentalRating, settingsStore, adult],
  );
};
