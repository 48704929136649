import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';
import cn from 'classnames';

import LoginForm from 'components/widgets/Login/LoginForm/LoginForm';
import LoginButtons from 'components/widgets/Login/LoginButtons/LoginButtons';
import OnBoardingLayout from 'components/presenters/layouts/onboarding/OnBoardingLayout';
import routes from 'routesPaths';
import { useLoginCombinerHook } from './hooks/useLoginCombinerHook/useLoginCombinerHook';
import ServiceView from './ServiceView/ServiceView';
import { login } from './handlers/login/login';

import styles from './Login.module.scss';

const Login: React.FC = () => {
  const {
    items,
    isReady,
    isOttLogin,
    isLoginForm,
    isShowLoginForm,
    isShowServicesView,
    isAlreadyAuthorized,
    showServiceList,
    handleRef,
  } = useLoginCombinerHook();

  if (isAlreadyAuthorized) {
    return <Navigate to={routes.home.path} />;
  }

  return (
    <OnBoardingLayout
      className={cn({ [styles.loginLayout]: !showServiceList, [styles.servicesLayout]: showServiceList })}
      refElement={handleRef}
    >
      {isReady && (
        <div className={cn(styles.center, { [styles.withoutMinHeight]: showServiceList })}>
          {!showServiceList && (
            <>
              <FormattedMessage id='Login.OnBoardingLayout.title' tagName='h1' />
              <FormattedMessage id='Login.OnBoardingLayout.description' tagName='p' />
            </>
          )}
          {isShowLoginForm && <LoginForm isOttLogin={isOttLogin} loginAssets={items!} />}
          {isShowServicesView && <ServiceView />}
          {!isLoginForm && <LoginButtons items={items!} onSignIn={login} />}
        </div>
      )}
    </OnBoardingLayout>
  );
};

export default observer(Login);
