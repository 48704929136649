import { useEffect, useRef } from 'react';

import { KuxMediaAssetType } from '@kaltura-ott/tvpil';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared';

import { useCleanupState, useAppStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import { PlayableAsset } from '@kaltura-ott/tvpil-shared/lib/stores/entities/PlayableAsset';

export const useInitializationHook = () => {
  const [loaded, setLoaded] = useCleanupState(() => false);
  const playableAsset = useRef<PlayableAsset<KuxMediaAssetType> | undefined>(undefined);
  const {
    appStore: { playoutStoreV2: playoutStore, lastWatchedLinear },
  } = useAppStore();

  useEffect(() => {
    async function effect() {
      setLoaded(false);

      await playoutStore.init({
        id: globalStorage.focusAssetV2.id,
        type: PlayoutStoreV2Type.LIVE,
        isMaxiPlayer: true,
        mosaic: {
          externalId: lastWatchedLinear.mainHeroRail?.lastWatchedSubLinearExternalId,
        },
      });

      setLoaded(!!playoutStore.currentAsset);
      globalStorage.clearFocusAsset();

      playableAsset.current = playoutStore.currentAsset;
    }

    const focusAssetId = globalStorage?.focusAssetV2?.id;

    if (focusAssetId && playoutStore) {
      effect();
    }
  }, [playoutStore, globalStorage?.focusAssetV2?.id, setLoaded]);

  return { loaded, playableAsset: playableAsset.current };
};
