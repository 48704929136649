import React from 'react';

import { CollectionAsset } from '@kaltura-ott/tvpil-shared';

import Card from 'components/widgets/RailV2/components/Card/Card';
import CardBubbleInfo from 'components/widgets/CardV2/components/CardBubbleInfo/CardBubbleInfo';
import CardInner from 'components/widgets/RailV2/components/CardInner/CardInner';
import CardTitle from 'components/widgets/CardV2/components/CardTitle/CardTitle';
import RecommendationCardContent from '../RecommendationCardContent/RecommendationCardContent';
import { useRecommendationCard } from './hooks/useRecommendationCard/useRecommendationCard';

interface Props {
  item: CollectionAsset;
  itemWidth: number;
  imageHeight: number;
}

function RecommendationCard({ item, itemWidth, imageHeight }: Props) {
  const {
    id,
    link,
    title,
    isAdult,
    handleRef,
    assetRating,
    channelName,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    handleLinkRef,
    handleClickInfo,
    isDisplayRating,
    defaultImageUrl,
    backgroundImageUrl,
    collectionProgramAsset,
  } = useRecommendationCard({ item, itemWidth, imageHeight });

  return (
    <Card
      handleLinkRef={handleLinkRef}
      clickHandler={clickHandler}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      link={link}
      imageHeight={imageHeight}
      itemWidth={itemWidth}
      isMosaicProgram={false}
    >
      <CardInner
        cardType='recommendation'
        url={backgroundImageUrl}
        name={title}
        handleRef={handleRef}
        handleLinkRef={handleLinkRef}
        isAdult={isAdult}
        isNoDataWithNoLogoImg={false}
        defaultImageUrl={defaultImageUrl}
        contentOverlay={
          <RecommendationCardContent
            isDisplayRating={isDisplayRating}
            assetRating={assetRating!}
            shouldDisplayPlayButton
          />
        }
        cardBubbleInfo={
          <CardBubbleInfo
            channelHasProgram
            channelName={channelName}
            isAdult={isAdult}
            title={title}
            isMosaicProgram={false}
            handleClickInfo={handleClickInfo}
            programId={id}
            collectionProgramAsset={collectionProgramAsset}
          />
        }
      />
      <CardTitle isAdult={isAdult} isStaticInfo title={title} isMosaicProgram={false} programId={item?.id} />
    </Card>
  );
}

export default RecommendationCard;
