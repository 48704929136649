import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useDetectLocation, useLoginStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import MenuWrapper from './MenuWrapper';

function MenuResolver() {
  const { isUrlToFullPlayer } = useDetectLocation();
  const { isAnonymous } = useLoginStore();

  useEffect(() => {
    globalStorage.setIsOnBoarding(isAnonymous);
  }, [isAnonymous]);

  if (isUrlToFullPlayer) {
    return null;
  }

  return <MenuWrapper />;
}
export default observer(MenuResolver);
