import { MediaType, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import { getNormalizeMediaType } from 'utils/dataNormalizer';
import routes from 'routesPaths';
import { FUTURE_RECORDINGS, RECORDINGS } from 'consts';
import { modifyPathForDetails } from './modifyPathForDetails';

type ParamsDetails = {
  id: number | string;
  externalId?: string;
  isTypeOfSeries?: Boolean;
  isProgramRecord?: boolean;
  mediaTypeParam?: string;
  seriesId?: string;
  collectionType?: string;
  isFutureProgram?: boolean;
  isSuppressedEpisode?: boolean;
};

export function resolvePathToDetailsPageV2({
  id,
  isTypeOfSeries,
  isFutureProgram,
  isProgramRecord,
  seriesId,
  collectionType,
  externalId = '',
  mediaTypeParam = '',
  isSuppressedEpisode = false,
}: ParamsDetails) {
  const currentPath = window.location.pathname;
  const { search } = window.location;
  const mediaType = mediaTypeParam && getNormalizeMediaType(mediaTypeParam);
  const isVodSeries = mediaType === MediaType.Series;
  const isNpvr = !!isProgramRecord || (!!collectionType && collectionType === UIDAPresentationTypeEnum.npvr);
  const isLeadsToDetailsSeries =
    (collectionType && collectionType === UIDAPresentationTypeEnum.series_catch_up) ||
    isVodSeries ||
    isSuppressedEpisode ||
    (!!isTypeOfSeries && !!seriesId);

  let path = routes.detailsInfo;

  if (isLeadsToDetailsSeries) {
    path = routes.detailsSeries;
  }

  let resolvedPath = `${window.location.pathname}${path.link(id, mediaTypeParam, externalId)}`;

  if (currentPath === routes.home.path) {
    resolvedPath = `${path.link(id, mediaTypeParam, externalId)}`;
  }

  const modifierPath = path.link(id, mediaTypeParam, externalId);

  resolvedPath = modifyPathForDetails(currentPath, modifierPath) ?? resolvedPath;

  const params = new URLSearchParams(search);

  if (isNpvr) {
    if (isFutureProgram) {
      // for avoid the bugs with future recording
      params.append('type', FUTURE_RECORDINGS);
    } else {
      params.append('type', RECORDINGS);
    }
  }

  if (isSuppressedEpisode && !!seriesId) {
    params.append('seriesid', seriesId);
  }

  if (params.toString()) {
    resolvedPath += `?${params.toString()}`;
  }

  return resolvedPath;
}
