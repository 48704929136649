import React, { createContext, useMemo } from 'react';
import { useNavigationType, NavigationType, useNavigate, useLocation } from 'react-router-dom';

import { useGoBack } from 'hooks';

interface Props {
  children: React.ReactNode;
}

export interface ContextType {
  goBack: () => void;
  navType: NavigationType | '';
}

const NavigationContext = createContext<ContextType>({
  goBack: () => {},
  navType: '',
});

const NavigationContextProvider = ({ children }: Props) => {
  const navType = useNavigationType();
  const goBack = useGoBack();
  const navigate = useNavigate();
  const location = useLocation();
  const contextValue = useMemo(() => {
    return { navigate, location, navType, goBack };
  }, [navigate, location, navType, goBack]);

  return <NavigationContext.Provider value={contextValue}>{children}</NavigationContext.Provider>;
};

export { NavigationContext, NavigationContextProvider };
