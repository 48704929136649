import { MediaType } from '@kaltura-ott/tvpil';
import { Program } from '@kaltura-ott/tvpil-shared';

import { useAppStore, useTransactionStore, useDetectLocation } from 'hooks';
import { useVideoContext } from 'context/videoContext/videoContext';
import { useBookmark } from '../hooks/useBookmark/useBookmark';
import { useCloseOverlayAndHideButtons } from './useCloseOverlayAndHideButtons/useCloseOverlayAndHideButtons';
import detailsStoreDataResolver from '../../../utils/detailsStoreDataResolver';

interface Props {
  isLandscapeRatio?: boolean;
}

function useVideoContentDataCombiner({ isLandscapeRatio }: Props) {
  const { program, firstEpisode, isOverlay, closeOverlay: onCloseModal } = useVideoContext();

  const {
    adult,
    catchupAvailableTime,
    duration,
    enableCatchUp,
    isPast,
    isProviderAsset,
    isPurchased,
    isTvod,
    isVodSeries,
    isVodSingle,
    mediaType,
    mosaicInfo,
    providerLogoPath,
    title,
  } = detailsStoreDataResolver(program);

  const {
    appStore: {
      localizationStore: { displayLanguage, latinLanguage },
      staticAssetsUrl = '',
    },
  } = useAppStore();
  const { isPurchaseAllowed } = useTransactionStore();
  const { isDetailsSeries } = useDetectLocation();

  const { hideDetailsBtn, closeOverlayAndHideButtons } = useCloseOverlayAndHideButtons({ isOverlay, onCloseModal });

  const currentAsset: any = isDetailsSeries ? firstEpisode : program;
  const { record } = !isDetailsSeries && currentAsset;
  const { seriesRecord } = program as Program;
  const isCatchupSeries = isDetailsSeries && mediaType === MediaType.Program;
  const isVod = isVodSingle || isVodSeries;
  const isMosaic = mosaicInfo?.isMosaicEvent === '1';
  const isShowingNotAllowedPurchase = !isPurchased && !isPurchaseAllowed && isTvod;
  const isPoster = !isOverlay && !isLandscapeRatio;
  const isLandscape = !isOverlay && !!isLandscapeRatio;
  const isAvailableForWatching =
    enableCatchUp && !adult && !isDetailsSeries && !!catchupAvailableTime && isPast && !hideDetailsBtn;
  const isButtonDetails = !isShowingNotAllowedPurchase && !(isDetailsSeries && !firstEpisode) && !hideDetailsBtn;
  const isVisibleProviderLogo = isProviderAsset && !!providerLogoPath;

  const { bookmarkInfo, remainingTime } = useBookmark({ currentAsset, duration, isOverlay, isPast });

  return {
    isDetailsSeries,
    isOverlay,
    isLandscape,
    isPoster,
    isVisibleProviderLogo,
    providerLogoPath,
    isCatchupSeries,
    title,
    record,
    seriesRecord,
    isButtonDetails,
    closeOverlayAndHideButtons,
    bookmarkInfo,
    remainingTime,
    isVod,
    displayLanguage,
    latinLanguage,
    isAvailableForWatching,
    isMosaic,
    staticAssetsUrl,
    isShowingNotAllowedPurchase,
  };
}

export { useVideoContentDataCombiner };
