import { useContext, useEffect } from 'react';
import { MobXProviderContext } from 'mobx-react';

import { AppStore } from '@kaltura-ott/tvpil-shared';

import localStorageService from 'services/localStorageService';
import { DISPLAY_NAME } from 'consts';

function useAppTitleManager() {
  const {
    appStore: { rootStore },
  } = useContext(MobXProviderContext) as {
    appStore: AppStore;
  };

  useEffect(() => {
    function effect() {
      const { displayName } = rootStore;

      // Check if the display name stored in local storage is different from the current display name
      if (localStorageService.getItem(DISPLAY_NAME) !== displayName) {
        localStorageService.setItem(DISPLAY_NAME, displayName);
        document.title = displayName;
      }
    }

    if (rootStore) {
      effect();
    }
  }, [rootStore]);
}

export { useAppTitleManager };
