import React from 'react';
import { observer } from 'mobx-react';

import { useMenuCombiner } from '../hooks/useMenuCombiner/useMenuCombiner';
import Languages from '../../LanguagesDropDown/LanguagesDropDown';
import SearchWidget from '../../SearchWidget/SearchWidget';
import ProfileIcon from './ProfileIcon/ProfileIcon';
import HeadingLinks from './HeadingLinks/HeadingLinks';

import styles from '../Menu.module.scss';

const Menu = () => {
  const { isReady, isOnBoarding, isChangingLangEnabled, isShowSearchInput } = useMenuCombiner();

  return (
    <>
      {isReady && <HeadingLinks />}
      {isOnBoarding ? (
        <div className={styles.right}>{isChangingLangEnabled && <Languages />}</div>
      ) : (
        <div className={styles.right}>
          {isShowSearchInput && <SearchWidget />}
          <ProfileIcon />
        </div>
      )}
    </>
  );
};

export default observer(Menu);
