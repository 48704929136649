import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import { capitalize } from 'lodash';

import { LanguageKeyEnum } from '@kaltura-ott/tvpil-shared';

import { useSettingsStore, useAppStore, useToastMessage } from 'hooks';
import { useModalContext } from 'context/modal/modal';
import { LANGUAGE_TYPE, LAST_CHANGED_LANGUAGE } from 'consts';
import { ActionButtonTypesEnum } from 'interface';
import Card from '../Card/Card';
import LanguagesList from '../../../presenters/LanguagesList/LanguagesList';
import SettingsContentWrapper from '../SettingsContentWrapper/SettingsContentWrapper';
import DefaultModal from '../../../widgets/Modal/DefaultModal/DefaultModal';
import { findLanguagesStore } from '../utils';

const COMPONENT_NAME = 'Languages';
const FULL_COMPONENT_NAME = `Settings.${COMPONENT_NAME}`;

type LanguageKey = keyof typeof LANGUAGE_TYPE;
type LanguageValue = typeof LANGUAGE_TYPE[LanguageKey];

function Languages() {
  const modalContext = useModalContext();
  const {
    appStore: {
      localizationStore: {
        languageNativeNameByCode,
        languageListForKey,
        audioLanguage,
        subTitleLanguage,
        displayLanguage,
        languagesList,
      },
    },
  } = useAppStore();

  const { settingsStore } = useSettingsStore();
  const intl = useIntl();
  const languagesSubPage = findLanguagesStore(settingsStore.children)!;

  useToastMessage(COMPONENT_NAME);

  useEffect(() => {
    languagesSubPage.init();
  }, []);

  function getLanguageByKey(key: LanguageValue) {
    switch (key) {
      case LANGUAGE_TYPE.AUDIO:
        return audioLanguage;
      case LANGUAGE_TYPE.SUBTITLE:
        return subTitleLanguage;
      case LANGUAGE_TYPE.DISPLAY:
        return displayLanguage;
      default:
        return '';
    }
  }

  const handleLanguagesHandle = (value: string, type: LanguageValue, func: (value: string) => void) => {
    sessionStorage.setItem(LAST_CHANGED_LANGUAGE, type);

    if (type === LANGUAGE_TYPE.DISPLAY) {
      modalContext.openModal(
        <DefaultModal
          title={intl.formatMessage({
            defaultMessage: `Change ${type} language`,
            id: `${FULL_COMPONENT_NAME}.DefaultModal.${type}`,
          })}
          description={intl.formatMessage({
            defaultMessage: 'The app needs to restart in otder to apply the new language',
            id: `${FULL_COMPONENT_NAME}.DefaultModal.ConfirmationDescription`,
          })}
          buttonProps={{
            onAccept: async () => {
              func(value);
              modalContext.closeModal();
            },
            acceptButtonType: ActionButtonTypesEnum.primary,
            acceptButtonText: intl.formatMessage({
              id: `${FULL_COMPONENT_NAME}.DefaultModal.Button.restart`,
              defaultMessage: 'Restart',
            }),
          }}
        />,
        undefined,
        true,
      );
      return;
    }
    func(value);

    modalContext.closeModal();
  };

  function onChangeLanguage(type: string) {
    return () => {
      const key = type as keyof typeof LanguageKeyEnum;
      const defaultLanguage = getLanguageByKey(type) || '';

      modalContext.openModal(
        <DefaultModal
          buttonProps={{
            acceptButtonText: intl.formatMessage({
              defaultMessage: 'Cancel',
              id: `${FULL_COMPONENT_NAME}.DefaultModal.buttonCancel`,
            }),
          }}
          title={intl.formatMessage({
            defaultMessage: `Change ${type} language`,
            id: `${FULL_COMPONENT_NAME}.DefaultModal.${type}`,
          })}
        >
          <LanguagesList
            defaultValue={defaultLanguage}
            languagesHandle={handleLanguagesHandle}
            list={languageListForKey(LanguageKeyEnum[key])}
            type={type}
          />
        </DefaultModal>,
      );
    };
  }

  if (!languagesList.length) {
    return null;
  }

  return (
    <SettingsContentWrapper
      description={intl.formatMessage({
        defaultMessage: 'Change your language preferences',
        id: `${FULL_COMPONENT_NAME}.SettingsContentWrapper.description`,
      })}
      title={intl.formatMessage({
        defaultMessage: 'Language',
        id: `${FULL_COMPONENT_NAME}.SettingsContentWrapper.title`,
      })}
    >
      {Object.values(LANGUAGE_TYPE).map((item) => {
        const key = item as LanguageValue;
        const language = getLanguageByKey(key) || '';

        return (
          <Card
            key={item}
            name={intl.formatMessage({
              defaultMessage: key,
              id: `${FULL_COMPONENT_NAME}.${key}`,
            })}
            onHandleClick={onChangeLanguage(key)}
            text={
              capitalize(languageNativeNameByCode(language)) ||
              intl.formatMessage({
                defaultMessage: 'Language is not provided',
                id: `${FULL_COMPONENT_NAME}.Card.empty.${key}`,
              })
            }
          />
        );
      })}
    </SettingsContentWrapper>
  );
}

export default observer(Languages);
