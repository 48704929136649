import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { createSkeletonCollection } from 'utils';
import styles from '../Subscriptions.module.scss';

const CARD_SKELETON_ITEMS_NUMBER = 3;
const OFFER_SKELETON_ITEMS_NUMBER = 4;
const CARD_SKELETON_ITEMS_PREFIX = 'card-skeleton';
const OFFER_SKELETON_ITEMS_PREFIX = 'offer-skeleton';

function SkeletonView() {
  const cards = createSkeletonCollection(CARD_SKELETON_ITEMS_NUMBER, CARD_SKELETON_ITEMS_PREFIX);
  const offers = createSkeletonCollection(OFFER_SKELETON_ITEMS_NUMBER, OFFER_SKELETON_ITEMS_PREFIX);

  return (
    <div className={styles.subscriptions}>
      <FormattedMessage defaultMessage='My Subscriptions' id='Settings.Subscriptions.MySubscriptions' tagName='h2' />
      <FormattedMessage defaultMessage='Current plan' id='Settings.Subscriptions.currentPlanTitle' tagName='h3' />
      <div className={cn(styles.card, styles.cardWithActionButton)} />
      <FormattedMessage defaultMessage='Subscriptions' id='Settings.Subscriptions.subscriptionsTitle' tagName='h3' />

      {cards.map((card) => (
        <div key={card.id} className={styles.card} />
      ))}
      <FormattedMessage defaultMessage='Some offers for you' id='Settings.Subscriptions.offersTitle' tagName='h3' />
      <div className={styles.offers}>
        {offers.map((offer) => (
          <div key={offer.id} className={styles.offer} />
        ))}
      </div>
    </div>
  );
}
export default SkeletonView;
