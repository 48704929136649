import { useIntl } from 'react-intl';

import { Ppv } from '@kaltura-ott/tvpil-shared';

import { amountResolver, resolveMinutesToHours } from 'utils';
import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';

export function useResolvedRent() {
  const intl = useIntl();

  function resolveRent(rent: Ppv) {
    const {
      viewLifeCycle,
      price: { amount, currency },
    } = rent?.data;

    const rentDuration = resolveMinutesToHours(viewLifeCycle);
    const duration = intl.formatMessage(
      {
        defaultMessage: '{duration, plural, one {Rent for # hour} other {Rent for # hours}}',
        id: `${COMPONENT_NAME}.VideoContent.RentButton.duration.${intl.formatPlural(rentDuration)}`,
      },
      { duration: rentDuration },
    );

    const price = amountResolver(amount, currency);

    return {
      duration,
      price,
      viewLifeCycle: rentDuration,
    };
  }

  return { resolveRent };
}
