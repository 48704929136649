import { appStore } from '@kaltura-ott/tvpil-shared';

import { playerStorage } from '../../storages';
import { KalturaAudioTrack } from '../../types';
import { PLAYER_SESSION_STORAGE_AUDIO_KEY } from '../../constants';

export function handleSelectAudio() {
  const { player } = playerStorage.getPlayer();
  const playerTracks: KalturaAudioTrack[] = player.getTracks(player.Track.AUDIO);
  const userSelection = appStore.localizationStore.audioLanguage;
  const defaultAudioCodes = appStore.rootStore.playerStore?.defaultAudioCodes || [];
  let selectedTrack: KalturaAudioTrack | undefined;

  if (userSelection) {
    selectedTrack = playerTracks.find(({ language }) => language === userSelection);
  }

  if (!selectedTrack && defaultAudioCodes.length) {
    let i = 0;

    while (i < defaultAudioCodes.length) {
      const defaultAudioCode = defaultAudioCodes[i];
      selectedTrack = playerTracks.find(({ language }) => language === defaultAudioCode);

      if (selectedTrack) {
        break;
      }

      i += 1;
    }
  }

  if (selectedTrack) {
    player.selectTrack(window.sessionStorage.getItem(PLAYER_SESSION_STORAGE_AUDIO_KEY) || selectedTrack);
  }
}
