import { useEffect } from 'react';

import { config } from 'components/pages/EpgV2/config';
import { throttle } from 'components/pages/EpgV2/utils';
import { useToggleScrollDirection } from '../useToggleScrollDirection/useToggleScrollDirection';

export const useDateViewScrollDirection = () => {
  const toggleScrollDirection = throttle(useToggleScrollDirection(), config.delayForVerticalScroll);

  useEffect(() => {
    window.addEventListener('scroll', toggleScrollDirection);

    return () => {
      window.removeEventListener('scroll', toggleScrollDirection);
    };
  }, []);
};
