import React from 'react';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

import { useAppStore } from 'hooks';
import { getPurchaseIcon } from 'utils';
import { SHOPPING_CART_ICON } from 'consts';

import styles from './PurchaseIcon.module.scss';

interface Props {
  className?: string;
  isBadge?: boolean;
}

export function PurchaseIcon({ className, isBadge = false }: Props) {
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  return (
    <div className={cn(className, styles.wrapper, { [styles.badge]: isBadge })}>
      <SVG data-target='shopping-cart' src={`${staticAssetsUrl}${getPurchaseIcon(SHOPPING_CART_ICON)}`} />
    </div>
  );
}
