import { TypeOfApplicationLink } from '@kaltura-ott/tvpil';
import { ExponeaUiButton } from '@kaltura-ott/tvpil-shared';

import routes from 'routesPaths';
import { getNormalButton, parseUrl } from '../helpers';

export function useResolveRedirectButton(buttons: ExponeaUiButton[]) {
  // TODO: should be merged with "show more button" and "link from menu" logic after solving a problem with AssetAsLink
  const normalButton = getNormalButton(buttons);
  const { typeOfAppLink, inAppLink, collectionId } = parseUrl(normalButton?.link);
  const urlParams = {
    url: '',
    openAs: undefined,
  };

  switch (typeOfAppLink) {
    case TypeOfApplicationLink.page:
      urlParams.url = routes.grid.link(`/page/${inAppLink}`);
      break;
    case TypeOfApplicationLink.external:
      urlParams.url = inAppLink!;
      // @ts-ignore
      urlParams.openAs = '_blank';
      break;
    case TypeOfApplicationLink.asset:
      // urlParams.url = resolvePathToDetailsPage({ id: inAppLink!, mediaTypeParam: mediaType?.toLocaleLowerCase() }); // will be added in second phase of Exponea
      console.error(Error(`${typeOfAppLink} - typeOfAppLink is not defined`));
      break;
    case TypeOfApplicationLink.grid:
      urlParams.url = routes.grid.link(`/grid/${inAppLink}/${collectionId}`);
      break;
    default:
      console.error(Error(`${typeOfAppLink} - typeOfAppLink is not defined`));
  }

  return {
    text: normalButton?.text,
    ...urlParams,
  };
}
