import React from 'react';

import { KalturaRecordingType } from '@kaltura-ott/ott-client-types/api/types';

import { useDetectLocation, useRecordRepository } from 'hooks';
import { useHandleMenuBtnClick } from './useHandleMenuBtnClick';
import { isOnlyCancelSeriesCondition } from '../utils/utils';
import { BasicRecordButtonProps } from '../interface';

interface Props extends BasicRecordButtonProps {
  setPending: Function;
  isMenuOpen: boolean;
  setIsMenuOpen: Function;
  ref: React.MutableRefObject<HTMLDivElement | null>;
}

function useHandleRecordBtnClick({
  assetId,
  seriesId,
  programNotAvailable,
  callback,
  enableCatchUp,
  setPending,
  isMenuOpen,
  setIsMenuOpen,
  ref,
}: Props) {
  const recordRepository = useRecordRepository();
  const { isDetailsSeries } = useDetectLocation();

  const record = recordRepository.getSingleRecord(assetId);
  const seriesRecord = recordRepository.getSeriesRecord(String(seriesId));

  const { handleMenuBtnClick } = useHandleMenuBtnClick({
    assetId,
    seriesId,
    programNotAvailable,
    callback,
    enableCatchUp,
    setPending,
    setIsMenuOpen,
    ref,
  });

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen) {
      ref.current?.blur();
    }
  }

  // eslint-disable-next-line consistent-return
  function handleRecordBtnClick(event: React.MouseEvent<HTMLButtonElement>) {
    let customEvent = event;
    if (isOnlyCancelSeriesCondition(record!, seriesRecord!, isDetailsSeries)) {
      // to avoid showing popup with only one Cancel Series case
      // event.currentTarget.dataset.value = KalturaRecordingType.series;
      customEvent = ({
        currentTarget: { dataset: { value: KalturaRecordingType.series } },
      } as unknown) as React.MouseEvent<HTMLButtonElement>;
    } else if (seriesId && ((!!record && !!seriesRecord) || (!record && !seriesRecord))) {
      // if single and series are exist or not exist together - can open menu with 2 options
      return toggleMenu();
    }

    handleMenuBtnClick(customEvent);
  }

  return { handleRecordBtnClick };
}

export { useHandleRecordBtnClick };
