import React from 'react';

import NavigationButton from '../NavigationButton/NavigationButton';
import { Position } from '../NavigationButton/types';
import { useNextButtonsCombiner } from './hooks/useNextButtonsCombiner/useNextButtonsCombiner';

const NextButtons = () => {
  const { isLeftNextButton, isRightNextButton, clickHandle, imageHeight } = useNextButtonsCombiner();

  return (
    <>
      {isLeftNextButton && (
        <NavigationButton handleClickCallback={clickHandle} position={Position.Left} imageHeight={imageHeight} />
      )}
      {isRightNextButton && (
        <NavigationButton handleClickCallback={clickHandle} position={Position.Right} imageHeight={imageHeight} />
      )}
    </>
  );
};

export default NextButtons;
