import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { EpgContextProvider } from 'context/epgContext/epgContext';
import { RhinoError, useChannels } from '@kaltura-ott/tvpil-shared';

import { useErrorScreenContext } from 'context/error/error';
import { useNavigate } from 'react-router-dom';
import EpgInner from './components/EpgInner/EpgInner';

import { useEpgHooksCombiner } from './hooks';
import { getEpgErrorPayload } from './utils/getEpgErrorPayload';

type Props = { referenceId: string };

function EpgV2({ referenceId }: Props) {
  const {
    setUserActive,
    forwardGridMovement,
    setFirstVisibleIndex,
    handleRef,
    nowLineRef,
    epgParams,
    gridStartYPosition,
    userActive,
    refTime,
    ref,
    settings,
  } = useEpgHooksCombiner(referenceId);

  const errorScreenContext = useErrorScreenContext();
  const navigate = useNavigate();

  const { channels, isLoading, error } = useChannels({ referenceId });

  useEffect(() => {
    if (error) {
      const handleError = () => {
        const { code } = error as RhinoError;
        const goBackLink = () => navigate(-1);
        const errorPayload = getEpgErrorPayload(goBackLink, code);

        errorScreenContext.setError(errorPayload);
      };

      handleError();
    }
  }, [error, errorScreenContext]);

  return (
    <EpgContextProvider
      epgRef={ref}
      nowLineRef={nowLineRef}
      forwardGridMovement={forwardGridMovement}
      epgParams={epgParams}
      refTime={refTime}
      userActive={userActive}
      gridStartYPosition={gridStartYPosition}
      handleRef={handleRef}
      setUserActive={setUserActive}
      setFirstVisibleIndex={setFirstVisibleIndex}
      settings={settings!}
      channels={channels}
      isReadyTvChannel={!isLoading}
    >
      <EpgInner />
    </EpgContextProvider>
  );
}

export default observer(EpgV2);
