import { useEffect } from 'react';
import { reaction } from 'mobx';

import { subMinutes } from 'date-fns';

import { useEpgStore } from 'hooks';
import { HALF_DIVIDER } from 'consts';
import { config } from '../../config';

import { onHandleResize } from '../../handlers';
import { epgLocalTimeStampStorage } from '../../storages';

interface Props {
  userActive: React.MutableRefObject<boolean>;
  ref: React.MutableRefObject<HTMLDivElement | null>;
}

export const useUpdateTimestamp = ({ userActive, ref }: Props) => {
  const { epgStore } = useEpgStore();

  useEffect(() => {
    const interval = setInterval(() => {
      epgLocalTimeStampStorage.setCurrentTimestamp(Date.now());

      if (ref.current && !userActive.current) {
        epgLocalTimeStampStorage.setViewTimestamp(
          subMinutes(new Date(), epgLocalTimeStampStorage.screenWidthInMinutes / HALF_DIVIDER).valueOf(),
        );
      }
    }, config.refreshTime);

    let updated = false;
    const disposer = reaction(
      () => epgStore.lastUpdateTime,
      () => {
        // update once
        if (!updated) {
          onHandleResize();
          epgLocalTimeStampStorage.setCurrentTimestamp(Date.now());
          updated = true;
        }
      },
    );

    return () => {
      clearInterval(interval);
      disposer();
    };
  }, []);
};
