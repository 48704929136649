import React from 'react';

import { useEpgContext } from 'context/epgContext/epgContext';
import ProgramsInner from './components/ProgramsInner/ProgramsInner';

import styles from './Programs.module.scss';

function Programs() {
  const {
    epgParams: { epgWidth },
    channels,
  } = useEpgContext();
  // defining channels for epg
  const tvChannels = channels ?? [];

  return (
    <ul
      className={styles.list}
      style={{
        width: epgWidth,
      }}
    >
      <>
        {tvChannels.map((channel) => (
          <ProgramsInner key={channel.id} channel={channel} />
        ))}
      </>
    </ul>
  );
}

export default Programs;
