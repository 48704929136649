import { DeviceStatus } from '@kaltura-ott/tvpil-analytics';

import { ContextType } from 'context/heartbeat/heartbeat';
import { PlayerEvent } from '../../types';

export class HeartbeatService {
  readonly context: ContextType;

  readonly player: any;

  constructor(context: ContextType, player: any) {
    this.context = context;
    this.player = player;
  }

  addEventListeners() {
    this.player.addEventListener(this.player.Event.CHANGE_SOURCE_STARTED, this.setDeviceStatusUp);
    this.player.addEventListener(this.player.Event.ERROR, this.onError);
    this.player.addEventListener(this.player.Event.PAUSE, this.setDeviceStatusUp);
    this.player.addEventListener(this.player.Event.PLAYING, this.onPlaying);
  }

  removeEventListeners() {
    this.player.removeEventListener(this.player.Event.CHANGE_SOURCE_STARTED, this.setDeviceStatusUp);
    this.player.removeEventListener(this.player.Event.ERROR, this.onError);
    this.player.removeEventListener(this.player.Event.PAUSE, this.setDeviceStatusUp);
    this.player.removeEventListener(this.player.Event.PLAYING, this.onPlaying);

    this.setDeviceStatusUp();
  }

  setDeviceStatusUp = () => {
    this.context.setMetrics({ status: DeviceStatus.up });
  };

  onPlaying = () => {
    this.context.setMetrics({ status: DeviceStatus.streaming });
  };

  onError = ({ payload }: PlayerEvent) => {
    const { category } = payload;

    if (category === this.player.Error.Category.NETWORK) {
      this.setDeviceStatusUp();
    }
  };
}
